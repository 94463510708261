import {ClientAge, ClientDisease, ClientMentalAbility, ClientPhysicalActivity, ClientWeight, DealStatus, FetchCriteria, GeoLocation, GeoLocationForm, GeoLocationValidation, Language, MapCoordinates, Name, Price, Qualification, Rate, RateForm, RateValidation, Rating, Sex, SortFields, UserId} from "@/types"
import {RecursivePartial} from "@/utils/typescript-library-extensions";
import {ValidationObject, ValidationRuleSet} from "@/utils/vuelidate-extension";
import {ExistLocationCords} from "@/store/map";
import {ProviderProtected} from "@/_modules/proposal/types";
export type ServiceId = string
export type TasksDates = string

export enum ServiceFilterFields {
  CATEGORY = "category",
  ONLINE_ONLY = "onlineOnly",
  DISTANCE = "distance",
  SPEAKLANG = "speakLang",
  SERVICE_AT_HOME = "serviceAtHome",
  DISTANCE_FROM_LOCATION = "distanceFromLocation",
  SEX = "sex",
  AGE = "age",
  WEIGHT = "weight",
  DISEASE = "disease",
  MENTAL = "mental",
  PHYSICAL = "physical"
}

export enum ServiceTypeForSearch {
  All = "All",
  ServiceAtHome = "ServiceAtHome",
  NotServiceAtHome = "NotServiceAtHome"
}

export interface ServiceTypeSubCategoriesWithQualification {
  category: string;
  category_en: string;
  category_et: string;
  category_ru: string;
  subCategories: ServiceTypeSubCategoriesWithQualification[];
  qualification?: Qualification;
}

export interface ServiceType {
  category: string;
  category_en: string;
  category_et: string;
  category_ru: string;
  qualification?: Qualification;
  subCategories: ServiceTypeSubCategoriesWithQualification[];
}

export interface Service {
  userId?: string;
  serviceId: ServiceId;
  category: string;
  competences: string[];
  title: string;
  description_ru?: string;
  description_et?: string;
  description_en?: string;
  languages: Language[];
  scheduleInfo_ru?: string;
  scheduleInfo_et?: string;
  scheduleInfo_en?: string;
  qualification: Qualification;
  location: GeoLocation;
  rate: Rate;
  visible: boolean;
  uploadToken?: string;
  providedAtClientPlace: boolean;
  agreeToPublishAddress?: boolean;
  clientSex: Sex[];
  clientAge: ClientAge[];
  clientWeight: ClientWeight[];
  clientMentalAbility: ClientMentalAbility[];
  clientPhysicalActivity: ClientPhysicalActivity[];
  clientDisease: ClientDisease[];
  joinToGroup: boolean;
}

export interface ServiceForm extends RecursivePartial<Service> {
  category: string;
  competences: string[];
  clientSex: Sex[];
  clientDisease: ClientDisease[];
  clientPhysicalActivity: ClientPhysicalActivity[];
  clientMentalAbility: ClientMentalAbility[];
  clientWeight: ClientWeight[];
  clientAge: ClientAge[];
  rate: RateForm;
  location: GeoLocationForm;
  languages: Language[];
  providedAtClientPlace?: boolean;
  joinToGroup: boolean | undefined;
}

export interface ServiceValidation extends ValidationObject<Service> {
  clientAge: ValidationRuleSet;
  title: ValidationRuleSet;
  rate: RateValidation;
  location: GeoLocationValidation;
}


export interface RequestShortDetails {
  id: string;
  name: string;
  requesterName: Name;
  tasksDates: TasksDates[];
  totalPrice: Price;
  status: DealStatus;
}

export interface OfferSummary {
  id: string;
  name: string;
  createdAt: string;
  active: string;
  requestsCount: number;
  requests: RequestShortDetails[];
}

export interface ServicesSummary {
  nr: number;
  size: number;
  items: OfferSummary[];
  total: number;
}

export interface ProviderPublic {
  id: UserId;
  online: boolean;
  displayName: string;
  verified: boolean;
  rating: Rating;
  sex: Sex;
}

export interface OfferCard {
  id: string;
  title: string;
  category: string;
  qualification: Qualification;
  rate: Rate;
  languages: Language[];
  coordinates: Coordinates;
  serviceAtHome: boolean;
}

export interface ServiceCards {
  nr: number;
  size: number;
  items: ServiceCard[];
}
export interface ServiceCard {
  provider: ProviderPublic;
  service: OfferCard;
  distanceInMeters?: number;
}

export interface MyDemands {
  id: string;
  name: string;
}

export interface ServiceDetails {
  service: Service;
  dateCreated: Date;
  distanceInMeters: number;
  provider: ProviderProtected;
  myDemands: MyDemands[];
  competencesWithParent: RecursivePartial<ServiceType>[];
}

export interface ServiceCriteria extends FetchCriteria<SortFields, ServiceFilterFields> {
  coordinates?: MapCoordinates;
  existLocationCords?: ExistLocationCords;
}

export interface ServiceDetailsPayload {
  lat: number;
  lon: number;
  serviceId: string;
}

