import {Module, VuexModule, Action, getModule} from "vuex-module-decorators"

import rootStore from "@/store"
import axios from "@/api";

import {BackendUrls} from "@/constants/APIconstants";
import {UnexpectedServerResponseError} from "@/utils/errors";
import HttpStatus from "http-status-codes";


export interface FeedbackForm {
  senderEmail: string;
  message: string;
}

export interface FormWithRecaptcha {
  senderEmail: string;
  message: string;
  recaptchaAction: string;
  recaptchaToken: string;
}

@Module({dynamic: true, namespaced: true, name: "feedback", store: rootStore})
export class FeedbackModule extends VuexModule {


  @Action({rawError: true})
  public async sendMsgToSupport(payload: FormWithRecaptcha): Promise<void> {
    const resp = await axios.post<FormWithRecaptcha>(BackendUrls.supportAsk, payload)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }
}

export const feedbackStore = getModule(FeedbackModule, rootStore)