import {Module, VuexModule, Action, getModule, Mutation} from "vuex-module-decorators"

import axios from "@/api"

import HttpStatuses from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"
import rootStore from "@/store/index";

export interface ServerCfg {
  badgesUri: string | undefined;
  paymentFeeFixedPart: string | undefined;
  paymentFeePercentagePart: string | undefined;
  taxPercentage: string | undefined;
}

export interface CountryFullName {
  eng: string;
  est: string;
  rus: string;
}
export interface Country {
  countryCode: string;
  phoneNumberPrefix: string[];
  fullName: CountryFullName;
  flagUrl: string;
  isSepa: boolean;
}

enum PriorityCountries {
  EE = "EE",
  LT = "LT",
  LV = "LV"
}

export function shiftPriorityCountriesInFront(countries: Country[]): Country[] {
  const left: Country[] = []
  const right: Country[] = []
  countries.forEach(c => {
    if (c.countryCode === PriorityCountries.EE || c.countryCode === PriorityCountries.LT || c.countryCode === PriorityCountries.LV) {
      left.push(c)
    } else {
      right.push(c)
    }
  })
  return left.sort((a, b) => a.countryCode.localeCompare(b.countryCode)).concat(right)
}

@Module({dynamic: true, namespaced: true, name: 'config', store: rootStore})
export class ConfigModule extends VuexModule {

  public serverCfg: ServerCfg = {
    badgesUri: undefined,
    paymentFeeFixedPart: undefined,
    paymentFeePercentagePart: undefined,
    taxPercentage: undefined
  }

  public countriesState: Country[] = []

  public get serverConfig(): ServerCfg {
    return this.serverCfg
  }

  public get isConfigLoaded(): boolean {
    return this.serverCfg.badgesUri !== undefined
  }

  public get isCountriesLoaded(): boolean {
    return this.countriesState.length > 0
  }

  public get countries(): Country[] {
    return this.countriesState
  }

  @Mutation
  public setServerConfig(config: ServerCfg): void {
    this.serverCfg = config
  }

  @Mutation
  public setCountries(countries: []): void {
    this.countriesState = shiftPriorityCountriesInFront(countries)
  }

  @Action({rawError: true})
  public async getCountries(): Promise<Country[]> {
    if (!this.isCountriesLoaded) {
      const resp = await axios.get<Country[]>(BackendUrls.countries)
      this.context.commit('setCountries', resp.data)
    }
    return this.countries
  }

  @Action({rawError: true})
  public async getServerConfig(): Promise<ServerCfg | number> {
    if (!this.isConfigLoaded) {
      const resp = await axios.get<ServerCfg>(BackendUrls.config)
      if (resp.status !== HttpStatuses.OK) {
        return resp.status
      } else {
        this.context.commit('setServerConfig', resp.data)
      }
    }
    return this.serverConfig
  }
}

export const configStore = getModule(ConfigModule, rootStore)
