export enum BasicEvents {
  Input = "input",
  Change = "change",
  Update = "update",
  Focus = "focus",
  Blur = "blur",
  Submit = "submit",
  Click = "click",
  Keydown = "keydown",
  Keyup = "keyup",
  Mousedown = "mousedown",
  Mouseup = "mouseup"
}

export enum CustomEvents {
  Add = "add",
  Remove = "remove",
  SetInputType = "set-input-type",
  FlashMsg = 'flashMsg',
  DismissMsg = 'dismissMsg',
  ToastMsg = 'toastMsg',
  DismissToast = 'dismissToast'
}