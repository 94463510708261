import {AxiosResponse} from "axios";

export abstract class ApplicationError extends Error {
  public readonly rootCause?: Error

  protected constructor(message?: string, rootCause?: Error) {
    super(message)
    this.rootCause = rootCause
    Object.setPrototypeOf(this, ApplicationError.prototype)
  }
}

export class UnexpectedInternalError extends ApplicationError {
  public constructor(message?: string, rootCause?: Error) {
    super(message, rootCause)
    Object.setPrototypeOf(this, UnexpectedInternalError.prototype)
  }
}

export class ServerUnavailableError extends ApplicationError {
  public constructor(message?: string, rootCause?: Error) {
    super(message, rootCause)
    Object.setPrototypeOf(this, ServerUnavailableError.prototype)
  }
}

export class UnexpectedServerError extends ApplicationError {
  public constructor(message?: string, rootCause?: Error) {
    super(message, rootCause)
    Object.setPrototypeOf(this, UnexpectedServerError.prototype)
  }
}

export class RequestCancelledError extends ApplicationError {
  public constructor(message: string, rootCause?: Error) {
    super(message, rootCause)
    Object.setPrototypeOf(this, RequestCancelledError.prototype)
  }
}

export class ResourceNotFoundError extends ApplicationError {
  public readonly path: string
  public constructor(path: string, message?: string, rootCause?: Error) {
    super(message, rootCause)
    this.path = path
    Object.setPrototypeOf(this, ResourceNotFoundError.prototype)
  }
}

export class BadRequestError extends ApplicationError {
  public readonly code?: string

  public constructor(code?: string, message?: string, rootCause?: Error) {
    console.log(code, message, rootCause)
    super(message, rootCause)
    this.code = code
    Object.setPrototypeOf(this, BadRequestError.prototype)
  }
}

export class AuthenticationError extends ApplicationError {
  public readonly code?: string
  public constructor(message: string, code?: string, rootCause?: Error) {
    super(message, rootCause)
    this.code = code
    Object.setPrototypeOf(this, AuthenticationError.prototype)
  }
}

export class BackendError extends ApplicationError {
  public readonly code?: string

  public constructor(code?: string, message?: string, rootCause?: Error) {
    super(message, rootCause);
    this.code = code
    Object.setPrototypeOf(this, BackendError.prototype)
  }
}

export class UnexpectedServerResponseError extends ApplicationError {
  public static throwOnStatusMismatch(response: AxiosResponse<unknown>, expectedStatus: number): void {
    if (response.status !== expectedStatus) {
      throw new UnexpectedServerResponseError([expectedStatus], response.status)
    }
  }

  public readonly expectedStatus: number[]
  public readonly actualStatus: number

  public constructor(expectedStatus: number[], actualStatus: number, message?: string, rootCause?: Error) {
    super(message, rootCause)
    this.expectedStatus = expectedStatus
    this.actualStatus = actualStatus
    Object.setPrototypeOf(this, UnexpectedServerResponseError.prototype)
  }
}