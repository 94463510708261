import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent"
import {
  AboutUs,
  PaymentAgreement,
  PrivacyPolicy,
  ProcedureForResolvingClaims,
  TermsAndConditions,
  ThankYou
} from "@/router/routes"
import * as ServicesRoutes from "@/_modules/service/router/routes"
import * as DemandsRoutes from "@/_modules/demand/router/routes"
import { getServiceTypesSorted, getServiceTypeTranslation } from "@/_modules/service/store/services"
import { appStore } from "@/store"

@Component({name: "Footer"})
export default class Footer extends TsxComponent<{}> {

  public render(): VNode {
    return (
      <footer class="footer d-flex flex-column">
        <div class="w-100 bg-white">
          <b-container fluid="xl">
            <b-row class="mt-10 mt-md-20 justify-content-between footer-content">
              <b-col lg="3" class="mb-10">
                <b-row>
                  <b-col>
                    <b-img src={require('@/assets/images/logo.png')} srcset={[`${require('@/assets/images/logo.png')} 1x, ${require('@/assets/images/logo@2x.png')} 2x, ${require('@/assets/images/logo@3x.png')} 3x`]} width="192" height="35" alt="naabrid.ee" />
                  </b-col>
                </b-row>
                <b-row class="mt-6 mt-md-8">
                  <b-col>
                    <a aria-label="Youtube" class="p-1 mr-3 social-icon" href="https://www.youtube.com/channel/UCE_l3dIPNpnVmlwZEISh7fg">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M22.988 10.125a24.94 24.94 0 00-.104-1.706 15.788 15.788 0 00-.265-1.844 2.92 2.92 0 00-.853-1.538 2.596 2.596 0 00-1.528-.725C18.42 4.104 15.675 4 12 4s-6.42.104-8.238.312a2.569 2.569 0 00-1.522.725c-.434.417-.716.93-.847 1.538a14.07 14.07 0 00-.276 1.844 24.973 24.973 0 00-.105 1.706C1.004 10.575 1 11.2 1 12s.004 1.425.012 1.875c.009.45.043 1.019.105 1.706.061.688.149 1.302.264 1.844.13.608.415 1.12.853 1.537.438.417.947.659 1.528.725C5.58 19.896 8.325 20 12 20s6.42-.104 8.238-.313a2.569 2.569 0 001.522-.725c.434-.416.716-.929.847-1.537a14.09 14.09 0 00.277-1.844c.06-.687.095-1.256.104-1.706.008-.45.012-1.075.012-1.875s-.004-1.425-.012-1.875zm-6.642 2.55l-6.286 4a.691.691 0 01-.417.125.837.837 0 01-.38-.1.746.746 0 01-.406-.7V8c0-.316.135-.55.405-.7.279-.15.545-.142.798.025l6.286 4c.245.142.368.367.368.675 0 .308-.122.534-.368.675z" fill="currentColor"/>
                      </svg>
                    </a>
                    <a aria-label="Facebook" class="p-1 mr-3 social-icon" href="https://www.facebook.com/naabrid.ee/">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M6 12.965h3.547V23h4.242V12.963h3.538l.532-3.91h-4.07V6.556c0-1.132.33-1.904 2.035-1.904H18V1.153C17.624 1.107 16.334 1 14.83 1c-3.136 0-5.283 1.823-5.283 5.17v2.884H6v3.911z" fill="currentColor"/>
                      </svg>
                    </a>
                    <a aria-label="Instagram" class="p-1 social-icon" href="https://www.instagram.com/naabrid.ee/">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7a5 5 0 100 10 5 5 0 000-10zm-3.333 5a3.333 3.333 0 116.666 0 3.333 3.333 0 01-6.666 0z" fill="currentColor"/>
                        <path d="M18.667 6.583a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z" fill="currentColor"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.833 2A5.833 5.833 0 002 7.833v8.334A5.833 5.833 0 007.833 22h8.334A5.833 5.833 0 0022 16.167V7.833A5.833 5.833 0 0016.167 2H7.833zM3.667 7.833a4.167 4.167 0 014.166-4.166h8.334a4.167 4.167 0 014.166 4.166v8.334a4.167 4.167 0 01-4.166 4.166H7.833a4.167 4.167 0 01-4.166-4.166V7.833z" fill="currentColor"/>
                      </svg>
                    </a>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="9" class="mb-10">
                <div class="footer-links d-flex flex-column flex-md-row justify-content-md-between justify-content-lg-end small">
                  <ul class="list-unstyled mr-lg-10 mr-xl-20 mb-6 mb-md-4">
                    <li class="footer-title font-weight-bold">{this.translation("title_footer_users")}</li>
                    <li>
                      <b-link to={ServicesRoutes.ServiceFind.path}>
                        {this.translation("menu_item_find_assistant")}
                      </b-link>
                    </li>
                    <li>
                      <b-link to={DemandsRoutes.DemandFind.path}>
                        {this.translation("menu_item_find_customer")}
                      </b-link>
                    </li>
                    {/*<li>{this.translation("menu_item_post_skill")}</li>*/}
                    {/*
                      <li>{this.translation("menu_item_view_skills")}</li>
                      <li>{this.translation("menu_item_educational_videos")}</li>
                    */}
                    {/*
                    <li>{this.translation("menu_item_support_center")}</li>
                    */}
                  </ul>
                  <ul class="list-unstyled mr-lg-10 mr-xl-20 mb-6 mb-md-4">
                    <li class="footer-title font-weight-bold">{this.translation("title_footer_popular_categories")}</li>
                    {getServiceTypesSorted(appStore.locale, false).map((type) => {
                      return (
                        <li>
                          <b-link
                            to={{
                              path: ServicesRoutes.ServiceFind.path,
                              query: {
                                topLevelCategory: type.category
                              }
                            }}
                          >
                            {getServiceTypeTranslation(appStore.locale, type)}
                          </b-link>
                        </li>
                      )
                    })}
                  </ul>
                  <ul class="list-unstyled">
                    <li class="footer-title font-weight-bold">{this.translation("title_footer_company")}</li>
                    <li>
                      <b-link to={AboutUs}>
                        {this.translation("menu_item_about")}
                      </b-link>
                    </li>
                    <li>
                      <b-link to={TermsAndConditions}>
                        {this.translation("menu_general_terms_and_conditions")}
                      </b-link>
                    </li>
                    <li>
                      <b-link to={PrivacyPolicy}>
                        {this.translation("menu_item_privacy_policy")}
                      </b-link>
                    </li>
                    <li>
                      <b-link to={ProcedureForResolvingClaims}>
                        {this.translation('menu_item_procedure_for_resolving_claims')}
                      </b-link>
                    </li>
                    <li>
                      <b-link to={PaymentAgreement}>
                        {this.translation('menu_item_payment_terms')}
                      </b-link>
                    </li>
                    <li>
                      <b-link to={ThankYou}>
                        {this.translation('menu_item_thank_you')}
                      </b-link>
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </footer>
    )
  }

}

