export default class {
  private static generateStringFromValues(length: number, possibleValues: string): string {
    if (length <= 0) {
      throw Error('length must be positive number!')
    }
    if (possibleValues === undefined || possibleValues.length === 0) {
      throw Error('possibleValues must be provided!')
    }

    let text = ""

    for (let i = 0; i < length; i++) {
      text += possibleValues.charAt(Math.floor(Math.random() * possibleValues.length))
    }

    return text
  }

  public static generateString(length: number): string {
    return this.generateStringFromValues(length, "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789")
  }

  public static generateElementId(): string {
    const numbersLength = 17
    const lettersLength = 2
    return this.generateStringFromValues(lettersLength, "abcdefghijklmnopqrstuvwxyz") + '_' + this.generateStringFromValues(numbersLength, "0123456789")
  }
}
