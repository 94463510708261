import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";
import ContentPage from "@/components/ContentPage";

@Component({name: 'ThankYou'})
export default class extends I18nComponent {
  public render(): VNode {
    return (
      <ContentPage
        title={this.translation('route_thankyou')}
      >
        <div class="d-flex flex-wrap justify-content-center align-items-center logo-section">
          <a href="https://inkubaator.tallinn.ee" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
            <b-img width="170" src={require('@/assets/images/Footer/inkubaator.png')}/>
          </a>
          <a href="https://www.eoc.ee" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
            <b-img width="58" src={require('@/assets/images/Footer/eoc.png')}/>
          </a>
          <a href="https://www.digital-magic.io" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
            <b-img width="79" src={require('@/assets/images/Footer/digital-magic.png')}/>
          </a>
          <a href="https://walless.com" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
            <b-img width="200" src={require('@/assets/images/Footer/walless.svg')}/>
          </a>
          <a href="https://startupkotiki.com" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
            <b-img width="78" src={require('@/assets/images/Footer/cats.png')}/>
          </a>
          <i18n class="small text-muted text-center w-100" tag="p" path="footer_icons_attribution">
            <a class="text-muted" target="_blank" rel="noopener noreferrer" href="https://www.freepik.com">Freepik</a>
            <a class="text-muted" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/">www.flaticon.com</a>
          </i18n>
        </div>
      </ContentPage>
    )
  }
}
