import { ImportedComponent } from "vue/types/options"
import * as Routes from "./routes"

export default [
  {
    path: Routes.Login.path,
    name: Routes.Login.name,
    meta: {
      secured: false
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/SignIn")
  },
  {
    path: Routes.Signup.path,
    name: Routes.Signup.name,
    meta: {
      secured: false
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/SignUp")
  },
  {
    path: Routes.ForgotPassword.path,
    name: Routes.ForgotPassword.name,
    meta: {
      secured: false
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/ForgotPassword")
  },
  {
    path: Routes.CompleteRegistrationViaEmail.path,
    name: Routes.CompleteRegistrationViaEmail.name,
    meta: {
      secured: false
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/CompleteRegistration")
  },
  {
    path: Routes.EmailVerification.path,
    name: Routes.EmailVerification.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/EmailVerification")
  },
  {
    path: Routes.ResetPassword.path,
    name: Routes.ResetPassword.name,
    meta: {
      secured: false
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/ResetPassword")
  },
  {
    path: Routes.Logout.path,
    name: Routes.Logout.name,
    meta: {
      secured: false
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/Logout")
  },
  {
    path: Routes.Profile.path,
    name: Routes.Profile.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/Profile")
  },
  {
    path: Routes.ChangePassword.path,
    name: Routes.ChangePassword.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/ChangePassword")
  },
  {
    path: Routes.SetupPassword.path,
    name: Routes.SetupPassword.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "profile" */ "../views/SetupPassword")
  }
]