import {Module, Mutation, VuexModule} from "vuex-module-decorators"

import i18n, {AppLang} from "@/i18n"
import {Language, MapCoordinates, Temperature} from "@/types";
import {defaultMapCenter} from "@/constants/ApplicationConfiguration";

export interface AppState {
  locale: Language;
  cookies: boolean;
}

export function convertToLanguage(lang: AppLang): Language {
  return lang.toLocaleUpperCase() as Language
}

export function convertToI18nLang(lang: Language): AppLang {
  switch (lang) {
    case Language.ENG:
      return AppLang.EN
    case Language.EST:
      return AppLang.ET
    case Language.RUS:
      return AppLang.RU
    default:
      throw Error
  }
}

@Module({namespaced: true, name: "app"})
export class AppModule extends VuexModule {
  public appState: AppState = {
    locale: Language.EST,
    cookies: false
  }
  public dismissedNotices: string[] = []
  public temperatureScale: Temperature = Temperature.celsius

  public serviceDetailsCoords = defaultMapCenter
  public demandDetailsCoords = defaultMapCenter

  public get locale(): Language {
    return this.appState.locale
  }

  public get cookieConsent(): boolean {
    return this.appState.cookies
  }

  public get serviceCoords(): MapCoordinates {
    return this.serviceDetailsCoords
  }

  public get demandCoords(): MapCoordinates {
    return this.serviceDetailsCoords
  }

  public get isNoticeDismissed(): Function {
    return (noticeKey: string): boolean => this.dismissedNotices.includes(noticeKey);
  }

  public get preferredTemperatureScale(): Temperature {
    return this.temperatureScale
  }

  @Mutation
  public resetCoords(): void {
    this.serviceDetailsCoords = defaultMapCenter
    this.demandDetailsCoords = defaultMapCenter
  }

  @Mutation
  public setLocale(lang: Language): void {
    this.appState.locale = lang
    i18n.locale = convertToI18nLang(lang)
  }

  @Mutation
  public setCookieConfirm(value: boolean): void {
    this.appState.cookies = value
  }

  @Mutation
  public setServiceDetailsCoords(coords: MapCoordinates): void {
    this.serviceDetailsCoords = coords
  }

  @Mutation
  public setDemandDetailsCoords(coords: MapCoordinates): void {
    this.serviceDetailsCoords = coords
  }

  @Mutation
  public setNoticeDismissed(noticeKey: string): void {
    this.dismissedNotices.push(noticeKey);
  }

  @Mutation
  public setPreferredTemperatureScale(scale: Temperature): void {
    this.temperatureScale = scale;
  }
}
