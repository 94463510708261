import { ImportedComponent } from "vue/types/options"
import * as Routes from "./routes"

export default [
  {
    path: Routes.Activity.path,
    name: Routes.Activity.name,
    redirect: Routes.Overview.path
  }, {
    path: Routes.Overview.path,
    name: Routes.Overview.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "Overview" */ "../views/Overview")
  }, {
    path: Routes.Services.path,
    name: Routes.Services.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "Offers" */ "../views/Services")
  },
  {
    path: Routes.Requests.path,
    name: Routes.Requests.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "Requests" */ "../views/Requests")
  },
  {
    path: Routes.Proposals.path,
    name: Routes.Proposals.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "Proposals" */ "../views/Proposals")
  },
  {
    path: Routes.Demands.path,
    name: Routes.Demands.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "Orders" */ "../views/Demands")
  },
  {
    path: Routes.Accounts.path,
    name: Routes.Accounts.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "Accounts" */ "../views/Accounts")
  },
  {
    path: Routes.History.path,
    name: Routes.History.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "History" */ "../views/History")
  }
]