import {Component, Prop} from "vue-property-decorator"
import {BasicEvents} from "@/constants/ComponentEvents"
import {AbstractComponent, AbstractComponentEventHandlers, AbstractComponentPropsType} from "./AbstractComponent"

export interface AbstractInputComponentEventHandlers<ValueType> extends AbstractComponentEventHandlers {
  onInput(v: ValueType | undefined): void;
  onChange(v: ValueType | undefined): void;
}

export interface AbstractInputPropsType<ValueType> extends AbstractComponentPropsType, Partial<AbstractInputComponentEventHandlers<ValueType>> {
  description?: string;
  required?: boolean;
  plaintext?: boolean;
  value?: ValueType;
  vModel?: ValueType;
  invalidMessage?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component
export abstract class AbstractInputComponent<ValueType, T extends AbstractInputPropsType<ValueType>> extends AbstractComponent<T> implements AbstractInputPropsType<ValueType>, AbstractInputComponentEventHandlers<ValueType> {

  @Prop(String) public readonly description?: string
  @Prop(Boolean) public readonly required!: boolean
  @Prop(Boolean) public readonly plaintext!: boolean
  @Prop(String) public readonly invalidMessage?: string
  public abstract readonly value?: ValueType

  public focused = false

  public abstract isEmpty(): boolean

  public get state(): boolean | undefined {
    return this.invalidMessage !== undefined ? false : undefined
  }

  /*
  public get classes(): object {
    return {
      focused: this.focused,
      disabled: this.disabled,
      plaintext: this.plaintext,
      nonEmpty: !this.isEmpty()
    }
  }
  */

  public onInput(v: ValueType | undefined): void {
    this.$emit(BasicEvents.Input, v)
  }

  public onChange(v: ValueType | undefined): void {
    this.$emit(BasicEvents.Change, v)
  }

  public onFocus(e: Event): void {
    if (!this.plaintext) {
      this.focused = true
      this.$emit(BasicEvents.Focus, e)
    }
  }

  public onBlur(e: Event): void {
    if (!this.plaintext) {
      this.focused = false
      this.$emit(BasicEvents.Blur, e)
    }
  }
}