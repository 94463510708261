import Component from "vue-class-component";
import VueMultiSelect from "vue-multiselect"
import { VNode } from "vue/types/umd";
import { Location } from "vue-router";

import { I18nComponent } from "@/components/I18nComponent"
import { getServiceTranslationPropertyName, getServiceTypesSorted, getServiceTypeTranslationByKey } from "@/_modules/service/store/services";
import { ServiceType } from "@/_modules/service/types";
import { appStore } from "@/store";

import * as DemandsRoutes from "@/_modules/demand/router/routes"
import * as ServiceRoutes from "@/_modules/service/router/routes"
import { BIconSearch } from "bootstrap-vue";
import { RouteItem } from "@/router/routes";
import { Prop } from "vue-property-decorator";

interface Form {
  topLevelCategory?: string;
}

@Component({name: "SelectServiceForm", components: { BIconSearch }})
export default class SelectServiceForm extends I18nComponent {
  @Prop(Object) public readonly value!: Location

  private form: Form = {
    topLevelCategory: undefined
  }

  private onSubmitSearch(event: Event): void {
    event.preventDefault();

    const route = this.value;
    route.query = {
      topLevelCategory: this.form.topLevelCategory
    }

    this.$router.push(route)
  }

  private setSearchRoute(route: RouteItem): void {
    this.$emit('setRoute', route);
  }

  public render(): VNode {
    return (
      <b-card class="rounded select-service-form" body-class="py-sm-6">
        <form novalidate onsubmit={(event: Event) => this.onSubmitSearch(event)} class="find-service-form d-flex flex-column flex-md-row">
          <b-button-group class="mr-md-4 mb-2 mb-md-0">
            <b-button
              class="text-nowrap px-4"
              pressed={this.value === ServiceRoutes.ServiceFind}
              variant={this.value === ServiceRoutes.ServiceFind ? 'primary' : 'outline-primary text-body'}
              onClick={() => {
                this.setSearchRoute(ServiceRoutes.ServiceFind);
              }}
            >
              {this.translation('btn_become_requester')}
            </b-button>
            <b-button
              class="text-nowrap px-4"
              pressed={this.value === DemandsRoutes.DemandFind}
              variant={this.value === DemandsRoutes.DemandFind ? 'primary' : 'outline-primary text-body'}
              onClick={() => {
                this.setSearchRoute(DemandsRoutes.DemandFind);
              }}
            >
              {this.translation('button_find_customers')}
            </b-button>
          </b-button-group>
          <div class="flex-fill mr-md-4 mb-2 mb-md-0">
            <VueMultiSelect
              class="custom-control-single-select font-weight-bold"
              multiple={false}
              close-on-select={true}
              preselect-first={false}
              searchable={false}
              show-labels={false}
              placeholder={this.translation('lbl_service_types')}
              options={getServiceTypesSorted(appStore.locale, false)}
              value={getServiceTypesSorted(appStore.locale, false).find(item => item.category === this.form.topLevelCategory)}
              track-by="category"
              label={getServiceTranslationPropertyName(appStore.locale)}
              onInput={(value: ServiceType) => {
                this.form.topLevelCategory = value.category;
              }}
              scopedSlots={{
                singleLabel: ({ option }: { option: ServiceType }): VNode => {
                  return (
                    <span>{getServiceTypeTranslationByKey(appStore.locale, option.category)}</span>
                  )
                }
              }}
            />
          </div>
          <b-button
            type="submit"
            variant="primary"
            class="text-nowrap"
          >
            <b-icon-search class="app-icon-lg mr-2"/> {this.translation('btn_search')}
          </b-button>
        </form>
      </b-card>
    )
  }
}