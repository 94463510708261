export const apiUrl: string = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '/'
const baseWebUrl = 'api/v1'

export enum Header {
  Accept = 'Accept',
  ContentType = 'Content-Type'
}

export enum ContentType {
  JSON = 'application/json'
}

export enum HttpStatuses {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  BackendError = 477
}

export enum ErrorCodes {
  //
  InvalidAuthorizationProvider = "InvalidAuthorizationProvider",
  AuthorizationProcessNotFound = "AuthorizationProcessNotFound",
  AuthorizationProcessNotFinished = "AuthorizationProcessNotFinished",
  AuthorizationProcessExpiredOrInvalid = "AuthorizationProcessExpiredOrInvalid",
  AuthenticationProviderSessionTimedOut = "AuthenticationProviderSessionTimedOut",
  UserAuthorizationFailure = "UserAuthorizationFailure",
  UserHasCancelledAuthorizationProcess = "UserHasCancelledAuthorizationProcess",
  UserNotRegisteredWithAuthenticationProvider = "UserNotRegisteredWithAuthenticationProvider",
  ClientCertificateInvalid = "ClientCertificateInvalid",
  ClientCertificateNotFound = "ClientCertificateNotFound",
  ClientCertificateCannotBeValidated = "ClientCertificateCannotBeValidated",
  SmartIdAccountUpgradeIsRequired = "SmartIdAccountUpgradeIsRequired",
  AccountIsLocked = "AccountIsLocked",
  UserAlreadyBoundToAnotherIDAccount = "UserAlreadyBoundToAnotherIDAccount",
  // other
  UserNotFound = "UserNotFound",
  AccountNotFound = "AccountNotFound",
  MismatchingPassword = "MismatchingPassword",
  AccountNotFoundOrMismatchingPassword = "AccountNotFoundOrMismatchingPassword",
  TokenNotFound = "TokenNotFound",
  AccountUnverified = "AccountUnverified",
  AccountNotProvisioned = "AccountNotProvisioned",
  AccountAlreadyExist = "AccountAlreadyExist",
  MessagingSubsystemFailure = "MessagingSubsystemFailure",
  SessionExpired = "SessionExpired",
  SessionClosed = "SessionClosed",
  SessionMalformed = "SessionMalformed",
  SessionNotFound = "SessionNotFound",
  DatabaseFailure = "DatabaseFailure",
  FileNameAlreadyExists = "FileNameAlreadyExists",
  UserDoesNotHaveProfile = "UserDoesNotHaveProfile",
  FileUploadTokenIsUsedByAnotherUser = "FileUploadTokenIsUsedByAnotherUser",
  OperationIsNotAuthorized = "OperationIsNotAuthorized",
  OperationIsNotSupported = "OperationIsNotSupported",
  DemandNotFound = "DemandNotFound",
  RecaptchaValidationFailed = "RecaptchaValidationFailed",
  ServiceRequestNotFound = "ServiceRequestNotFound",
  UserDoesNotHaveRightToAccess = "UserDoesNotHaveRightToAccess",
  ServiceNotFound = "ServiceNotFound",
  ObjectParsingError = "ObjectParsingError",
  ServiceRequestStatusIncorrect = "ServiceRequestStatusIncorrect",
  ProposalRecordNotFound = "ProposalRecordNotFound",
  ProposalStatusIncorrect = "ProposalStatusIncorrect",
  ProposalVersionIncorrect = "ProposalVersionIncorrect",
  ProposalIsNotLocked = "ProposalIsNotLocked",
  RatingAlreadySet = "RatingAlreadySet",
  MKTransactionAlreadyInProgress = "MKTransactionAlreadyInProgress",
  ServiceTermsAreNotAccepted = "ServiceTermsAreNotAccepted",
  ServiceRequestIsNotLocked = "ServiceRequestIsNotLocked",
  ServiceRequestIsLocked = "ServiceRequestIsLocked",
  ProposalIsLocked = "ProposalIsLocked",
  UserVerifiedAndCannotChangePrimaryData = "UserVerifiedAndCannotChangePrimaryData",
  CannotDeleteIBANDueToExistingServices = "CannotDeleteIBANDueToExistingServices",
  UserDoesNotHaveIBAN = "UserDoesNotHaveIBAN",
  PayloadParsingError = "PayloadParsingError",
  MessageIdNotFound = "MessageIdNotFound",
  CannotCreateDealWithYourself = "CannotCreateDealWithYourself",
  TaskRecurrenceRuleIncorrect = "TaskRecurrenceRuleIncorrect"
}

export const BackendUrls = {
  urlApi: apiUrl,
  urlBaseWeb: baseWebUrl,
  countries: baseWebUrl + '/countries',
  signup: baseWebUrl + '/auth/naabrid/register',
  login: (provider: string): string => baseWebUrl + '/auth/' + provider + '/login',
  logout: baseWebUrl + '/auth/logout',
  touchSession: baseWebUrl + '/session/touch',
  profiles: baseWebUrl + '/profiles',
  settings: baseWebUrl + '/settings',
  changePassword: baseWebUrl + '/user/change-password',
  changeEmail: baseWebUrl + '/user/change-email',
  forgotPassword: baseWebUrl + '/forgotten-password',
  setupPassword: baseWebUrl + '/setup-password',
  resetPassword: baseWebUrl + '/reset-password',
  completeRegistration: baseWebUrl + '/complete-registration',
  emailVerification: baseWebUrl + '/email-verification',
  resendEmailVerification: baseWebUrl + '/resend-email-code',
  validateToken: baseWebUrl + '/validate-token',
  services: baseWebUrl + '/services',
  demands: baseWebUrl + '/demands',
  serviceTypes: baseWebUrl + '/serviceTypes',
  files: baseWebUrl + '/files',
  file: baseWebUrl + '/file',
  supportAsk: baseWebUrl + '/support/ask',
  requests: baseWebUrl + '/requests',
  config: baseWebUrl + '/config',
  proposals: baseWebUrl + '/proposals',
  users: baseWebUrl + '/users',
  static: '/static',
  acceptedVersion: 'accept-version?version=',
  termsAccepted: '&termsAccepted=',
  adminLogin: '/auth/naabrid/admin-login',
  findUser: baseWebUrl + '/admin/find-user?email=',
  qualification: baseWebUrl + '/admin/user-qualification',
  userVerify: baseWebUrl + '/admin/user-verify',
  search: baseWebUrl + '/admin/agreements/search',
  paidToProvider: baseWebUrl + '/admin/agreements/paid-to-provider',
  admFile: baseWebUrl + '/admin/agreements/file',
  incomeReport: baseWebUrl + '/admin/financial/income-report',
  badges: baseWebUrl + '/badges',
  chats: baseWebUrl + '/chats'
}
