import {ProposalDetails} from "@/_modules/proposal/types";
import {defaultMapCenter} from "@/constants/ApplicationConfiguration";
import {Currency, DealType, RateType} from '@/types'
import {Dictionary} from "vue-router/types/router";
import {RateActionType} from "@/views/DealAnnouncer";

export function buildProposal(): ProposalDetails {
  return {
    serviceLanguages: [],
    id: '',
    serviceRate: {
      type: RateType.FIXED_PRICE,
      price: {
        amount: '',
        currency: Currency.EUR
      }
    },
    serviceLocation: {
      address: {
        country: '',
        cityOrCounty: '',
        zipCode: '',
        address: ''
      },
      coordinates: {
        longitude: defaultMapCenter.longitude,
        latitude: defaultMapCenter.latitude
      }
    },
    demandId: '',
    title: '',
    requester: {
      userId: '',
      name: {
        first: '',
        last: ''
      },
      sex: undefined,
      verified: false,
      rating: {
        grade: '0.0',
        ratingsCount: 0
      },
      languages: [],
      location: {
        address: {
          country: '',
          cityOrCounty: '',
          zipCode: '',
          address: ''
        },
        coordinates: {
          longitude: defaultMapCenter.longitude,
          latitude: defaultMapCenter.latitude
        }
      }
    },
    service: {
      clientAge: [],
      clientSex: [],
      clientWeight: [],
      clientDisease: [],
      clientPhysicalActivity: [],
      clientMentalAbility: [],
      competencesWithParent: [],
      rate: {
        type: RateType.HOURLY_PAYMENT,
        price: {
          amount: '',
          currency: Currency.EUR
        }
      },
      languages: [],
      location: {
        address: {
          country: '',
          cityOrCounty: '',
          zipCode: '',
          address: ''
        },
        coordinates: {
          longitude: defaultMapCenter.longitude,
          latitude: defaultMapCenter.latitude
        }
      },
      distanceInMeters: 0,
      provider: {
        userId: '',
        name: {
          first: '',
          last: ''
        },
        verified: false,
        sex: undefined,
        rating: {
          grade: '0.0',
          ratingsCount: 0
        }
      }
    },
    demand: {
      userId: '',
      demandId: '',
      category: '',
      demandDescription_ru: undefined,
      demandDescription_et: undefined,
      demandDescription_en: undefined,
      /*diseaseDescription_ru: undefined,
      diseaseDescription_et: undefined,
      diseaseDescription_en: undefined,*/
      clientAge: undefined,
      clientWeight: undefined,
      clientDisease: [],
      clientMentalAbility: undefined,
      clientPhysicalActivity: [],
      clientRequirementsWithParent: [],
      clientSex: undefined,
      rate: {
        type: RateType.FIXED_PRICE,
        price: {
          amount: '',
          currency: Currency.EUR
        }
      },
      languages: [],
      title: '',
      location: {
        address: {
          country: '',
          cityOrCounty: '',
          zipCode: '',
          address: ''
        },
        coordinates: {
          longitude: defaultMapCenter.longitude,
          latitude: defaultMapCenter.latitude
        }
      },
      requestedAtClientPlace: true
    },
    tasks: [],
    price: {
      servicePrice: {
        amount: '0',
        currency: Currency.EUR
      },
      tax: {
        amount: '0',
        currency: Currency.EUR
      },
      total: {
        amount: '0',
        currency: Currency.EUR
      },
      calculatePrice: true
    },
    lockState: {
      status: '',
      timeLeftInSeconds: 0,
      lockedBy: {
        first: '',
        last: ''
      }
    },
    changesToAccept: [],
    version: 0,
    status: '',
    acceptedLatestVersion: false,
    isRatedBy: '',
    servicePriceOverrided: false,
    createdAt: new Date(),
    agreementFileId: '',
    agreementNumber: '',
    calculatePrice: true
  }
}

export function onProposalCreatePayload(proposal: ProposalDetails, proposalId: string): Dictionary<string> {
  return {
    greetingTitle: 'proposal.title_msg_on_create',
    msg: 'proposal.msg_on_create',
    msgParams: `${proposal.title}&&${proposal.requester.name.first} ${proposal.requester.name.last}`,
    dealDate: proposal.createdAt.toString(),
    dealTitle: proposal.title,
    dealType: DealType.Proposal,
    fromDraft: 'true',
    dealId: proposalId
  }
}

export function onProposalUpdateByRequesterPayload(proposal: ProposalDetails): Dictionary<string> {
  return {
    greetingTitle: 'proposal.title_msg_on_update_by_requester',
    msg: 'proposal.msg_on_update_by_requester',
    msgParams: `${proposal.title}&&${proposal.service.provider.name.first} ${proposal.service.provider.name.last}`,
    dealDate: proposal.createdAt.toString(),
    dealTitle: proposal.title,
    dealType: DealType.Proposal,
    agreementFileId: proposal.agreementFileId,
    agreementNumber: proposal.agreementNumber
  }
}

export function onProposalUpdateByProviderPayload(proposal: ProposalDetails): Dictionary<string> {
  return {
    greetingTitle: 'proposal.title_msg_on_update_by_provider',
    msg: 'proposal.msg_on_update_by_provider',
    msgParams: `${proposal.title}&&${proposal.requester.name.first} ${proposal.requester.name.last}`,
    dealDate: proposal.createdAt.toString(),
    dealTitle: proposal.title,
    dealType: DealType.Proposal,
    agreementFileId: proposal.agreementFileId,
    agreementNumber: proposal.agreementNumber
  }
}

export function onAcceptProposalCurrentVersionByProviderPayload(proposal: ProposalDetails, isConcluded: boolean): Dictionary<string> {
  return {
    greetingTitle: isConcluded ? 'proposal.title_msg_on_deal_concluded_by_provider' : 'proposal.title_msg_on_accepted_version_by_provider',
    msg: isConcluded ? 'proposal.msg_on_deal_concluded_by_provider' : 'proposal.msg_on_accepted_version_by_provider',
    msgParams: `${proposal.title}&&${proposal.requester.name.first} ${proposal.requester.name.last}`,
    dealDate: proposal.createdAt.toString(),
    dealTitle: proposal.title,
    dealType: DealType.Proposal,
    agreementFileId: proposal.agreementFileId,
    agreementNumber: proposal.agreementNumber
  }
}

export function onAcceptProposalCurrentVersionByRequesterPayload(proposal: ProposalDetails, isConcluded: boolean): Dictionary<string> {
  if (isConcluded) {
    return {
      greetingTitle: 'proposal.title_msg_on_deal_concluded_by_requester',
      msg: 'proposal.msg_on_deal_concluded_by_requester',
      msgParams: `${proposal.title}&&${proposal.service.provider.name.first} ${proposal.service.provider.name.last}`,
      dealDate: proposal.createdAt.toString(),
      dealTitle: proposal.title,
      dealType: DealType.Proposal,
      agreementFileId: proposal.agreementFileId,
      agreementNumber: proposal.agreementNumber,
      singleButtonBack: 'btn.pay'
    }
  } else {
    return {
      greetingTitle: 'proposal.title_msg_on_accepted_version_by_requester',
      msg: 'proposal.msg_on_accepted_version_by_requester',
      msgParams: `${proposal.title}&&${proposal.service.provider.name.first} ${proposal.service.provider.name.last}`,
      dealDate: proposal.createdAt.toString(),
      dealTitle: proposal.title,
      dealType: DealType.Proposal,
      agreementFileId: proposal.agreementFileId,
      agreementNumber: proposal.agreementNumber
    }
  }
}

export function onProposalCancelByProviderPayload(proposal: ProposalDetails): Dictionary<string> {
  return {
    greetingTitle: 'proposal.title_msg_on_cancel',
    msg: 'proposal.msg_on_cancel',
    msgParams: `${proposal.title}&&${proposal.requester.name.first} ${proposal.requester.name.last}`,
    dealCancelled: 'true',
    dealType: DealType.Proposal
  }
}

export function onProposalCancelByRequesterPayload(proposal: ProposalDetails): Dictionary<string> {
  return {
    greetingTitle: 'proposal.title_msg_on_declined',
    msg: 'proposal.msg_on_declined',
    msgParams: `${proposal.title}&&${proposal.service.provider.name.first} ${proposal.service.provider.name.last}`,
    dealCancelled: 'true',
    dealType: DealType.Proposal
  }
}

export function onProposalSetRatingByProviderPayload(proposal: ProposalDetails, proposalId: string): Dictionary<string> {
  return {
    greetingTitle: 'proposal.title_msg_on_rating_send_provider',
    msg: 'proposal.msg_on_rating_send_provider',
    msgParams: `${proposal.title}&&${proposal.requester.name.first} ${proposal.requester.name.last}`,
    rateActionType: RateActionType.Rating,
    dealDate: proposal.createdAt.toString(),
    dealId: proposalId,
    dealTitle: proposal.title,
    dealType: DealType.Proposal,
    agreementFileId: proposal.agreementFileId,
    agreementNumber: proposal.agreementNumber
  }
}

export function onProposalSetRatingByRequesterPayload(proposal: ProposalDetails, proposalId: string): Dictionary<string> {
  return {
    greetingTitle: 'proposal.title_msg_on_rating_send_requester',
    msg: 'proposal.msg_on_rating_send_requester',
    msgParams: `${proposal.title}&&${proposal.service.provider.name.first} ${proposal.service.provider.name.last}`,
    rateActionType: RateActionType.Confirm,
    dealDate: proposal.createdAt.toString(),
    dealId: proposalId,
    dealTitle: proposal.title,
    dealType: DealType.Proposal,
    agreementFileId: proposal.agreementFileId,
    agreementNumber: proposal.agreementNumber
  }
}

export function onProposalAcceptToDiscussion(proposal: ProposalDetails): Dictionary<string> {
  return {
    greetingTitle: 'proposal.title_msg_on_accept_to_discussion',
    msg: 'proposal.msg_on_accept_to_discussion',
    msgParams: `${proposal.title}&&${proposal.service.provider.name.first} ${proposal.service.provider.name.last}`,
    singleButtonBack: 'btn.proceed_to_discussion',
    dealDate: proposal.createdAt.toString(),
    dealTitle: proposal.title,
    dealType: DealType.Proposal,
    agreementFileId: proposal.agreementFileId,
    agreementNumber: proposal.agreementNumber
  }
}
