import * as RequestRoutes from "./request-service-routes"
import {MessagingCenter} from "@/_modules/message-center/router/routes"
import { ImportedComponent } from "vue/types/options"

export default [
  {
    path: `${RequestRoutes.RequestServiceDraft.path}/:serviceId`,
    name: RequestRoutes.RequestServiceDraft.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-service-store" */ "../views/Request")
  },
  {
    path: `${RequestRoutes.RequestRequester.path}/:requestId`,
    name: RequestRoutes.RequestRequester.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-service-store" */ "../views/Request")
  },
  {
    path: `${RequestRoutes.RequestProvider.path}/:requestId`,
    name: RequestRoutes.RequestProvider.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-service-store" */ "../views/Request")
  },
  {
    path: `${RequestRoutes.FromOverviewRequestProvider.path}/:requestId`,
    name: `${RequestRoutes.FromOverviewRequestProvider.name}`,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-service-store" */ "../views/Request")
  },
  {
    path: `${RequestRoutes.FromOverviewRequestRequester.path}/:requestId`,
    name: `${RequestRoutes.FromOverviewRequestRequester.name}`,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-service-store" */ "../views/Request")
  },
  {
    path: `${MessagingCenter.path}/request/:requestId`,
    name: `${MessagingCenter.name}-request`,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-service-store" */ "../views/Request")
  }
]