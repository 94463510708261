import { Component, Prop } from "vue-property-decorator"
import { VNode } from "vue"
import { TsxComponent } from "./TsxComponent";
import PageHeader from "@/components/layout/PageHeader"

interface Props {
  title: string;
  wideHeader?: boolean;
}

@Component({name: 'ContentPage'})
export default class extends TsxComponent<Props> implements Props {
  @Prop({ type: String, required: true }) public readonly title!: string
  @Prop({type: Boolean, default: false}) public readonly wideHeader?: boolean

  public render(): VNode {
    // Content slot is default
    if (this.$slots.default !== undefined && this.$slots.content === undefined) {
      this.$slots.content = this.$slots.default;
    }

    return (
      <b-container fluid="xl">
        <b-row>
          <PageHeader
            title={this.title}
            wideHeader={this.wideHeader}
          >
            {this.$slots.lead !== undefined && this.$slots.lead}
          </PageHeader>
          {this.$slots.content !== undefined && 
            <b-col cols="12">
              <b-card body-class="pt-6 pb-8 py-sm-12 pt-md-16 px-lg-16 content-page">
                {this.$slots.content}
              </b-card>
              {this.$slots.append !== undefined && 
                this.$slots.append
              }
            </b-col>
          }
        </b-row>
      </b-container>
    )
  }
}
