import Vue from "vue"
import Vuex from "vuex"

import createPersistedState from "vuex-persistedstate"

// There is no reason to make these stores dynamic, because they are used for every page
import {getModule} from "vuex-module-decorators"
import {AppModule} from "./app"
import {AuthModule} from "./auth"
import {ServiceAndDemandStore} from "@/store/service-and-demand-store";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

const rootStore = new Vuex.Store({
  modules: {
    app: AppModule,
    auth: AuthModule,
    userData: ServiceAndDemandStore
  },
  strict: debug,
  plugins: [
    createPersistedState({
      paths: ["app"]
    }),
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['auth', 'userData']
    })
  ]
})

export default rootStore

// Define constants to export them globally as a singleton
const appStore = getModule(AppModule, rootStore)
const authStore = getModule(AuthModule, rootStore)
const serviceAndDemandStore = getModule(ServiceAndDemandStore, rootStore)
export {appStore, authStore, serviceAndDemandStore}
