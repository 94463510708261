import {isEmpty} from "@/constants/DataBoundaries";
import moment from "moment";

const personalCodeLength = {
  "EE": 11,
  "LV": 12,
  "LT": 11
}

function getDateOfBirth(code: string): moment.Moment | undefined {
  const estonianPersonalCodeRegExp = /^([1-6])([\d]{2})([01]\d)([0123]\d)([\d]{3})([\d])$/

  const groups = estonianPersonalCodeRegExp.exec(code)

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (groups === null || groups.length !== 7) {
    return undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const year: number = 1800 + (100 * Math.floor((+groups[1] - 1) / 2)) + +groups[2]

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const month: string = groups[3]

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const day: string = groups[4]

  const dob = moment(`${year}${month}${day}`, "YYYYMMDD", true)

  if (!dob.isValid()) {
    return undefined
  }

  return dob
}

export function validateEstonianOrLithuanianPersonalCode(code: string): boolean {

  function getControlNumber(theCode: string): number {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const multiplier1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1]
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const multiplier2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3]

    function getTotal(multiplier: number[]): number {
      return theCode.slice(0, -1).split('').map((x, index) => parseInt(x) * multiplier[index]).reduce((x, y) => x + y)
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    let mod = getTotal(multiplier1) % 11;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (mod === 10) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      mod = getTotal(multiplier2) % 11;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (mod === 10) {
        mod = 0;
      }
    }
    return mod;
  }

  if (code.length !== personalCodeLength['EE'] || code.length !== personalCodeLength['LT']) {
    return false;
  }

  const dob = getDateOfBirth(code)

  if (dob === undefined || dob.isAfter(moment().startOf('day'))) {
    // a birth date in the future or invalid
    return false;
  }

  return getControlNumber(code) === parseInt(code.charAt(personalCodeLength['EE'] - 1));
}

export function validateLatvianPersonalCode(code: string): boolean {
  if (code.length !== personalCodeLength['LV'] || code.length !== personalCodeLength['LV']) {
    return false;
  }

  const regExp = /^([\d]{6})-([\d]{5})$/

  const groups = regExp.exec(code)

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return !(groups === null || groups.length !== 3);
}

export function validatePersonalCode(): (v: string | undefined | null) => boolean {

  function validate(code: string): boolean {
    switch (code.length) {
      case personalCodeLength['EE']:
      case personalCodeLength['LT']:
        return validateEstonianOrLithuanianPersonalCode(code)
      case personalCodeLength['LV']:
        return validateLatvianPersonalCode(code)
      default:
        return false
    }
  }

  return (v: string | undefined | null) => isEmpty(v) || validate(v as string)
}

function validateEstonianOrLithuanianAge(code: string, minAge: number): boolean {
  const dob = getDateOfBirth(code)

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return !(dob === undefined || dob.isAfter(moment().subtract(minAge, 'years').startOf('day')))
}

export function validateAgeRestriction(): (v: string | undefined | null) => boolean {

  function validate(code: string): boolean {
    switch (code.length) {
      case personalCodeLength['EE']:
      case personalCodeLength['LT']:
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return validateEstonianOrLithuanianAge(code, 18)
      case personalCodeLength['LV']:
        return true // cannot be validated for new ID's
      default:
        return false
    }
  }

  return (v: string | undefined | null) => isEmpty(v) || validate(v as string)
}
