import Component from "vue-class-component";
import { VNode } from "vue/types/umd";
import { Location } from "vue-router";

import { I18nComponent } from "@/components/I18nComponent"
import { getServiceTypesSorted, getServiceTypeTranslation } from "@/_modules/service/store/services";
import { appStore } from "@/store";

import * as DemandsRoutes from "@/_modules/demand/router/routes"
import * as ServiceRoutes from "@/_modules/service/router/routes"
import { RouteItem } from "@/router/routes";
import { Prop } from "vue-property-decorator";
import LinkedCard from "./layout/LinkedCard";
import { ServiceType } from "@/_modules/service/types";

@Component({name: "SelectServiceCards"})
export default class SelectServiceCards extends I18nComponent {
  @Prop(Object) public readonly value!: Location

  private getCategoryRoute(type: ServiceType): Location {
    return {
      ...this.value,
      ...{
        query: {
          topLevelCategory: type.category
        }
      }
    };
  }

  private setSearchRoute(route: RouteItem): void {
    this.$emit('setRoute', route);
  }

  public render(): VNode {
    return (
      <b-col cols="12">
        <div class="d-flex justify-content-center">
          <b-button-group class="mb-6 mb-md-10 flex-fill flex-sm-grow-0">
            <b-button
              class="text-nowrap px-4"
              pressed={this.value === ServiceRoutes.ServiceFind}
              variant={this.value === ServiceRoutes.ServiceFind ? 'primary' : 'outline-primary text-body'}
              onClick={() => {
                this.setSearchRoute(ServiceRoutes.ServiceFind);
              }}
            >
              {this.translation('button_card_find_assistants')}
            </b-button>
            <b-button
              class="text-nowrap px-4"
              pressed={this.value === DemandsRoutes.DemandFind}
              variant={this.value === DemandsRoutes.DemandFind ? 'primary' : 'outline-primary text-body'}
              onClick={() => {
                this.setSearchRoute(DemandsRoutes.DemandFind);
              }}
            >
              {this.translation('button_find_customers')}
            </b-button>
          </b-button-group>
        </div>

        <b-row class="mb-md-4 row-sm-narrow">
          {getServiceTypesSorted(appStore.locale, false).map((type, index) => {
            return <b-col cols="12" sm={index === 0 ? '12' : '6'} md={index <= 1 ? '6' : '4'}>
              <LinkedCard
                cardImage={require(`@/assets/images/Service/${type.category.toLowerCase()}.jpg`)}
                title={getServiceTypeTranslation(appStore.locale, type)}
                to={this.getCategoryRoute(type)}
              />
            </b-col>
          })}
        </b-row>
      </b-col>
    )
  }
}
