import {Module, VuexModule, Action, getModule} from "vuex-module-decorators"
import rootStore from "@/store"

import axios from "@/api"
import HttpStatus from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"
import {UnexpectedServerResponseError} from "@/utils/errors"
import {ServiceDetails} from "@/_modules/service/types";
import {PersonalSettings} from "@/_modules/profile/types";
import {AcceptReqVersionPayload, LockState, Message, RequestNewMessagePayload, NewRequest, RequestDetails, RequestPriceAndTasks, RequestTaskAsCompleteOrConfirmPayload, RequestUpdatePayload, ServicesRates} from "@/_modules/request/types";
import {DiaryDataForm, FileData, PaymentMethods, PricesTaxesTasks, RequestPaymentPayload, SendRequestRatingPayload, NurseDiaryReportPayload} from '@/types'
import {AxiosResponse} from "axios";
import fileDownload from "js-file-download"


@Module({dynamic: true, namespaced: true, name: 'request-service-store', store: rootStore})
export class ServiceRequestModule extends VuexModule {

  @Action({rawError: true})
  public async getServiceDetailsById(serviceId: string): Promise<ServiceDetails> {
    const resp = await axios.get<ServiceDetails>(`${BackendUrls.services}/${serviceId}/details`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getSettings(): Promise<PersonalSettings> {
    const resp = await axios.get<PersonalSettings>(BackendUrls.settings)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async postNewRequest(request: NewRequest): Promise<string> {
    const resp = await axios.post<string>(`${BackendUrls.requests}`, request)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.CREATED)
    return resp.data
  }

  @Action({rawError: true})
  public async updateRequestByProvider(payload: RequestUpdatePayload): Promise<void> {
    const requestId = payload.requestId
    const form = {
      tasks: payload.tasks,
      price: payload.price,
      calculatePrice: payload.calculatePrice
    }
    const resp = await axios.put<RequestDetails>(`${BackendUrls.requests}/${requestId}`, form)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async updateRequestByRequester(payload: RequestUpdatePayload): Promise<void> {
    const requestId = payload.requestId
    const form = {
      tasks: payload.tasks
      //donate: payload.donate
    }
    const resp = await axios.put<RequestDetails>(`${BackendUrls.requests}/${requestId}`, form)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async lockRequest(requestId: string): Promise<LockState> {
    const resp = await axios.put<LockState>(`${BackendUrls.requests}/${requestId}/lock`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async unlockRequest(requestId: string): Promise<LockState> {
    const resp = await axios.put<LockState>(`${BackendUrls.requests}/${requestId}/unlock`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
    return resp.data
  }

  @Action({rawError: true})
  public async cancelRequest(requestId: string): Promise<void> {
    const resp = await axios.post(`${BackendUrls.requests}/${requestId}/cancel`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async acceptRequestToDiscussion(requestId: string): Promise<void> {
    const resp = await axios.put(`${BackendUrls.requests}/${requestId}/accept`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async acceptCurrentVersion(payload: AcceptReqVersionPayload): Promise<AxiosResponse<{ dealConcluded: boolean }>> {
    const requestId = payload.requestId
    const reqVersion = payload.reqVersion
    const termsAccepted = payload.termsAccepted
    const resp = await axios.put(`${BackendUrls.requests}/${requestId}/${BackendUrls.acceptedVersion}${reqVersion}${BackendUrls.termsAccepted}${termsAccepted}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp
  }

  @Action({rawError: true})
  public async getRequestById(requestId: string): Promise<RequestDetails> {
    const resp = await axios.get<RequestDetails>(`${BackendUrls.requests}/${requestId}?autolock=false`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true}) // TODO: deprecated?
  public async getConfiguration(): Promise<ServicesRates> {
    const resp = await axios.get<ServicesRates>(BackendUrls.config)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getAllMessages(requestId: string): Promise<Message[]> {
    const resp = await axios.get<Message[]>(`${BackendUrls.requests}/${requestId}/messages`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async sendMessage(payload: RequestNewMessagePayload): Promise<Message> {
    const message = {
      message: payload.message.message,
      uploadToken: payload.message.uploadToken
    }
    const requestId = payload.requestId
    const resp = await axios.post(`${BackendUrls.requests}/${requestId}/messages`, message)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.CREATED)
    return resp.data
  }

  @Action({rawError: true})
  public async getUploadedFiles(uploadToken: string): Promise<FileData[]> {
    const resp = await axios.get(`${BackendUrls.files}/${uploadToken}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async updatePriceAndTasksById(priceDataPayload: RequestPriceAndTasks): Promise<PricesTaxesTasks> {
    const priceData: RequestPriceAndTasks = {
      servicePrice: priceDataPayload.servicePrice,
      tasks: priceDataPayload.tasks,
      calculatePrice: priceDataPayload.calculatePrice
    }
    const requestId = priceDataPayload.requestId
    const resp = await axios.post(`${BackendUrls.requests}/${requestId}/price`, priceData)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async updatePriceAndTasksDraft(priceDataPayload: RequestPriceAndTasks): Promise<PricesTaxesTasks> {
    const priceData = {
      serviceRate: priceDataPayload.serviceRate,
      servicePrice: priceDataPayload.servicePrice,
      tasks: priceDataPayload.tasks
    }
    const resp = await axios.post(`${BackendUrls.requests}/price`, priceData)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  /*@Action({rawError: true})
  public async updatePriceAndTasksTest(priceDataPayload: RequestPriceAndTasks): Promise<PriceWithTaxes> {
    const priceData = {
      serviceRate: priceDataPayload.serviceRate,
      servicePrice: priceDataPayload.servicePrice,
      tasks: priceDataPayload.tasks
    }
    const resp = await axios.post(`${BackendUrls.requests}/price2`, priceData)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }*/

  @Action({rawError: true})
  public async setTaskComplete(taskAsCompletePayload: RequestTaskAsCompleteOrConfirmPayload): Promise<void> {
    const taskId = taskAsCompletePayload.taskId
    const requestId = taskAsCompletePayload.requestId
    const resp = await axios.post(`${BackendUrls.requests}/${requestId}/tasks/${taskId}/complete`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async setTaskCompleteConfirm(taskAsCompleteConfirmPayload: RequestTaskAsCompleteOrConfirmPayload): Promise<void> {
    const taskId = taskAsCompleteConfirmPayload.taskId
    const requestId = taskAsCompleteConfirmPayload.requestId
    const resp = await axios.post(`${BackendUrls.requests}/${requestId}/tasks/${taskId}/confirm-complete`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async sendRating(sendRatingPayload: SendRequestRatingPayload): Promise<void> {
    const requestId = sendRatingPayload.requestId
    const rating = {rating: sendRatingPayload.rating}
    const resp = await axios.post(`${BackendUrls.requests}/${requestId}/rating`, rating)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async payForServiceInRequest(payload: RequestPaymentPayload): Promise<PaymentMethods> {
    const requestId = payload.requestId
    const paymentTermsAccepted = payload.paymentTermsAccepted
    const resp = await axios.post(`${BackendUrls.requests}/${requestId}/pay?paymentTermsAccepted=${paymentTermsAccepted}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data.paymentMethods
  }

  @Action({rawError: true})
  public async sendNurseDiaryReport(payload: NurseDiaryReportPayload): Promise<void> {
    const requestId = payload.dealId
    const data = payload.diary
    const resp = await axios.post(`${BackendUrls.requests}/${requestId}/nurse-diary`, data)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async getRequestNurseDiaryReportList(requestId: string): Promise<DiaryDataForm[]> {
    const resp = await axios.get(`${BackendUrls.requests}/${requestId}/nurse-diary`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getDiaryListDownload(payload: {start: string; end: string; lang: string; requestId: string}): Promise<void> {
    const resp = await axios.get(
      `${BackendUrls.requests}/${payload.requestId}/nurse-diary/report?start=${payload.start}&end=${payload.end}&lang=${payload.lang}`,
      {responseType: 'blob'}
    )
    await fileDownload(resp.data, 'nurse_diary_report.pdf')
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
  }
}

export const requestServiceStore = getModule(ServiceRequestModule, rootStore)
