export interface RouteItem {
  path: string;
  name: string;
}

export const Home: RouteItem = {
  path: "/",
  name: "Home"
}

export const Assistants: RouteItem = {
  path: "/assistants",
  name: "Assistants"
}

export const Customers: RouteItem = {
  path: "/customers",
  name: "Customers"
}

export const Error: RouteItem = {
  path: "/error",
  name: "Error"
}

export const Success: RouteItem = {
  path: "/success",
  name: "Success"
}

export const SuccessLogged: RouteItem = {
  path: "/success2",
  name: "Success2"
}

export const DealAnnouncer: RouteItem = {
  path: "/announcer",
  name: "announcer"
}

export const PaymentSuccess: RouteItem = {
  path: "/payment_success",
  name: "payment_success"
}

export const PaymentFailure: RouteItem = {
  path: "/payment_failure",
  name: "payment_failure"
}

export const TermsAndConditions: RouteItem = {
  path: "/termsandconditions",
  name: "termsandconditions"
}

export const PrivacyPolicy: RouteItem = {
  path: "/privacypolicy",
  name: "privacypolicy"
}

export const ProcedureForResolvingClaims: RouteItem = {
  path: "/procedureforresolvingclaims",
  name: "procedureforresolvingclaims"
}

export const PaymentAgreement: RouteItem = {
  path: "/paymentagreement",
  name: "paymentagreement"
}

export const InvestorInfo: RouteItem = {
  path: "/investorinfo",
  name: "investorinfo"
}

export const ThankYou: RouteItem = {
  path: "/thankyou",
  name: "thankyou"
}

export const AboutUs: RouteItem = {
  path: "/aboutus",
  name: "aboutus"
}
