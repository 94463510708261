import {Module, Mutation, VuexModule} from "vuex-module-decorators"
import {AuthInfo} from "@/types"

export interface AuthState {
  authInfo: AuthInfo | null;
  beenAuthorized: boolean;
}

@Module({namespaced: true, name: "auth"})
export class AuthModule extends VuexModule {
  
  public authState: AuthState = {
    authInfo: null,
    beenAuthorized: false
  }
  
  public get authInfo(): AuthInfo | undefined {
    const authInfo = this.authState.authInfo
    return authInfo === null ? undefined : authInfo
  }

  public get authenticated(): boolean {
    return this.authState.authInfo !== null
  }

  public get isBeenAuthorized(): boolean {
    return this.authState.beenAuthorized
  }

  @Mutation
  public setBeenAuthorized(): void {
    this.authState.beenAuthorized = true
  }

  @Mutation
  public resetBeenAuthorized(): void {
    this.authState.beenAuthorized = false
  }

  @Mutation
  public setAuthInfo(authInfo: AuthInfo): void {
    this.authState.authInfo = authInfo
  }

  @Mutation
  public clearAuthInfo(): void {
    this.authState.authInfo = null
  }
}
