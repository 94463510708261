import {RouteItem, Home} from "@/router/routes"
import * as DemandRoutes from "@/_modules/demand/router/routes"
import * as ActivityRoutes from "@/_modules/activity/router/routes"
import {DealType} from "@/types";

export {Home}

export const ProposalDraft: RouteItem = {
  path: `${DemandRoutes.DemandDetails.path}/${DealType.Proposal}`,
  name: DealType.Proposal + '-draft'
}

export const ProposalRequester: RouteItem = {
  path: `${ActivityRoutes.Demands.path}/${DealType.Proposal}`,
  name: DealType.Proposal + '-requester'
}

export const ProposalProvider: RouteItem = {
  path: `${ActivityRoutes.Proposals.path}/${DealType.Proposal}`,
  name: DealType.Proposal + '-provider'
}

export const FromOverviewProposalRequester: RouteItem = {
  path: `${ActivityRoutes.Overview.path}/${DealType.Proposal}`,
  name: `from_overview_${DealType.Proposal}-requester`
}

export const FromOverviewProposalProvider: RouteItem = {
  path: `${ActivityRoutes.Overview.path}/${DealType.Proposal}`,
  name: `from_overview_${DealType.Proposal}-provider`
}
