import {Component} from "vue-property-decorator"
import BackendClientMixin from "./BackendClientMixin"
import {Validation} from 'vuelidate'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component
export default abstract class extends BackendClientMixin {

  public checkValidation(v: Validation | undefined): boolean {
    if (v !== undefined) {
      v.$touch()
      const result = !v.$pending && !v.$error
      if (!result) {
        this.$nextTick(() => {
          this.scrollToFirstError()
        })
      }
      return result
    } else {
      throw Error('Validation object is undefined')
    }
  }

  public resetValidation(v: Validation | undefined): void {
    if (v !== undefined) {
      v.$reset()
    } else {
      throw Error('Validation object is undefined')
    }
  }

  public mounted(): void {
    // After load resets default loading=true to false
    this.resetClientState()
  }

  public scrollToFirstError(): void {
    if(document.getElementsByClassName('invalid-feedback d-block')[0] !== undefined){
      const firstErrorElement = document.getElementsByClassName('invalid-feedback d-block')[0].previousElementSibling
      if (firstErrorElement !== null) {
        firstErrorElement.scrollIntoView({behavior: "smooth", block: "center"})
      }
    }
  }
}