import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import BackendClientMixin from "@/mixins/BackendClientMixin";
import {BasicEvents} from "@/constants/ComponentEvents";

interface Props {
  txt?: string;
  to?: string;
  cssClass?: string;
  cssStyle?: string;
  linkClasses?: string;
  onClick?(e: Event): void;
}

@Component({name: 'Link'})
export default class extends BackendClientMixin {
  public $props!: Readonly<{class?: string}> & Readonly<Props>
  @Prop(String) public readonly txt?: string
  @Prop(String) public readonly to?: string
  @Prop(String) public readonly href?: string
  @Prop(String) public readonly cssClass?: string
  @Prop(String) public readonly linkClasses?: string
  @Prop(String) public readonly cssStyle?: string
  
  public onClick(e: Event): void {
    this.$emit(BasicEvents.Click, e)
  }

  public render(): VNode {
    return (
      <b-nav-item
        href={this.href} // standard link
        to={this.to} // router link
        disabled={this.busy} // property from backend client mixin
        class={this.cssClass}
        linkClasses={this.linkClasses}
        style={this.cssStyle}
        onClick={this.onClick}
      >
        {this.$slots.label === undefined ? this.txt : this.$slots.label}
      </b-nav-item>
    )
  }
}