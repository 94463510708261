import {Action, getModule, Module, VuexModule} from "vuex-module-decorators"
import Router from "vue-router"
import {Location} from "vue-router/types/router"
import axios from "@/api"

import rootStore, {appStore, authStore} from "@/store"
import {mapStore} from "@/store/map"

import {AuthChallengeStep, AuthInfo, AuthResponse, ConfirmationStep, UserId} from "@/types"
import {
  ChangePassword,
  Credentials,
  EmailCredentials,
  ForgotPassword,
  PersonalSettings,
  Profile,
  RestorePassword, RestorePasswordToken, UserConfirmation
} from '../types'

import HttpStatus from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"
import {UnexpectedInternalError, UnexpectedServerError, UnexpectedServerResponseError} from "@/utils/errors"

import queryString from "query-string"
import {convertToI18nLang} from "@/store/app"
import {webSocketHandler} from "@/ws/ws-handler";
import random from "@/utils/random";
import ApplicationConfiguration from "@/constants/ApplicationConfiguration";
import assert from "assert"
import {AxiosResponse, Canceler} from "axios";

interface ExtendedRouter extends Router {
  history: {
    _startLocation: string;
  };
}

function buildLangParams(nonceV?: string): string {
  const nonce = (nonceV ? {nonce: nonceV} : {});
  const params = {...nonce, lang: convertToI18nLang(appStore.locale).toLocaleUpperCase()}
  return queryString.stringify(params)
}

export class LoginPayload {
  public readonly credentials: Credentials;
  public readonly nonce: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  public readonly onChallenge: ((ch: AuthChallengeStep, nonce: string) => Promise<void>) | undefined

  public constructor(credentials: Credentials, onChallenge?: (ch: AuthChallengeStep, nonce: string) => Promise<void>) {
    this.credentials = credentials
    this.nonce = random.generateString(ApplicationConfiguration.nonceLength)
    switch (credentials.kind) {
      case 'email':
        assert(onChallenge === undefined)
        this.onChallenge = undefined
        return
      case 'smart-id':
      case 'mobile-id':
        assert(onChallenge !== undefined)
        this.onChallenge = onChallenge
        return
      default:
        throw new UnexpectedInternalError('unexpected credentials : ' + credentials)
    }
  }
}

export class CallbackPayload {
  public readonly callback: string;
  public readonly nonce: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  public readonly onConfirmation: ((c: ConfirmationStep, nonce: string) => Promise<void>) | undefined

  public constructor(step: AuthChallengeStep, nonce: string, onConfirmation?: (c: ConfirmationStep, nonce: string) => Promise<void>) {
    this.callback = step.callback
    this.nonce = nonce
    this.onConfirmation = onConfirmation
  }
}

export class ConfirmationPayload {
  public readonly callback: string;
  public readonly nonce: string;
  public readonly confirmation: UserConfirmation

  public constructor(step: ConfirmationStep, nonce: string, confirmation: UserConfirmation) {
    this.callback = step.callback
    this.nonce = nonce
    this.confirmation = confirmation
  }
}

export class LogoutPayload {
  public readonly router: Router;
  public readonly redirectLocation: Location | string;
  public readonly kickedByServer: boolean;

  public constructor(router: Router, redirectLocation: Location | string, kickedByServer: boolean = false) {
    this.router = router
    this.redirectLocation = redirectLocation
    this.kickedByServer = kickedByServer
  }
}

const CancelToken = axios.CancelToken
let cancel: Canceler = () => null

@Module({dynamic: true, namespaced: true, name: "profile", store: rootStore})
export class ProfileModule extends VuexModule {

  @Action({rawError: true})
  public async signUp(payload: EmailCredentials): Promise<UserId> {
    const resp = await axios.post<UserId>(`${BackendUrls.signup}?${buildLangParams()}`, payload)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
    return resp.data
  }

  @Action({rawError: true})
  public async forgotPassword(payload: ForgotPassword): Promise<void> {
    const resp = await axios.post(`${BackendUrls.forgotPassword}?${buildLangParams()}`, payload)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  public static provider(credentials: Credentials): string {
    switch(credentials.kind) {
      case 'email':
        return 'naabrid';
      case 'smart-id':
        return 'smart-id';
      case 'mobile-id':
        return 'mobiil-id';
      default:
        return credentials;
    }
  }

  private static isAuthInfo(r: AuthResponse): r is AuthInfo {
    return (r as AuthInfo).userId !== undefined
  }

  private static isAuthChallengeStep(r: AuthResponse): r is AuthChallengeStep {
    return (r as AuthChallengeStep).challenge !== undefined && (r as AuthChallengeStep).callback !== undefined
  }

  private static isConfirmationStep(r: AuthResponse): r is ConfirmationStep {
    return (r as AuthChallengeStep).challenge === undefined && (r as ConfirmationStep).callback !== undefined
  }

  private static async handleLoginResponse(response: AxiosResponse<AuthResponse>): Promise<void> {
    if (ProfileModule.isAuthInfo(response.data)) {
      authStore.setAuthInfo(response.data)

      await webSocketHandler.wsStreamUp(response.data.userId)

      mapStore.cleanupPosition() // TODO: Maybe it is a good idea to set profile location here
      appStore.resetCoords()
    } else {
      throw new UnexpectedServerError('200 is allowed only for auth info')
    }
  }

  @Action({rawError: true})
  public async login(payload: LoginPayload): Promise<void> {
    const response = await axios.post<AuthResponse>(BackendUrls.login(ProfileModule.provider(payload.credentials)) + '?' + buildLangParams(payload.nonce), payload.credentials)
    switch (response.status) {
      case HttpStatus.ACCEPTED :
        if (payload.onChallenge !== undefined) {
          if (ProfileModule.isAuthChallengeStep(response.data)) {
            await payload.onChallenge(response.data, payload.nonce)
            break
          } else {
            throw new UnexpectedServerError('202 is allowed only for challenge step')
          }
        } else {
          throw new UnexpectedServerResponseError([HttpStatus.OK], response.status)
        }

      case HttpStatus.OK :
        await ProfileModule.handleLoginResponse(response)
        break
      default:
        throw new UnexpectedServerResponseError([HttpStatus.OK, HttpStatus.ACCEPTED], response.status)
    }
  }

  @Action({rawError: true})
  public cancelRequest(): void {
    cancel()
  }

  @Action({rawError: true})
  public async callback(payload: CallbackPayload): Promise<void> {
    const response = await axios.post<AuthInfo>(
      payload.callback + '?' + buildLangParams(payload.nonce),
      undefined,
      {cancelToken: new CancelToken((c) => {
        cancel = c
      })}
    )
    switch (response.status) {
      case HttpStatus.ACCEPTED :
        if (payload.onConfirmation !== undefined) {
          if (ProfileModule.isConfirmationStep(response.data)) {
            await payload.onConfirmation(response.data, payload.nonce)
            return
          } else {
            throw new UnexpectedServerError('202 is allowed only for challenge step')
          }
        } else {
          throw new UnexpectedServerResponseError([HttpStatus.OK], response.status)
        }

      case HttpStatus.OK :
        await ProfileModule.handleLoginResponse(response)
        break
      default:
        throw new UnexpectedServerResponseError([HttpStatus.OK, HttpStatus.ACCEPTED], response.status)
    }
  }

  @Action({rawError: true})
  public async confirmation(payload: ConfirmationPayload): Promise<void> {
    const response = await axios.post<AuthInfo>(payload.callback + '?' + buildLangParams(payload.nonce), payload.confirmation)
    UnexpectedServerResponseError.throwOnStatusMismatch(response, HttpStatus.OK)
    await ProfileModule.handleLoginResponse(response)
  }

  @Action({rawError: true})
  public async logout(payload: LogoutPayload): Promise<void> {
    const wasAuthenticated = authStore.authenticated
    await authStore.clearAuthInfo()

    if (!payload.kickedByServer) {
      try {
        const resp = await axios.post(BackendUrls.logout)
        UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
        authStore.resetBeenAuthorized()
      } catch (e) {
        console.warn(e)
      }
    }

    const destination = typeof payload.redirectLocation === 'string'
      ? payload.redirectLocation
      : {
        name: payload.redirectLocation.name,
        params: (!wasAuthenticated) ? {
          ...payload.redirectLocation.params,
          initialEntryPoint: (payload.router as ExtendedRouter).history._startLocation
        } : payload.redirectLocation.params
      }
    await payload.router.push(destination)

    await appStore.resetCoords()
    await authStore.clearAuthInfo()
    await mapStore.cleanupPosition()
    await webSocketHandler.wsStreamDown()
  }

  @Action({rawError: true})
  public async killSessionIfExist(): Promise<void> {
    const resp = await axios.post(BackendUrls.logout)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async touchSession(): Promise<void> {
    const resp = await axios.post(BackendUrls.touchSession)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async changePassword(payload: ChangePassword): Promise<void> {
    const resp = await axios.put(BackendUrls.changePassword, payload)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async startSetupPassword(): Promise<string> {
    const resp = await axios.post<RestorePasswordToken>(BackendUrls.setupPassword)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data.token
  }

  @Action({rawError: true})
  public async resetPassword(payload: RestorePassword): Promise<void> {
    const resp = await axios.post(BackendUrls.resetPassword, payload)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async completeRegistration(token: string): Promise<void> {
    const resp = await axios.post(`${BackendUrls.completeRegistration}`, {token})
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async changeEmail(email: string): Promise<void> {
    const resp = await axios.put(`${BackendUrls.changeEmail}`, {email})
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async emailVerification(code: string): Promise<void> {
    const resp = await axios.post(`${BackendUrls.emailVerification}`, {code})
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async resendEmailLink(): Promise<void> {
    const resp = await axios.post(`${BackendUrls.resendEmailVerification}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async tokenValidation(token: string): Promise<void> {
    const resp = await axios.get(`${BackendUrls.validateToken}?token=${token}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async getSettings(): Promise<PersonalSettings> {
    const resp = await axios.get<PersonalSettings>(BackendUrls.settings)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async submitSettings(payload: PersonalSettings): Promise<void> {
    const resp = await axios.put(BackendUrls.settings, payload)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async getProfile(userId: UserId): Promise<Profile | undefined> {
    const resp = await axios.get<Profile>(`${BackendUrls.profiles}/${userId}`)
    if (resp.status === HttpStatus.OK) {
      return resp.data
    } else if (resp.status === HttpStatus.NO_CONTENT) {
      return undefined
    } else {
      throw new UnexpectedServerResponseError([HttpStatus.OK, HttpStatus.NO_CONTENT], resp.status)
    }
  }
}

export const profileStore = getModule(ProfileModule, rootStore)
