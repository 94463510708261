import {VNode} from "vue"
import {Component, Prop} from "vue-property-decorator"

import {TsxComponent} from "@/components/TsxComponent"

interface Props {
  columnBreakpoint?: string;
  subtitle?: string;
  title?: string;
  reversed?: boolean;
  titleContent?: () => VNode;
  content: () => VNode;
}

@Component
export default class TitledSection extends TsxComponent<Props> implements Props{
  @Prop({type: String, required: false, default: 'md'}) public readonly columnBreakpoint!: string
  @Prop({type: String, required: false, default: ''}) public readonly subtitle!: string
  @Prop({type: String, required: false, default: ''}) public readonly title!: string
  @Prop(Boolean) public readonly reversed!: boolean
  @Prop(Function) public readonly titleContent?: () => VNode
  @Prop(Function) public readonly content!: () => VNode

  private renderContent(): VNode {
    return (
      <b-col
        cols="12"
        class={`content col-${this.columnBreakpoint}-6 ${this.reversed ? 'pr' : 'pl'}-lg-14 order-${this.columnBreakpoint}-${!this.reversed ? '2' : '0'}`}
      >
        {this.content()}
      </b-col>
    )
  }

  public render(): VNode {
    return (
      <b-row align-v="center" class="feature py-8 py-md-12">
        {this.reversed && this.renderContent()}
        <b-col cols="12" order="1" class={`feature-content col-${this.columnBreakpoint}-6`}>
          {this.title?.length > 0 &&
            <h2>{this.title}</h2>
          }
          {this.subtitle?.length > 0 &&
            <p>{this.subtitle}</p>
          }
          {this.titleContent && this.titleContent()}
        </b-col>
        {!this.reversed && this.renderContent()}
      </b-row>
    )
  }

}
