import { Component} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"
import TitledSection from "@/components/layout/TitledSection"
import Button from "@/components/form/Button"
import Feedback from "@/components/layout/Feedback"
import {BIconChevronRight} from "bootstrap-vue"

import {authStore} from "@/store"
import {profileStore} from "@/_modules/profile/store/profile"
import HomePageCard from "@/components/layout/HomePageCard"
import * as ProfileRoutes from "@/_modules/profile/router/routes"
import {ServiceFind} from "@/_modules/service/router/routes"
import * as MainRoutes from "@/router/routes"
import Possibilities, {UserType} from "@/components/Possibilities"
import NaabridRoadMap from "@/components/NaabridRoadMap"
import * as ServicesRoutes from "@/_modules/service/router/routes"
import Cta from "@/components/layout/Cta"
import FullscreenYoutubeVideo from "@/components/layout/FullscreenYoutubeVideo"
import {introVideoId} from "@/utils/video-links";

@Component({name: "Customers", components: {BIconChevronRight}})
export default class Customers extends BackendClientMixin {

  public email = ''
  public buildFeedBack = false

  public async mounted(): Promise<void> {
    const userId = authStore.authInfo
    if (userId !== undefined) {
      await this.withRequest(profileStore.getSettings()).then(resp => {
        this.email = resp.profile.email
      })
    }
    this.$nextTick().then(() => {
      this.buildFeedBack = true
    })
  }

  public get authenticated(): boolean {
    return authStore.authenticated
  }

  public render(): VNode {
    return (
      <main class="bg-white">
        <header>
          <b-container fluid="xl">
            <TitledSection
              columnBreakpoint="lg"
              titleContent={() => {
                return (
                  <div>
                    <h1 class="display-1">{this.translation("lp_assistants_main_title")}</h1>
                    <i18n tag="p" path="lp_assistants_main_description">
                      {this.translation('lp_assistants_main_description')}
                    </i18n>
                    <ul class="list-styled">
                      <li>
                        {this.translation('lp_assistants_main_description_li1')}
                      </li>
                      <li>
                        {this.translation('lp_assistants_main_description_li2')}
                      </li>
                      <li>
                        {this.translation('lp_assistants_main_description_li3')}
                      </li>
                      <li>
                        {this.translation('lp_assistants_main_description_li4')}
                      </li>
                      <li>
                        {this.translation('lp_assistants_main_description_li5')}
                      </li>
                      <li>
                        {this.translation('lp_assistants_main_description_li6')}
                      </li>
                      <li>
                        {this.translation('lp_assistants_main_description_li7')}
                      </li>
                    </ul>
                    {!this.authenticated && 
                      <Button
                        variant="primary"
                        label={this.translation("fld_auth_register")}
                        to={ProfileRoutes.Signup.path}
                      />
                    }
                  </div>
                )
              }}
              content={() => {
                return (
                  <div class="embed-responsive embed-responsive-21by9 rounded mb-6 mb-lg-0">
                    <b-img class="embed-responsive-item object-cover" src={require('@/assets/images/Home/Tartu_naabrid_LP1.jpg')} alt="" />
                  </div>
                )
              }}
            />
          </b-container>
        </header>
        <section>
          <b-container fluid="xl">
            <TitledSection
              title={this.translation("title_assistants_body_first_block")}
              subtitle={this.translation("text_assistants_body_first_block_description")}
              reversed
              titleContent={() => {
                return (
                  <Button
                    class="mt-4"
                    variant="primary"
                    to={ServicesRoutes.ServiceFind.path}
                    label={this.translation("button_find")}/>
                )
              }}
              content={() => {
                return (
                  <div class="position-relative pb-3 mb-3 mb-md-0 pb-md-6">
                    <div class="embed-responsive embed-responsive-4by3 rounded">
                      <b-img class="embed-responsive-item object-cover" src={require('../assets/images/Home/feature1.jpg')} alt=""/>
                    </div>
                    <FullscreenYoutubeVideo videoId={introVideoId()} />
                  </div>
                )
              }}
            />
          </b-container>
        </section>
        <section class="py-8 py-md-12">
          <Possibilities userType={UserType.Customer} />
        </section>
        <section class="py-8 py-md-12">
          <b-container fluid="xl">
            <b-row class="justify-content-center">
              <b-col cols="12" md="8">
                <h2>
                  {this.translation('title_assistants_body_third_block')}
                </h2>
                <ul class="list-styled">
                  <li>
                    {this.translation('lp_assistants_body_third_block_li1')}
                  </li>
                  <li>
                    {this.translation('lp_assistants_body_third_block_li2')}
                  </li>
                  <li>
                    {this.translation('lp_assistants_body_third_block_li3')}
                  </li>
                  <li>
                    {this.translation('lp_assistants_body_third_block_li4')}
                  </li>
                  <li>
                    {this.translation('lp_assistants_body_third_block_li5')}
                  </li>
                  <li>
                    {this.translation('lp_assistants_body_third_block_li6')}
                  </li>
                  <li>
                    {this.translation('lp_assistants_body_third_block_li7')}
                  </li>
                </ul>
                <Button variant="primary" to={ServiceFind.path} label={this.translation("button_find_assistants")}/>
              </b-col>
            </b-row>
          </b-container>
        </section>
        <section class="py-8 py-md-12">
          <b-container fluid="xl">
            <b-row class="justify-content-center">
              <b-col cols="12" md="8">
                <h2>
                  {this.translation('title_assistants_body_fourth_block')}
                </h2>
                <div class="accordion">
                  <div role="tab">
                    <b-link class="d-flex p-4" href="#" v-b-toggle={'accordion-1'}>
                      <b-icon-chevron-right variant="primary" class="app-icon-lg mt-0 mr-2" />
                      <h4 class="m-0">
                        {this.translation('assistants_body_fourth_block_accordion_title1')}
                      </h4>
                    </b-link>
                  </div>
                  <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
                    <div class="pt-4 pl-12 pr-4 pb-6">
                      <p>{this.translation('assistants_body_fourth_block_accordion_description1')}</p>
                    </div>
                  </b-collapse>
                  <div role="tab">
                    <b-link class="d-flex p-4" href="#" v-b-toggle={'accordion-2'}>
                      <b-icon-chevron-right variant="primary" class="app-icon-lg mt-0 mr-2" />
                      <h4 class="m-0">
                        {this.translation('assistants_body_fourth_block_accordion_title2')}
                      </h4>
                    </b-link>
                  </div>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <div class="pt-4 pl-12 pr-4 pb-6">
                      <p>{this.translation('assistants_body_fourth_block_accordion_description2')}</p>
                    </div>
                  </b-collapse>
                  <div role="tab">
                    <b-link class="d-flex p-4" href="#" v-b-toggle={'accordion-3'}>
                      <b-icon-chevron-right variant="primary" class="app-icon-lg mt-0 mr-2" />
                      <h4 class="m-0">
                        {this.translation('assistants_body_fourth_block_accordion_title3')}
                      </h4>
                    </b-link>
                  </div>
                  <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                    <div class="pt-4 pl-12 pr-4 pb-6">
                      <p>{this.translation('assistants_body_fourth_block_accordion_description3')}</p>
                    </div>
                  </b-collapse>
                  <div role="tab">
                    <b-link class="d-flex p-4" href="#" v-b-toggle={'accordion-4'}>
                      <b-icon-chevron-right variant="primary" class="app-icon-lg mt-0 mr-2" />
                      <h4 class="m-0">
                        {this.translation('assistants_body_fourth_block_accordion_title4')}
                      </h4>
                    </b-link>
                  </div>
                  <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
                    <div class="pt-4 pl-12 pr-4 pb-6">
                      <p>{this.translation('assistants_body_fourth_block_accordion_description4')}</p>
                    </div>
                  </b-collapse>
                  <div role="tab">
                    <b-link class="d-flex p-4" href="#" v-b-toggle={'accordion-5'}>
                      <b-icon-chevron-right variant="primary" class="app-icon-lg mt-0 mr-2" />
                      <h4 class="m-0">
                        {this.translation('assistants_body_fourth_block_accordion_title5')}
                      </h4>
                    </b-link>
                  </div>
                  <b-collapse id="accordion-5" accordion="my-accordion-5" role="tabpanel">
                    <div class="pt-4 pl-12 pr-4 pb-6">
                      <p>{this.translation('assistants_body_fourth_block_accordion_description5')}</p>
                    </div>
                  </b-collapse>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </section>
        {this.buildFeedBack &&
          <section class="py-8 py-md-12">
            <Feedback value={this.email}/>
          </section>
        }
        <section class="py-8 py-md-12">
          <b-container fluid="xl">
            <b-row class="justify-content-center">
              <b-col cols="12" md="8" xl="6" class="text-center">
                <h2 class="display-2">
                  {this.translation('title_assistants_body_fifth_block')}
                </h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pr-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('../assets/images/icons/customer_advantage_01.svg')}/>
                  }}
                  title={'assistants_body_fifth_block_title_card1'}
                  text={'assistants_body_fifth_block_description_card1'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pl-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('../assets/images/icons/customer_advantage_02.svg')}/>
                  }}
                  title={'assistants_body_fifth_block_title_card2'}
                  text={'assistants_body_fifth_block_description_card2'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pr-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('../assets/images/icons/customer_advantage_03.svg')}/>
                  }}
                  title={'assistants_body_fifth_block_title_card3'}
                  text={'assistants_body_fifth_block_description_card3'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pl-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('../assets/images/icons/customer_advantage_04.svg')}/>
                  }}
                  title={'assistants_body_fifth_block_title_card4'}
                  text={'assistants_body_fifth_block_description_card4'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pr-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('../assets/images/icons/customer_advantage_05.svg')}/>
                  }}
                  title={'assistants_body_fifth_block_title_card5'}
                  text={'assistants_body_fifth_block_description_card5'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pl-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('../assets/images/icons/customer_advantage_06.svg')}/>
                  }}
                  title={'assistants_body_fifth_block_title_card6'}
                  text={'assistants_body_fifth_block_description_card6'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pr-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('../assets/images/icons/customer_advantage_07.svg')}/>
                  }}
                  title={'assistants_body_fifth_block_title_card7'}
                  text={'assistants_body_fifth_block_description_card7'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pl-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('../assets/images/icons/customer_advantage_08.svg')}/>
                  }}
                  title={'assistants_body_fifth_block_title_card8'}
                  text={'assistants_body_fifth_block_description_card8'}
                />
              </b-col>
              <b-col cols="12" class="mt-10 mb-3">
                <p>{this.translation('assistants_body_fifth_block_description')}</p>
              </b-col>
            </b-row>
          </b-container>
        </section>
        <section class="py-8 py-md-12">
          <Cta
            backgroundImage={require('@/assets/images/Home/feature5.jpg')}
          >
            <h2>{this.translation('title_assistants_body_sixth_block')}</h2>
            <p class="lead mb-6">{this.translation('text_assistants_body_sixth_block_description')}</p>
            <Button variant="primary" to={MainRoutes.Assistants.path} label={this.translation("btn_know_more")}/>
          </Cta>
        </section>
        <section class="py-8 py-md-12">
          <b-container fluid="xl">
            <b-row>
              <b-col cols="12">
                <h2>{this.translation('title_assistants_body_seventh_block')}</h2>
                <p>{this.translation('text_assistants_body_seventh_block_description')}</p>
              </b-col>
            </b-row>
            <NaabridRoadMap />
          </b-container>
        </section>
        <section class="pt-8 pt-md-12">
          <b-container fluid="xl">
            <b-row>
              <b-col cols="12">
                <h2>{this.translation('title_assistants_body_eighth_block')}</h2>
                <p>{this.translation('text_assistants_body_eighth_block_description')}</p>
              </b-col>
            </b-row>
          </b-container>
        </section>
      </main>
    )
  }
}
