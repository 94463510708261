import {Action, getModule, Module, VuexModule} from "vuex-module-decorators"

import axios from "@/api"
import HttpStatuses from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"
import {UnexpectedServerResponseError} from "@/utils/errors"
import rootStore from "@/store/index";
import {BusyTimePayload, GetRatingPayload, Qualification, Rating, SendProposalRatingPayload, SendRequestRatingPayload, ServiceProviderQualificationPayload, Turnover, UsersTasksPayload, UserTurnoverPayload, InitialTaskData} from '@/types'

@Module({dynamic: true, namespaced: true, name: 'user-store', store: rootStore})
export class UserModule extends VuexModule {

  @Action({rawError: true})
  public async getUserRating(ratingPayload: GetRatingPayload): Promise<Rating> {
    const userId = ratingPayload.userId
    const role = ratingPayload.role
    const category = ratingPayload.category
    const resp = await axios.get(`${BackendUrls.users}/${userId}/rating?ratingType=${role}${category ? `&category=${category}` : ''}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.OK)
    return resp.data
  }
  
  @Action({rawError: true})
  public async sendRequestRating(sendRatingPayload: SendRequestRatingPayload): Promise<void> {
    const requestId = sendRatingPayload.requestId
    const rating = {rating: sendRatingPayload.rating}
    const resp = await axios.post(`${BackendUrls.requests}/${requestId}/rating`, rating)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.NO_CONTENT)
  }

  @Action({rawError: true})
  public async sendProposalRating(sendRatingPayload: SendProposalRatingPayload): Promise<void> {
    const proposalId = sendRatingPayload.proposalId
    const rating = {rating: sendRatingPayload.rating}
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/rating`, rating)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.NO_CONTENT)
  }
  
  @Action({rawError: true})
  public async getUsersTurnover(payload: UserTurnoverPayload): Promise<Turnover> {
    const userId = payload.userId
    const year = payload.year
    const month = payload.month
    const resp = await axios.get<Turnover>(`${BackendUrls.users}/${userId}/income?year=${year}&month=${month}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.OK)
    return resp.data
  }
  
  @Action({rawError: true})
  public async getUserTasks(payload: UsersTasksPayload): Promise<InitialTaskData[]> {
    const userId = payload.userId
    const weekStartDate = payload.weekStartDate
    const resp = await axios.get(`${BackendUrls.users}/${userId}/calendar?weekStartDate=${weekStartDate}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.OK)
    return resp.data
  }
  
  @Action({rawError: true})
  public async getProviderBusyTime(busyTimePayload: BusyTimePayload): Promise<InitialTaskData[]> {
    const providerId = busyTimePayload.providerId
    const startDate = busyTimePayload.startDate
    const resp = await axios.get(`${BackendUrls.users}/${providerId}/busyTimes?startDate=${startDate}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.OK)
    return resp.data
  }
  
  @Action({rawError: true})
  public async getServiceProviderQualification(payload: ServiceProviderQualificationPayload): Promise<Qualification> {
    const userId = payload.userId
    const category = payload.category
    const resp = await axios.get(`${BackendUrls.users}/${userId}/qualification?category=${category}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.OK)
    return resp.data
  }
}

export const userStore = getModule(UserModule, rootStore)