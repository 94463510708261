import * as ProposalRoutes from "../../proposal/router/proposal-demand-routes"
import {MessagingCenter} from "@/_modules/message-center/router/routes";
import { ImportedComponent } from "vue/types/options";

export default [
  {
    path: `${ProposalRoutes.ProposalDraft.path}/:demandId`,
    name: ProposalRoutes.ProposalDraft.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-demand-store" */ "../views/Proposal")
  },
  {
    path: `${ProposalRoutes.ProposalRequester.path}/:proposalId`,
    name: ProposalRoutes.ProposalRequester.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-demand-store" */ "../views/Proposal")
  },
  {
    path: `${ProposalRoutes.ProposalProvider.path}/:proposalId`,
    name: ProposalRoutes.ProposalProvider.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-demand-store" */ "../views/Proposal")
  },
  {
    path: `${ProposalRoutes.FromOverviewProposalRequester.path}/:proposalId`,
    name: ProposalRoutes.FromOverviewProposalRequester.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-demand-store" */ "../views/Proposal")
  },
  {
    path: `${ProposalRoutes.FromOverviewProposalProvider.path}/:proposalId`,
    name: ProposalRoutes.FromOverviewProposalProvider.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-demand-store" */ "../views/Proposal")
  },
  {
    path: `${MessagingCenter.path}/proposal/:proposalId`,
    name: `${MessagingCenter.name}-proposal`,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "request-service-store" */ "../views/Proposal")
  }
]