import { Component, Prop } from "vue-property-decorator"
import { VNode } from "vue"
import { TsxComponent } from "@/components/TsxComponent"

interface Props {
  headerClass?: string;
  leadClass?: string;
  title: string;
  wideHeader?: boolean;
}

@Component({name: 'PageHeader'})
export default class extends TsxComponent<Props> implements Props {
  @Prop({ type: String, default: 'lead' }) public readonly leadClass!: string
  @Prop({ type: String }) public readonly headerClass?: string
  @Prop({ type: String, required: true }) public readonly title!: string
  @Prop({ type: Boolean, default: false }) public readonly wideHeader?: boolean

  public render(): VNode {
    let headerClass = `mb-10${!this.wideHeader ? ' mt-10' : ' mt-8'}`;

    if (this.headerClass) {
      headerClass = this.headerClass;
    }
    return (
      <b-col cols="12" class={!this.wideHeader ? 'col-lg-10 col-xl-9' : ''}>
        <div class={headerClass}>
          <h1 class={`display-1${!this.wideHeader ? ' mb-6' : ' mb-2'}`}>
            {this.title}
          </h1>

          {this.$slots.default !== undefined && 
            <div class={this.leadClass}>
              {this.$slots.default}
            </div>
          }
        </div>
      </b-col>
    )
  }
}
