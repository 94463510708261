import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent"
import { isEmpty } from "@/constants/DataBoundaries";
import {appStore} from "@/store";

interface Props {
  dismissible?: boolean;
  noticeClass?: string;
  noticeKey?: string;
  variant?: string;
}

@Component({name: 'Notice'})
export default class extends TsxComponent<Props> implements Props {
  @Prop({type: Boolean, required: false, default: false}) public readonly dismissible!: boolean;
  @Prop({type: String, required: false, default: ''}) public readonly noticeClass!: string;
  @Prop({type: String, required: false}) public readonly noticeKey!: string;
  @Prop({type: String, required: false, default: 'primary'}) public readonly variant!: string;

  public mounted(): void {
    if (this.dismissible && isEmpty(this.noticeKey)) {
      // Explicitly call `throw` when required conditions are not met
      throw new Error('noticeKey prop is required for dismissible notice.');
    }
  }

  private dismissNotice(): void {
    appStore.setNoticeDismissed(this.noticeKey);
  }

  public render(): VNode {
    return (
      <div>
        {(!this.dismissible || (this.dismissible && !appStore.isNoticeDismissed(this.noticeKey))) &&
          <div class={['app-notice', `app-notice-${this.variant}`, this.noticeClass]}>
            {this.$slots.default}
            {this.dismissible &&
              <div class="d-flex justify-content-end mt-n2 mb-4">
                <b-link class="small text-primary py-1" onClick={this.dismissNotice}>
                  {this.translation('dismiss_notice')}
                </b-link>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}
