import * as AdminRoutes from "@/_modules/admin/router/admin-routes"
import { ImportedComponent } from "vue/types/options"
 
export default [
  {
    path: AdminRoutes.AdminPanel.path,
    name: AdminRoutes.AdminPanel.name,
    meta: {
      secured: true
    },
    redirect: AdminRoutes.UserProfileManagement.path,
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "admin-store" */ "../views/AdminPanel"),
    children: [
      {
        path: AdminRoutes.UserProfileManagement.path,
        name: AdminRoutes.UserProfileManagement.name,
        meta: {
          secured: false
        },
        component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "AdminPanel" */ "../components/UserProfileManagement")
      },
      {
        path: AdminRoutes.Agreements.path,
        name: AdminRoutes.Agreements.name,
        meta: {
          secured: false
        },
        component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "AdminPanel" */ "../components/Agreements")
      },
      {
        path: AdminRoutes.AccountingAndReports.path,
        name: AdminRoutes.AccountingAndReports.name,
        meta: {
          secured: false
        },
        component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "AdminPanel" */ "../components/AccountingAndReports")
      },
      {
        path: AdminRoutes.AdminMsgCenter.path,
        name: AdminRoutes.AdminMsgCenter.name,
        meta: {
          secured: false
        },
        component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "AdminPanel" */ "../components/MsgCenter")
      }
    ]
  }
]