import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {DealType} from "@/types"

import BackendClientMixin from "@/mixins/BackendClientMixin"

import Button from "@/components/form/Button"

import {requestServiceStore} from "@/_modules/request/store/request-service-store"
import {proposalDemandStore} from "@/_modules/proposal/store/proposal-demand-store"

import * as RequestRoutes from "@/_modules/request/router/request-service-routes"
import * as ProposalRoutes from "@/_modules/proposal/router/proposal-demand-routes"

import {RequestDetails} from "@/_modules/request/types"
import {ProposalDetails} from "@/_modules/proposal/types"
import {buildRequestData} from "@/_modules/request/data-builders"
import {buildProposal} from "@/_modules/proposal/data-builders"
import {isEmpty} from "@/constants/DataBoundaries"

import * as ActivityRoutes from "@/_modules/activity/router/routes";

@Component({name: 'PaymentSuccess'})
export default class PaymentSuccess extends BackendClientMixin {

  public request: RequestDetails = buildRequestData()
  public proposal: ProposalDetails = buildProposal()

  public dealId: string | undefined = undefined
  public dealType: string | null = null
  
  public async mounted(): Promise<void> {
    this.dealId = this.$route.params.dealId
    this.dealType = this.$route.query.type as string

    if (this.dealType === DealType.Request) {
      this.request = await this.withRequest(requestServiceStore.getRequestById(this.dealId))
    }

    if (this.dealType === DealType.Proposal) {
      this.proposal = await this.withRequest(proposalDemandStore.getProposalById(this.dealId))
    }
  }

  private redirectToExistingDeal(): void {
    if (this.dealType === DealType.Request) {
      this.$router.push({
        path: `${RequestRoutes.RequestRequester.path}/${this.dealId}`,
        params: {
          requestId: this.dealId!
        }
      })
    }
    if (this.dealType === DealType.Proposal) {
      this.$router.push({
        path: `${ProposalRoutes.ProposalRequester.path}/${this.dealId}`,
        params: {
          proposalId: this.dealId!
        }
      })
    }
  }
  
  public render(): VNode {
    return (
      <b-container fluid="xl" class="mt-6 mt-md-10">
        <b-row class="justify-content-center">
          <b-col cols="12" md="10">
            <b-card body-class="text-center pt-12 pb-8 pt-md-16 pb-md-12 px-lg-16">
              <h1>{this.translation('title_payment_success')}</h1>
              {!isEmpty(this.request.status) && !this.busy &&
                <div>
                  <p>
                    {this.translation('msg_thank_you')} <span>{this.request.requester.name.first}</span>.
                  </p>
                  <i18n path={'request.msg_on_paid'}>
                    <span>{this.request.service.provider.name.first}</span>
                  </i18n>
                </div>
              }
              {!isEmpty(this.proposal.status) && !this.busy &&
                <div>
                  <p>
                    {this.translation('msg_thank_you')} <span>{this.proposal.requester.name.first}</span>.
                  </p>
                  <i18n path={'proposal.msg_on_paid'}>
                    <span>{this.proposal.service.provider.name.first}</span>
                  </i18n>
                </div>
              }
              <div class="mt-8 mt-md-10 mb-4 d-flex flex-column flex-sm-row justify-content-center align-items-center">
                <Button 
                  class="mb-2 mb-sm-0 mr-sm-2"
                  variant="outline-primary" 
                  onClick={() => this.redirectToExistingDeal()} 
                  label={this.dealType === DealType.Request ? this.translation('btn_back_to_request') : this.translation('btn_back_to_proposal')}
                />
                <Button
                  variant="primary"
                  onClick={() => this.$router.push(ActivityRoutes.Activity.path)}
                  label={this.translation('btn_go_to_overview')}
                />
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    )
  }
}