import {RouteItem, Home} from "@/router/routes"

export {Home}

export const ServiceDetails: RouteItem = {
  path: "/map-service/service_details",
  name: "service_details"
}

export const ServiceNew: RouteItem = {
  path: "/new-service",
  name: "new-service"
}

export const ServiceNewFromActivity: RouteItem = {
  path: "/services/new-service",
  name: "service_new_from_activity"
}

export const ServiceEdit: RouteItem = {
  path: "/service_edit",
  name: "service_edit"
}

export const ServiceEditFromActivity: RouteItem = {
  path: "/services/service_edit",
  name: "service_edit_from_activity"
}

export const ServiceFind: RouteItem = {
  path: "/map-service",
  name: "map-service"
}
