import {VNode} from 'vue'
import {Component} from "vue-property-decorator"
import {I18nComponent} from "./I18nComponent"

@Component({name: "LoadingIndicator"})
export default class extends I18nComponent {
  public render(): VNode {
    return (
      <b-container fluid="xl">
        <b-row class="mt-12 mt-md-20">
          <b-col cols="12">
            <div class="loading-indicator text-center my-2">
              <p>
                <b-img class="rotating" src={require('../assets/images/loader-icon.svg')}/>
              </p>
              <p class="font-weight-bold mb-0">{this.translation('msg_loading')}</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    )
  }
}