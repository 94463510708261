import {RouteItem, Home} from "@/router/routes"

export {Home}

export const Activity: RouteItem = {
  path: "/activity",
  name: "activity"
}

export const Overview: RouteItem = {
  path: "/overview",
  name: "overview"
}

export const Services: RouteItem = {
  path: "/services",
  name: "services"
}

export const Requests: RouteItem = {
  path: "/requests",
  name: "requests"
}

export const Proposals: RouteItem = {
  path: "/proposals",
  name: "proposals"
}

export const Demands: RouteItem = {
  path: "/demands",
  name: "demands"
}

export const Accounts: RouteItem = {
  path: "/accounts",
  name: "accounts"
}

export const History: RouteItem = {
  path: "/history",
  name: "history"
}