import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent"

import {TermsAndConditions} from "@/router/routes"
import {appStore} from "@/store";

@Component({name: "CookiesAttention"})
export default class extends I18nComponent {
  public showCookiesAttention: boolean = true

  public acceptCookies(): void {
    appStore.setCookieConfirm(true)
    this.showCookiesAttention = false
  }

  public render(): VNode {
    return (
      <b-toast
        body-class="p-4"
        no-auto-hide
        no-close-button
        solid
        variant="light"
        visible={this.showCookiesAttention}
        toast-class="rounded-sm"
        toaster="b-toaster-bottom-left"
      >
        <i18n tag="p" path="msg_cookies_attention" class="mb-3">
          <b-link class="text-primary" href={TermsAndConditions.path} target="_blank">{this.translation('msg_cookies_attention_label')}</b-link>
        </i18n>
        <b-button
          size="sm"
          variant="primary"
          onClick={() => this.acceptCookies()}
        >
          {this.translation('btn_agree')}
        </b-button>
      </b-toast>
    )
  }
}
