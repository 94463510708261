import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent"

interface Card {
  key: string;
}

enum ContentType {
  PhysicalHealth = 'physical_health',
  LivingPlaceImprove = 'living_place_improve',
  LivingImprove = 'living_improve',
  SpiritualSupport = 'spiritual_support',
  EmotionalHealth = 'emotional_health',
  CommunityActivity = 'community_activity'
}

export enum UserType {
  Customer = 'customers',
  Assistant = 'assistants'
}

interface Props {
  userType: UserType;
}

@Component({name: 'Possibilities'})
export default class extends TsxComponent<Props> implements Props{

  @Prop(String) public readonly userType!: UserType

  private get title(): string {
    return this.translation(`title_${this.userType}_body_second_block`)
  }

  private get description(): string {
    return this.translation(`text_${this.userType}_body_second_block_description`)
  }

  public cards: Card[] = [
    {
      key: ContentType.PhysicalHealth
    },
    {
      key: ContentType.LivingPlaceImprove
    },
    {
      key: ContentType.LivingImprove
    },
    {
      key: ContentType.SpiritualSupport
    },
    {
      key: ContentType.EmotionalHealth
    },
    {
      key: ContentType.CommunityActivity
    }
  ]

  private renderTabContent(contentKey: ContentType): VNode {
    if (contentKey === ContentType.PhysicalHealth) {
      return (
        <div class="pt-3">
          <h3>{this.translation(`txt_lp_block_title_${contentKey}`)}</h3>
          <p>
            {this.translation(`txt_lp_block_header_${this.userType}_${contentKey}`)}
          </p>
          <ul class="list-styled">
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_1_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_1_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_2_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_2_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_3_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_3_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_4_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_4_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_5_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_5_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_6_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_6_description_${this.userType}_${contentKey}`)}
            </li>
          </ul>
        </div>
      )
    }
    else if (contentKey === ContentType.LivingPlaceImprove) {
      return (
        <div class="pt-3">
          <h3>{this.translation(`txt_lp_block_title_${contentKey}`)}</h3>
          <p>
            {this.translation(`txt_lp_block_header_${this.userType}_${contentKey}`)}
          </p>
          <ul class="list-styled">
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_1_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_1_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_2_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_2_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_3_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_3_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_4_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_4_description_${this.userType}_${contentKey}`)}
            </li>
          </ul>
        </div>
      )
    } else if (contentKey === ContentType.LivingImprove) {
      return (
        <div class="pt-3">
          <h3>{this.translation(`txt_lp_block_title_${contentKey}`)}</h3>
          <p>
            {this.translation(`txt_lp_block_header_${this.userType}_${contentKey}`)}
          </p>
          <ul class="list-styled">
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_1_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_1_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_2_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_2_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_3_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_3_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_4_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_4_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_5_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_5_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_6_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_6_description_${this.userType}_${contentKey}`)}
            </li>
          </ul>
        </div>
      )
    } else if (contentKey === ContentType.SpiritualSupport) {
      return (
        <div class="pt-3">
          <h3>{this.translation(`txt_lp_block_title_${contentKey}`)}</h3>
          <p>
            {this.translation(`txt_lp_block_header_${this.userType}_${contentKey}`)}
          </p>
          <ul class="list-styled">
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_1_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_1_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_2_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_2_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_3_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_3_description_${this.userType}_${contentKey}`)}
            </li>
          </ul>
        </div>
      )
    } else if (contentKey === ContentType.EmotionalHealth) {
      return (
        <div class="pt-3">
          <h3>{this.translation(`txt_lp_block_title_${contentKey}`)}</h3>
          <p>
            {this.translation(`txt_lp_block_header_${this.userType}_${contentKey}`)}
          </p>
          <ul class="list-styled">
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_1_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_1_description_${this.userType}_${contentKey}`)}
            </li>
          </ul>
        </div>
      )
    } else if (contentKey === ContentType.CommunityActivity) {
      return (
        <div class="pt-3">
          <h3>{this.translation(`txt_lp_block_title_${contentKey}`)}</h3>
          <p>
            {this.translation(`txt_lp_block_header_${this.userType}_${contentKey}`)}
          </p>
          <ul class="list-styled">
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_1_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_1_description_${this.userType}_${contentKey}`)}
            </li>
            <li>
              <span class="font-weight-bold">
                {this.translation(`txt_lp_block_body_text_2_title_${contentKey}`)}
              </span>
              {this.translation(`txt_lp_block_body_text_2_description_${this.userType}_${contentKey}`)}
            </li>
          </ul>
        </div>
      )
    }

    return (
      <div></div>
    )
  }

  public render(): VNode {
    return (
      <b-container fluid="xl">
        <b-row>
          <b-col cols="12" md="10" lg="8" class="mb-6">
            <h2>{this.title}</h2>
            <p>{this.description}</p>
          </b-col>
          <b-col cols="12">
            <b-tabs nav-wrapper-class="col-12 col-md-auto" nav-class="bg-gray-100 p-4 rounded" pills vertical>
              {this.cards.map((item, index) => {
                const contentKey: ContentType = item.key as ContentType

                return (
                  <b-tab active={index === 0}>
                    <template slot="title">
                      {this.translation(`txt_lp_block_title_${item.key}`)}
                    </template>
                    {this.renderTabContent(contentKey)}
                  </b-tab>
                )
              })}
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    )
  }
}
