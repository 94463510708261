import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";
import ContentPage from "@/components/ContentPage";

/* eslint-disable */
@Component({name: 'ProcedureForResolvingClaims'})
export default class extends I18nComponent {
  public render(): VNode {
    return (
      <ContentPage
        title={'Pretensioonide lahendamise kord'}
      >
        <h2>
          1. Pretensiooni esitamine
        </h2>
        <ol class="list-styled">
          <li>
            Pretensiooni saavad esitada Teenuse osutaja ning Teenuse saaja Teenuse osutamise täitmise kinnitamisel või 30 päeva jooksul alates Teenuse osutamise täitmise kinnitamisest. Pretensioon esitatakse Naabrid.ee-le e-posti teel või Platvormil oleva vormi kaudu.
          </li>
          <li>
            Kui pretensioon esitatakse pärast Teenuse osutamise täitmise kinnitamist, peab Kasutaja põhjendama, miks ei olnud pretensiooni võimalik esitada enne Teenuse osutamise täitmise kinnitamist.
          </li>
          <li>
            Pretensioonist peab nähtuma pretensiooni esitaja nimi, kontaktandmed, millise Lepingu suhtes pretensioon esitatakse, pretensiooni sisu (märkida, millega on Kasutaja rahulolematu), millised on Kasutaja argumendid pretensiooni esitamiseks ning millist tulemust pretensiooni esitaja taotleb. Pretensiooni esitaja lisab pretensioonile asjassepuutuvad dokumendid, fotod ning muud tõendid.
          </li>
          <li>
            Pretensiooni kättesaamisest teavitab Naabrid.ee pretensiooni esitajat viivitamata.
          </li>
        </ol>
        <h2>
          2. Pretensioonile vastamine
        </h2>
        <ol class="list-styled">
          <li>
            Pretensiooni kättesaamisel edastab Naabrid.ee pretensiooni Lepingu teisele poolele ning palub Lepingu teisel poolel pretensioonile vastata hiljemalt 7 päeva jooksul. Juhul, kui Lepingu teine pool tähtaegselt ei vasta, on Naabrid.ee õigustatud lahendama pretensiooni tema kahjuks.
          </li>
          <li>
            Kui pretensioonis esitatud asjaolud on selged ning pretensioonile vastuse nõudmine ei ole asjaolusid arvestades mõistlik, võib Naabrid.ee lahendada pretensiooni ka Lepingu teiselt poolelt pretensioonile vastust nõudmata.
          </li>
          <li>
            Pretensiooni vastuses tuleb Lepingu teisel poolel selgitada, kas võtab pretensioonis esitatu omaks või vaidleb sellele vastu. Pretensioonile vastamise korral tuleb vastusele lisada asjassepuutuvad dokumendid, fotod ning muud tõendid.
          </li>
        </ol>
        <h2>
          3. Pretensiooni lahendamine Naabrid.ee poolt
        </h2>
        <ol class="list-styled">
          <li>
            Naabrid.ee lahendab pretensiooni 7 päeva jooksul alates pretensioonile vastuse saamisest. Kui Naabrid.ee lahendab pretensiooni ilma Lepingu teiselt poolelt vastust nõudmata, lahendab Naabrid.ee pretensiooni 7 päeva jooksul alates pretensiooni saamisest.
          </li>
          <li>
            Pretensiooni lahendamisel tutvub Naabrid.ee pretensiooniga ning selle vastusega ning teeb otsuse pretensiooni kohta.
          </li>
          <li>
            Pretensiooni lahendamise otsusena võib Naabrid.ee jätta tasu Vahendusteenuse eest nõudmata, Teenuse osutajale tasu Teenuse osutamise eest välja maksmata ning tagastada Teenuse saajale, määrata Teenuse osutamise eest välja makstud summa tagastamine või määrata Kasutajale trahv hinnakirjas sätestatud alusel või määras, mis vastab Lepingu sõlmimise tasule Vahendusteenuse osutamise eest. Samuti võib Naabrid.ee pretensiooni lahendamise otsusega rakendada Naabrid.ee üldtingimuste punktis 4.1.1. sätestatud meetmeid.
          </li>
          <li>
            Isik, kelle kahjuks Naabrid.ee pretensiooni lahendas, kaotab õiguse jätta Teenuse osutamise kohta tagasisidet.
          </li>
          <li>
            Kui Kasutajad jõuavad pretensiooni lahendamise käigus kokkuleppele, tuleb Kasutajatel teavitada Naabrid.ee-d kokkuleppe sõlmimisest.
          </li>
          <li>
            Pretensiooni lahendamine Naabrid.ee poolt ei takista Kasutajat pöörduma oma õiguste kaitseks kohtusse või muu kohase organi poole. Kasutajad teadvustavad, et Naabrid.ee poolt pretensioonide lahendamine ei ole vahekohus või muu sarnane organ, kelle otsus oleks
          </li>
          <li>
            Pretensiooni lahendamise järgselt ei vastuta Naabrid.ee ühegi kahju eest, mis võis tekkida Kasutajale pretensiooni lahendamise tõttu. Eelkõige, kuid mitte ainult, ei vastuta Naabrid.ee kahju eest, mis võis tekkida isikule, kelle kahjuks pretensioon Naabrid.ee poolt lahendati, kuid kelle kasuks (või pretensiooni lahendamise otsusest teisiti) otsustas kohus või muu kohane organ, seoses pretensiooni lahendamisega.
          </li>
        </ol>
      </ContentPage>
    );
  }
}
/* eslint-enable */
