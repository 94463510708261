import * as Routes from "./routes"
import * as ActivityRoutes from "@/_modules/activity/router/routes"
import { ImportedComponent } from "vue/types/options"

export default [
  {
    path: `${Routes.ServiceDetails.path}/:serviceId`,
    name: Routes.ServiceDetails.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "services" */ "../views/ServiceDetails")
  },
  {
    path: `${ActivityRoutes.Services.path}${Routes.ServiceEdit.path}/:serviceId`,
    name: Routes.ServiceEditFromActivity.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "services" */ "../views/Service")
  },
  {
    path: Routes.ServiceNew.path,
    name: Routes.ServiceNew.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "services" */ "../views/Service")
  },
  {
    path: Routes.ServiceNewFromActivity.path,
    name: Routes.ServiceNewFromActivity.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "services" */ "../views/Service")
  },
  {
    path: Routes.ServiceFind.path,
    name: Routes.ServiceFind.name,
    meta: {
      secured: false
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "services" */ "../views/FindService")
  }
]
