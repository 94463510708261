import {Component} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin";

import Button from "@/components/form/Button"

//import * as MainRoutes from "@/router/routes"
import * as ActivityRoutes from "@/_modules/activity/router/routes"

import {isEmpty} from "@/constants/DataBoundaries"
import {DealType, SendProposalRatingPayload, SendRequestRatingPayload} from "@/types"
import {userStore} from "@/store/user-store"
import {BasicErrorHandler} from "@/utils/errorHandler"
import * as Error from "@/utils/errors"
import {BackendUrls, ErrorCodes} from "@/constants/APIconstants"
import LoadingIndicator from "@/components/LoadingIndicator"

import * as ProposalRoutes from "@/_modules/proposal/router/proposal-demand-routes"
import * as RequestRoutes from "@/_modules/request/router/request-service-routes"
import PageHeader from "@/components/layout/PageHeader";
import moment from "moment";
import { BIconCheck2 } from "bootstrap-vue";

export enum RateActionType {
  Confirm = "confirm",
  Rating = "rating"
}

export class DealAnnouncerErrorHandler extends BasicErrorHandler {
  
  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.RatingAlreadySet:
        this.errorMessageKey = 'err_rating_already_set'
        break
      default: await super.handleBackendError(e)
        break
    }
  }
}
const dealAnnouncerErrorHandler = new DealAnnouncerErrorHandler()

@Component({
  name: 'DealAnnouncer',
  components: {
    BIconCheck2
  }
})
export default class extends BackendClientMixin {

  private msg = ''
  private msgParams: string[] = []
  private rateActionType: RateActionType | string = ''
  private dealId: string = ''
  private dealType: string = ''
  private dealTitle: string = ''
  private dealDate: string = ''
  private dealCancelled: string | undefined = undefined
  private agreementNumber: string | undefined = undefined
  private agreementFileId: string | undefined = undefined
  private fromDraft: string | undefined = undefined
  private greetingTitle: string = ''
  private singleButtonBack = ''

  private rateGrade: number = 0
  
  public mounted(): void {
    if (this.$route.params.msgParams !== undefined) {
      this.msg = this.$route.params.msg
      this.msgParams = this.$route.params.msgParams.split('&&')
      this.rateActionType = this.$route.params.rateActionType !== undefined ? this.$route.params.rateActionType : ''
      this.dealId = this.$route.params.dealId
      this.dealType = this.$route.params.dealType
      this.dealTitle = this.$route.params.dealTitle
      this.dealDate = this.$route.params.dealDate
      this.dealCancelled = this.$route.params.dealCancelled
      this.agreementNumber = this.$route.params.agreementNumber
      this.agreementFileId = this.$route.params.agreementFileId
      this.fromDraft = this.$route.params.fromDraft
      this.greetingTitle = this.$route.params.greetingTitle
      this.singleButtonBack = this.$route.params.singleButtonBack
    } 
    else {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      this.$router.go(-1)
    }
  }

  public async onSendRating(): Promise<void> {
    if (this.dealType === DealType.Request) {
      const requestRatingPayload: SendRequestRatingPayload = {
        requestId: this.dealId,
        rating: this.rateGrade.toString()
      }
      await this.withRequest(userStore.sendRequestRating(requestRatingPayload), dealAnnouncerErrorHandler)
      await this.$router.push({
        name: ActivityRoutes.Activity.name
      })
    }
    if (this.dealType === DealType.Proposal) {
      const proposalRatingPayload: SendProposalRatingPayload = {
        proposalId: this.dealId,
        rating: this.rateGrade.toString()
      }
      await this.withRequest(userStore.sendProposalRating(proposalRatingPayload), dealAnnouncerErrorHandler)
      await this.$router.push({
        name: ActivityRoutes.Activity.name
      })
    }
  }
  
  public onBackClick(): void {
    if (this.dealType === DealType.Proposal && this.fromDraft === 'true') {
      this.$router.push({
        path: `${ProposalRoutes.ProposalProvider.path}/${this.dealId}`
      })
    } else if (this.dealType === DealType.Request && this.fromDraft === 'true') {
      this.$router.push({
        path: `${RequestRoutes.RequestRequester.path}/${this.dealId}`
      })
    } else {
      this.$router.go(-1)
    }
  }
  
  public onForwardClick(): void {
    this.$router.push(ActivityRoutes.Overview.path)
  }

  public render(): VNode {
    return (
      <div>
        {this.busy && <LoadingIndicator />}
        {!this.busy && 
          <b-container fluid="xl">
            {this.dealTitle && 
              <b-row>
                <PageHeader
                  headerClass="my-10"
                  leadClass="text-sm"
                  title={this.dealTitle}
                  wideHeader={true}
                >
                  {this.dealDate && 
                    <span class="text-nowrap mr-4">{this.translation('lbl_service_created')}: {moment(this.dealDate).locale(this.$i18n.locale).format('L')}</span>
                  }
                  {!isEmpty(this.agreementNumber) &&
                    <span class="text-nowrap">
                      {this.translation('lbl_request_agreement_number')}
                      <b-link class="text-primary" href={`${BackendUrls.urlApi}${BackendUrls.file}/${encodeURIComponent(this.agreementFileId!)}`}>{this.agreementNumber}</b-link>
                    </span>
                  }
                </PageHeader>
              </b-row>
            }
            <b-row class="justify-content-center">
              <b-col cols="12" md="10" class={{'mt-10': !this.dealTitle}}>
                <b-card body-class="text-center pt-12 pb-8 pt-md-16 pb-md-12 px-lg-16">
                  <h2 class="text-center">{this.translation(this.greetingTitle)}</h2>
                  <i18n tag="p" path={this.msg}>
                    {(isEmpty(this.rateActionType) && this.dealCancelled !== 'true') && <b-link class="text-primary" onClick={() => this.onBackClick()}>{this.msgParams[0]}</b-link>}
                    {(!isEmpty(this.rateActionType) || this.dealCancelled === 'true') && <span>{this.msgParams[0]}</span>}
                    <span>{this.msgParams[1]}</span>
                  </i18n>
                  {!isEmpty(this.rateActionType) &&
                    <div>
                      <div class="d-flex align-items-center justify-content-center my-6 my-md-10">
                        <span class="mr-6 d-none d-md-flex text-nowrap">
                          {this.translation('rating_form_lbl_bad')}
                        </span>
                        <b-form-rating
                          class="p-1"
                          inline
                          no-border
                          icon-full="check2"
                          v-model={this.rateGrade}
                        />
                        <span class="ml-6 d-none d-md-flex text-nowrap">
                          {this.translation('rating_form_lbl_great')}
                        </span>
                      </div>
                      <div class="text-center">
                        <Button onClick={() => this.onSendRating()} variant="primary" label={this.translation('btn_rate')}/>
                      </div>
                    </div>
                  }
                  {(isEmpty(this.rateActionType) && isEmpty(this.singleButtonBack)) &&
                    <div class="mt-8 mt-md-10 mb-4">
                      <Button
                        variant="primary"
                        onClick={() => this.onForwardClick()}
                        label={this.translation('menu_item_activity_overview')}
                      />
                    </div>
                  }
                  {!isEmpty(this.singleButtonBack) &&
                    <div class="mt-8 mt-md-10 mb-4">
                      <Button 
                        variant="outline-primary"
                        onClick={() => this.onBackClick()}
                        label={this.translation(this.singleButtonBack)}
                      />
                    </div>
                  }
                </b-card>
              </b-col>
            </b-row>
          </b-container>
        }
      </div>
    )
  }
}
