import {Module, VuexModule, Action, getModule} from "vuex-module-decorators"
import rootStore from "@/store"

import axios from "@/api"
import HttpStatus from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"
import {DemandDetails} from "@/_modules/demand/types";
import {UnexpectedServerResponseError} from "@/utils/errors";
import {PersonalSettings} from "@/_modules/profile/types";
import {AcceptPropVersionPayload, NewProposal, ProposalPriceAndTasks, ProposalDetails, ProposalUpdatePayload, ProposalTaskAsCompletePayload, ProposalNewMessagePayload} from "@/_modules/proposal/types";
import {LockState, Message} from "@/_modules/request/types";
import {DiaryDataForm, FileData, NurseDiaryReportPayload, PaymentMethods, PricesTaxesTasks, ProposalPaymentPayload, SendProposalRatingPayload} from '@/types'
import {AxiosResponse} from "axios";
import fileDownload from "js-file-download";

@Module({dynamic: true, namespaced: true, name: 'request-demand-store', store: rootStore})
export class ProposalDemandModule extends VuexModule {

  @Action({rawError: true})
  public async getDemandDetailsById(demandId: string): Promise<DemandDetails> {
    const resp = await axios.get<DemandDetails>(`${BackendUrls.demands}/${demandId}/details`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getProposalById(proposalId: string): Promise<ProposalDetails> {
    const resp = await axios.get<ProposalDetails>(`${BackendUrls.proposals}/${proposalId}?autolock=false`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getSettings(): Promise<PersonalSettings> {
    const resp = await axios.get<PersonalSettings>(BackendUrls.settings)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getAllMessages(proposalId: string): Promise<Message[]> {
    const resp = await axios.get<Message[]>(`${BackendUrls.proposals}/${proposalId}/messages`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async sendMessage(payload: ProposalNewMessagePayload): Promise<Message> {
    const message = {
      message: payload.message.message,
      uploadToken: payload.message.uploadToken
    }
    const proposalId = payload.proposalId
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/messages`, message)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.CREATED)
    return resp.data
  }

  @Action({rawError: true})
  public async getUploadedFiles(uploadToken: string): Promise<FileData[]> {
    const resp = await axios.get(`${BackendUrls.files}/${uploadToken}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async unlockProposal(proposalId: string): Promise<LockState> {
    const resp = await axios.put<LockState>(`${BackendUrls.proposals}/${proposalId}/unlock`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
    return resp.data
  }

  @Action({rawError: true})
  public async cancelProposal(proposalId: string): Promise<void> {
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/cancel`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async postNewProposal(proposal: NewProposal): Promise<string> {
    const resp = await axios.post<string>(`${BackendUrls.proposals}`, proposal)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.CREATED)
    return resp.data
  }

  @Action({rawError: true})
  public async acceptCurrentVersion(payload: AcceptPropVersionPayload): Promise<AxiosResponse< {dealConcluded: boolean} >> {
    const proposalId = payload.proposalId
    const propVersion = payload.propVersion
    const termsAccepted = payload.termsAccepted
    const resp = await axios.put(`${BackendUrls.proposals}/${proposalId}/${BackendUrls.acceptedVersion}${propVersion}${BackendUrls.termsAccepted}${termsAccepted}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp
  }

  @Action({rawError: true})
  public async acceptProposalToDiscussion(proposalId: string): Promise<void> {
    const resp = await axios.put(`${BackendUrls.proposals}/${proposalId}/accept`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async lockProposal(proposalId: string): Promise<LockState> {
    const resp = await axios.put<LockState>(`${BackendUrls.proposals}/${proposalId}/lock`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async updateProposalByProvider(payload: ProposalUpdatePayload): Promise<void> {
    const proposalId = payload.proposalId
    const form = {
      tasks: payload.tasks,
      price: payload.price,
      calculatePrice: payload.calculatePrice
    }
    const resp = await axios.put(`${BackendUrls.proposals}/${proposalId}`, form)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async updateProposalByRequester(payload: ProposalUpdatePayload): Promise<void> {
    const proposalId = payload.proposalId
    const form = {
      tasks: payload.tasks
      //donate: payload.donate
    }
    const resp = await axios.put(`${BackendUrls.proposals}/${proposalId}`, form)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async updatePriceWithProposalIdByProvider(priceDataPayload: ProposalPriceAndTasks): Promise<PricesTaxesTasks> {
    const priceData = {
      servicePrice: priceDataPayload.servicePrice,
      tasks: priceDataPayload.tasks,
      calculatePrice: priceDataPayload.calculatePrice
    }
    const proposalId = priceDataPayload.proposalId
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/price`, priceData)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async updatePriceWithProposalIdByRequester(priceDataPayload: ProposalPriceAndTasks): Promise<PricesTaxesTasks> {
    const priceData = {
      //donate: priceDataPayload.donate,
      tasks: priceDataPayload.tasks
    }
    const proposalId = priceDataPayload.proposalId
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/price`, priceData)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async updatePriceWithOutProposalId(priceData: ProposalPriceAndTasks): Promise<PricesTaxesTasks> {
    const resp = await axios.post(`${BackendUrls.proposals}/price`, priceData)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async setTaskComplete(taskAsCompletePayload: ProposalTaskAsCompletePayload): Promise<void> {
    const taskId = taskAsCompletePayload.taskId
    const proposalId = taskAsCompletePayload.proposalId
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/tasks/${taskId}/complete`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async setTaskCompleteConfirm(taskAsCompleteConfirmPayload: ProposalTaskAsCompletePayload): Promise<void> {
    const taskId = taskAsCompleteConfirmPayload.taskId
    const proposalId = taskAsCompleteConfirmPayload.proposalId
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/tasks/${taskId}/confirm-complete`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async sendRating(sendRatingPayload: SendProposalRatingPayload): Promise<void> {
    const proposalId = sendRatingPayload.proposalId
    const rating = {rating: sendRatingPayload.rating}
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/rating`, rating)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async payForServiceInProposal(payload: ProposalPaymentPayload): Promise<PaymentMethods> {
    const proposalId = payload.proposalId
    const paymentTermsAccepted = payload.paymentTermsAccepted
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/pay?paymentTermsAccepted=${paymentTermsAccepted}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data.paymentMethods
  }

  @Action({rawError: true})
  public async sendNurseDiaryReport(payload: NurseDiaryReportPayload): Promise<void> {
    const proposalId = payload.dealId
    const data = payload.diary
    const resp = await axios.post(`${BackendUrls.proposals}/${proposalId}/nurse-diary`, data)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  @Action({rawError: true})
  public async getRequestNurseDiaryReportList(proposalId: string): Promise<DiaryDataForm[]> {
    const resp = await axios.get(`${BackendUrls.proposals}/${proposalId}/nurse-diary`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getDiaryListDownload(payload: {start: string; end: string; lang: string; proposalId: string}): Promise<void> {
    const resp = await axios.get(
      `${BackendUrls.proposals}/${payload.proposalId}/nurse-diary/report?start=${payload.start}&end=${payload.end}&lang=${payload.lang}`,
      {responseType: 'blob'}
    )
    await fileDownload(resp.data, 'nurse_diary_report.pdf')
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
  }
}

export const proposalDemandStore = getModule(ProposalDemandModule, rootStore)
