import {Module, Mutation, VuexModule} from "vuex-module-decorators"
import {ClientAge, ClientDisease, ClientMentalAbility, ClientPhysicalActivity, ClientWeight, Currency, GeoLocationForm, Language, RateForm, Sex} from "@/types"
import {defaultCountry, defaultMapCenter} from "@/constants/ApplicationConfiguration";

export interface DemandStepsData {
  step1: DemandStepData1;
  step2: DemandStepData2;
  step3: DemandStepData3;
  step4: DemandStepData4;
}

export interface DemandStepData1 {
  userId: string | undefined;
  demandId: string | undefined;
  clientRequirements: string[];
  category: string;
  title: string | undefined;
  demandDescription_ru?: string | undefined;
  demandDescription_et?: string | undefined;
  demandDescription_en?: string | undefined;
  /*diseaseDescription_ru?: string | undefined;
  diseaseDescription_et?: string | undefined;
  diseaseDescription_en?: string | undefined;*/
  uploadToken: string | undefined;
}

export interface DemandStepData2 {
  clientSex: Sex | undefined;
  clientAge: ClientAge | undefined;
  clientWeight: ClientWeight | undefined;
  clientMentalAbility: ClientMentalAbility | undefined;
  clientPhysicalActivity: ClientPhysicalActivity[];
  clientDisease: ClientDisease[];
  languages: Language[];
}

export interface DemandStepData3 extends Partial<ServiceStepData3> {
  location: GeoLocationForm;
  requestedAtClientPlace: boolean | undefined;
}

export interface DemandStepData4 {
  budget: RateForm;
  visible: boolean | undefined;
}

export interface ServiceStepData1 { //TODO: userID missed
  serviceId: string | undefined;
  userId: string | undefined;
  title: string | undefined;
  competences: string[];
  category: string;
  description_ru?: string | undefined;
  description_et?: string | undefined;
  description_en?: string | undefined;
  languages: Language[];
  uploadToken: string | undefined;
  joinToGroup: boolean | undefined;
}

export interface ServiceStepData2 {
  clientSex: Sex[];
  clientAge: ClientAge[];
  clientWeight: ClientWeight[];
  clientMentalAbility: ClientMentalAbility[];
  clientPhysicalActivity: ClientPhysicalActivity[];
  clientDisease: ClientDisease[];
}

export interface ServiceStepData3 {
  location: GeoLocationForm;
  agreeToPublishAddress: boolean | undefined;
  providedAtClientPlace: boolean | undefined;
}

export interface ServiceStepData4 {
  scheduleInfo_ru: string | undefined;
  scheduleInfo_et: string | undefined;
  scheduleInfo_en: string | undefined;
  rate: RateForm;
  visible: boolean | undefined;
}

interface ServiceStepsData {
  step1: ServiceStepData1;
  step2: ServiceStepData2;
  step3: ServiceStepData3;
  step4: ServiceStepData4;
}

@Module({namespaced: true, name: "userData"})
export class ServiceAndDemandStore extends VuexModule {

  public serviceStep = 0

  public demandStep = 0

  public demandStepsData: DemandStepsData = {
    step1: {
      demandId: undefined,
      userId: undefined,
      title: undefined,
      clientRequirements: [],
      category: '',
      demandDescription_ru: undefined,
      demandDescription_et: undefined,
      demandDescription_en: undefined,
      /*diseaseDescription_ru: undefined,
      diseaseDescription_et: undefined,
      diseaseDescription_en: undefined,*/
      uploadToken: undefined
    },
    step2: {
      clientSex: undefined,
      clientAge: undefined,
      clientWeight: undefined,
      clientMentalAbility: undefined,
      clientPhysicalActivity: [],
      clientDisease: [],
      languages: []
    },
    step3: {
      location: {
        address: {
          country: defaultCountry,
          cityOrCounty: undefined,
          zipCode: undefined,
          address: undefined
        },
        coordinates: defaultMapCenter
      },
      agreeToPublishAddress: undefined,
      requestedAtClientPlace: undefined
    },
    step4: {
      budget: {
        type: undefined,
        price: {
          amount: undefined,
          currency: Currency.EUR
        }
      },
      visible: true
    }
  }

  public serviceStepsData: ServiceStepsData = {
    step1: {
      serviceId: undefined,
      userId: undefined,
      title: undefined,
      competences: [],
      category: '',
      description_ru: undefined,
      description_et: undefined,
      description_en: undefined,
      languages: [],
      uploadToken: undefined,
      joinToGroup: undefined
    },
    step2: {
      clientSex: [],
      clientAge: [],
      clientWeight: [],
      clientMentalAbility: [],
      clientPhysicalActivity: [],
      clientDisease: []
    },
    step3: {
      location: {
        address: {
          country: defaultCountry,
          cityOrCounty: undefined,
          zipCode: undefined,
          address: undefined
        },
        coordinates: defaultMapCenter
      },
      agreeToPublishAddress: undefined,
      providedAtClientPlace: undefined
    },
    step4: {
      scheduleInfo_ru: undefined,
      scheduleInfo_et: undefined,
      scheduleInfo_en: undefined,
      rate: {
        type: undefined,
        price: {
          amount: undefined,
          currency: Currency.EUR
        }
      },
      visible: true
    }
  }

  @Mutation
  public setServiceStep1(step1: ServiceStepData1): void {
    this.serviceStepsData.step1 = {...step1}
  }
  @Mutation
  public setServiceStep2(step2: ServiceStepData2): void {
    this.serviceStepsData.step2 = {...step2}
  }
  @Mutation
  public setServiceStep3(step3: ServiceStepData3): void {
    this.serviceStepsData.step3 = {...step3}
  }
  @Mutation
  public setServiceStep4(step4: ServiceStepData4): void {
    this.serviceStepsData.step4 = {...step4}
  }

  public get serviceStepData1(): ServiceStepData1 {
    return this.serviceStepsData.step1
  }
  public get serviceStepData2(): ServiceStepData2 {
    return this.serviceStepsData.step2
  }
  public get serviceStepData3(): ServiceStepData3 {
    return this.serviceStepsData.step3
  }
  public get serviceStepData4(): ServiceStepData4 {
    return this.serviceStepsData.step4
  }

  public get currentServiceStep(): number {
    return this.serviceStep
  }
  @Mutation
  public setCurrentServiceStep(step: number): void {
    this.serviceStep = step
  }

  @Mutation
  public setDemandStep1(step1: DemandStepData1): void {
    this.demandStepsData.step1 = {...step1}
  }
  @Mutation
  public setDemandStep2(step2: DemandStepData2): void {
    this.demandStepsData.step2 = {...step2}
  }
  @Mutation
  public setDemandStep3(step3: DemandStepData3): void {
    this.demandStepsData.step3 = {...step3}
  }
  @Mutation
  public setDemandStep4(step4: DemandStepData4): void {
    this.demandStepsData.step4 = {...step4}
  }

  public get demandStepData1(): DemandStepData1 {
    return this.demandStepsData.step1
  }
  public get demandStepData2(): DemandStepData2 {
    return this.demandStepsData.step2
  }
  public get demandStepData3(): DemandStepData3 {
    return this.demandStepsData.step3
  }
  public get demandStepData4(): DemandStepData4 {
    return this.demandStepsData.step4
  }

  public get currentDemandStep(): number {
    return this.demandStep
  }
  @Mutation
  public setCurrentDemandStep(step: number): void {
    this.demandStep = step
  }

  @Mutation
  public clearServiceData(): void {
    this.serviceStepsData = {
      step1: {
        serviceId: undefined,
        userId: undefined,
        title: undefined,
        competences: [],
        category: '',
        description_ru: undefined,
        description_et: undefined,
        description_en: undefined,
        languages: [],
        uploadToken: undefined,
        joinToGroup: undefined
      },
      step2: {
        clientSex: [],
        clientAge: [],
        clientWeight: [],
        clientMentalAbility: [],
        clientPhysicalActivity: [],
        clientDisease: []
      },
      step3: {
        location: {
          address: {
            country: defaultCountry,
            cityOrCounty: undefined,
            zipCode: undefined,
            address: undefined
          },
          coordinates: defaultMapCenter
        },
        agreeToPublishAddress: undefined,
        providedAtClientPlace: undefined
      },
      step4: {
        scheduleInfo_ru: undefined,
        scheduleInfo_et: undefined,
        scheduleInfo_en: undefined,
        rate: {
          type: undefined,
          price: {
            amount: undefined,
            currency: Currency.EUR
          }
        },
        visible: true
      }
    }
  }

  @Mutation
  public clearDemandData(): void {
    this.demandStepsData = {
      step1: {
        demandId: undefined,
        userId: undefined,
        title: undefined,
        clientRequirements: [],
        category: '',
        demandDescription_ru: undefined,
        demandDescription_et: undefined,
        demandDescription_en: undefined,
        /*diseaseDescription_ru: undefined,
        diseaseDescription_et: undefined,
        diseaseDescription_en: undefined,*/
        //files: [];
        uploadToken: undefined
      },
      step2: {
        clientSex: undefined,
        clientAge: undefined,
        clientWeight: undefined,
        clientMentalAbility: undefined,
        clientPhysicalActivity: [],
        clientDisease: [],
        languages: []
      },
      step3: {
        location: {
          address: {
            country: defaultCountry,
            cityOrCounty: undefined,
            zipCode: undefined,
            address: undefined
          },
          coordinates: defaultMapCenter
        },
        agreeToPublishAddress: undefined,
        requestedAtClientPlace: undefined
      },
      step4: {
        budget: {
          type: undefined,
          price: {
            amount: undefined,
            currency: Currency.EUR
          }
        },
        visible: true
      }
    }
  }
}
