import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconClose'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg class="app-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-width="1.5" fill="none" d="M20.997 21.003L11.995 12 2.996 3"/>
        <path stroke="currentColor" stroke-linecap="round" stroke-width="1.5" fill="none" d="M20.994 3l-9 9-8.993 9"/>
      </svg>
    )
  }
}
