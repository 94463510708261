import {Module, VuexModule, Action, Mutation, getModule} from "vuex-module-decorators"
import rootStore from "@/store"

import axios from "@/api"
import HttpStatus from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"

import {UnexpectedInternalError, UnexpectedServerResponseError} from "@/utils/errors"
import {Service, ServiceCards, ServiceCriteria, ServiceDetails, ServiceDetailsPayload, ServiceType} from "../types"
import {FileData, FilePublishStatePayload, Language} from "@/types"
import _, { cloneDeep } from 'lodash'

import queryString, {StringifiableRecord} from "query-string"


interface ServicesState {
  serviceTypes: ServiceType[] | undefined;
}

interface ServiceData {
  category?: string;
  sortBy?: string;
  onlineOnly?: boolean;
  sortMode?: string;
  page?: number;
  size?: number;
  lat?: number;
  lon?: number;
  dist?: number;
  dlat?: number;
  dlon?: number;
}

function toServiceQuery(criteria: ServiceCriteria): ServiceData {
  const result: ServiceData = {
    sortBy: criteria.order?.columnName,
    sortMode: criteria.order?.orderDirection,
    page: criteria.pagination?.current === undefined ? undefined : criteria.pagination.current - 1,
    size: criteria.pagination?.size,
    lat: criteria.coordinates?.latitude,
    lon: criteria.coordinates?.longitude,
    dlat: criteria.existLocationCords?.dLat,
    dlon: criteria.existLocationCords?.dLon
  }

  if (criteria.filters !== undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anyResult: any = result
    for (const [key, value] of criteria.filters.entries()) {
      anyResult[key] = value
    }
  }
  return result
}

@Module({dynamic: true, namespaced: true, name: "services", store: rootStore})
export class ServicesModule extends VuexModule {

  public servicesState: ServicesState = {
    serviceTypes: undefined
  }


  public get areServiceTypesLoaded(): boolean {
    return this.servicesState.serviceTypes !== undefined
  }

  public get serviceTypes(): ServiceType[] {
    return this.servicesState.serviceTypes === undefined ? [] : this.servicesState.serviceTypes
  }

  @Mutation
  public setServiceTypes(serviceTypes: ServiceType[]): void {
    this.servicesState.serviceTypes = serviceTypes
  }

  @Action({rawError: true})
  public async loadServiceTypes(): Promise<ServiceType[]> {
    if (!this.areServiceTypesLoaded) {
      const resp = await axios.get<ServiceType[]>(BackendUrls.serviceTypes)
      UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
      this.context.commit('setServiceTypes', resp.data)
    }
    return this.serviceTypes
  }

  @Action({rawError: true})
  public async addOffer(offer: Service): Promise<void> {
    const resp = await axios.post(`${BackendUrls.services}`, offer)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.CREATED)
  }

  @Action({rawError: true})
  public async updateOffer(service: Service): Promise<void> {
    if (service.serviceId === undefined) {
      throw new UnexpectedInternalError("serviceId is not set")
    }
    const resp = await axios.put(`${BackendUrls.services}/${service.serviceId}`, service)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
  }

  @Action({rawError: true})
  public async uploadFile(payload: FormData): Promise<FileData> {
    const resp = await axios.post(BackendUrls.files, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.CREATED)
    return resp.data
  }

  @Action({rawError: true})
  public async getOffers(request: ServiceCriteria): Promise<ServiceCards> {
    const url = BackendUrls.services + '?' + queryString.stringify(toServiceQuery(request) as StringifiableRecord)
    const resp = await axios.get<ServiceCards>(url)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getServiceById(serviceId: string): Promise<Service> {
    const resp = await axios.get<Service>(`${BackendUrls.services}/${serviceId}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async getOfferDetailsById(serviceDetailsPayload: ServiceDetailsPayload): Promise<ServiceDetails> {
    const serviceId = serviceDetailsPayload.serviceId
    const lat = serviceDetailsPayload.lat
    const lon = serviceDetailsPayload.lon
    const resp = await axios.get<ServiceDetails>(`${BackendUrls.services}/${serviceId}/details?lat=${lat}&lon=${lon}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async deleteFileById(fileId: string): Promise<boolean> {
    const resp = await axios.delete(`${BackendUrls.file}/${fileId}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
    return resp.status === HttpStatus.OK
  }

  @Action({rawError: true})
  public async getUploadedFiles(uploadToken: string): Promise<FileData[]> {
    const resp = await axios.get(`${BackendUrls.files}/${uploadToken}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
    return resp.data
  }

  @Action({rawError: true})
  public async setFileVisibility(payload: FilePublishStatePayload): Promise<void> {
    const fileId = payload.fileId
    const visibility = payload.visibility
    const resp = await axios.put(`${BackendUrls.file}/${fileId}/${visibility}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.OK)
  }
}

export const servicesStore = getModule(ServicesModule, rootStore)

export function getServiceTranslationPropertyName(locale: Language): keyof ServiceType {
  switch (locale) {
    case Language.ENG:
      return "category_en"
    case Language.RUS:
      return "category_ru"
    case Language.EST:
      return "category_et"
    default:
      return "category_en"
  }
}

export function getServiceTypeTranslation(locale: Language, st: ServiceType | undefined): string {
  if (st === undefined) {
    return ''
  }
  switch (locale) {
    case Language.ENG:
      return st.category_en
    case Language.RUS:
      return st.category_ru
    case Language.EST:
      return st.category_et
    default:
      return st.category_en
  }
}

export function getServiceTypesSorted(locale: Language, addEmpty = false): ServiceType[] {
  //const translationPropertyName = getServiceTranslationPropertyName(locale)
  const result = cloneDeep(servicesStore.serviceTypes)
  //result.sort(propertyStringComparator(translationPropertyName))
  if (!addEmpty) {
    return result
  }
  const emptyResult: ServiceType[] = [{
    category: '',
    category_et: 'Vali...',
    category_ru: 'Выбрать...',
    category_en: 'Choose...',
    subCategories: []
  }]
  return emptyResult.concat(result)
}

export function getServiceTypeTranslationByKey(locale: Language, key: string | undefined): string {
  if (key === undefined) {
    return ''
  }

  const subCat = _.flattenDeep(servicesStore.serviceTypes.map(st => {
    if (st.category === key) {
      return st
    } else {
      if (st.subCategories.some(st1 => st1.category === key)) {
        return st.subCategories.filter(subSt => {
          return subSt.category === key
        })
      } else {
        return st.subCategories.map(it => it.subCategories.filter(c3 => (c3.category === key)))
      }
    }
  }))


  return `${getServiceTypeTranslation(locale, subCat[0])}`
}
