import { Component, Prop } from "vue-property-decorator"
import { VNode } from "vue"
import { Location } from "vue-router";
import { TsxComponent } from "@/components/TsxComponent"
import { BasicEvents } from "@/constants/ComponentEvents";

interface Props {
  cardImage: string;
  leadText?: string;
  title: string;
  to?: Location;
  onClick?(e: Event): void;
}

@Component({name: 'LinkedCard'})
export default class extends TsxComponent<Props> implements Props {
  @Prop({ type: String, required: true }) public readonly cardImage!: string
  @Prop(String) public readonly leadText?: string
  @Prop({ type: String, required: true }) public readonly title!: string
  @Prop(Object) public readonly to?: Location

  public onClick(e: Event): void {
    this.$emit(BasicEvents.Click, e)
  }

  public render(): VNode {
    return (
      <b-link to={this.to} class="linked-card mb-2 mb-sm-4" onClick={this.onClick}>
        <b-card
          no-body
          class="shadow-none"
          text-variant="white"
        >
          <b-img src={this.cardImage} class="card-img object-cover" />
          <b-card-body overlay class="p-6 d-flex flex-column justify-content-end text-center">
            <h3 class="mb-2">{this.title}</h3>
            {this.leadText && 
              <p class="small mb-0">{this.leadText}</p>
            }
          </b-card-body>
        </b-card>
      </b-link>
    )
  }
}
