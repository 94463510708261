export default {
  nonceLength: 10,
  currencyDigitsAfterComma: 2,
  dividePrecision: 4,
  maxUploadableFileSize: 10485760,
  maxSendableViaEmailAttachmentsSize: 26214400,
  maxFileNameLength: 512,
  maxFileNameDisplayLength: 30,
  fileNameStringFromStart: 8,
  fileNameStringFromEnd: 19,
  maxAttachmentsAmount: 10,
  titleCropMidLength: 50,
  nameCropMidLength: 50,
  titleCropShortLength: 25,
  nameCropShortLength: 25,
  defaultInputMaxLength: 100
}

export const serverDateFormat = "YYYY-MM-DD"
export const momentDateFormat = "DD-MM-YYYY"
export const displayDateFormat = "DD.MM.yyyy"
export const datepickerDisplayDateFormat = "dd.MM.yyyy"

export const serverDateTimeFormat = "YYYY-MM-DDTHH:mm:ss"

export const disablePayedServices = process.env.VUE_APP_DISABLE_PAYED_SERVICE !== "false"
export const googleMapApiKey: string = process.env.VUE_APP_GOOGLE_API_KEY!
export const googleReCaptchaPublicKey = process.env.VUE_APP_CAPTCHA_PUBLIC_KEY!

export const storeVersion: string = process.env.VUE_APP_STORE_VERSION ? process.env.VUE_APP_STORE_VERSION : '0'
export const googleAnalyticsKey: string | undefined = process.env.VUE_APP_GOOGLE_ANALYTICS

import {MapCoordinates} from "@/types"

export const defaultCountry: string = process.env.VUE_APP_DEFAULT_COUNTRY_CODE ? process.env.VUE_APP_DEFAULT_COUNTRY_CODE : 'EE'

export const defaultMapCenter: MapCoordinates = {
  longitude: 25.5,
  latitude: 58.7
}

export const defaultZoom = 7
export const foundZoom = 12

export const defaultPaginationItemsPerPage = 10
