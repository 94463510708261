import { Component, Prop } from "vue-property-decorator"
import { VNode } from "vue"
import { TsxComponent } from "@/components/TsxComponent"

interface Props {
  backgroundImage?: string;
}

@Component({name: 'Cta'})
export default class extends TsxComponent<Props> implements Props {
  @Prop(String) public readonly backgroundImage!: string

  public render(): VNode {
    return (
      <div class="cta w-100">
        <b-container fluid="xl" class="py-16 py-lg-20">
          <b-row>
            <b-col cols="12" lg="6">
              {this.$slots.default !== undefined && this.$slots.default}
            </b-col>
          </b-row>
        </b-container>
        {this.backgroundImage && 
          <div class="cta-background">
            <b-img src={this.backgroundImage} alt="" />
          </div>
        }
      </div>
    )
  }
}
