import { ImportedComponent } from "vue/types/options"
import {MessagingCenter} from "./routes"

export default [
  {
    path: MessagingCenter.path,
    name: MessagingCenter.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "ActiveChats" */ "../views/MessageCenterView")
  }
]