export function initStickyNav(): void {
  const navbar = document.querySelector<HTMLElement>('.sticky-nav');
  const mobileMenu = document.querySelector<HTMLElement>('.mobile-menu');
  let lastScrollTop = window.pageYOffset;
  let navHeight = navbar!.offsetHeight;

  function updateNavHeight(): void {
    navHeight = navbar!.offsetHeight;
  }

  function toggleScrollClass(): void {
    const scrollTop = window.pageYOffset;

    if (navbar !== null) {
      if (scrollTop > navHeight && scrollTop > lastScrollTop && !mobileMenu?.classList.contains('show')) {
        navbar.classList.add('scroll-down');
        lastScrollTop = scrollTop;
      } else if (scrollTop < lastScrollTop - navHeight) {
        navbar.classList.remove('scroll-down');
        lastScrollTop = scrollTop;
      }
    }
  }

  window.addEventListener('scroll', toggleScrollClass)
  window.addEventListener('resize', updateNavHeight)
}
