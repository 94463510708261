import {Component, Prop} from "vue-property-decorator"
import {TsxComponent} from "@/components/TsxComponent"
import {VNode} from "vue"

import {ToastMessagesPayload} from "@/mixins/BackendClientMixin"
import { BIconCheckCircle, BIconExclamationCircle } from "bootstrap-vue";

export interface Props {
  messages: ToastMessagesPayload;
  onChangeState?: () => void;
}

@Component
export default class extends TsxComponent<Props> implements Props{
  @Prop(Object) public messages!: ToastMessagesPayload

  public render(): VNode {
    return (
      <div>
        <b-toast
          no-close-button
          visible={this.messages.errorToastMessageKey !== null}
          toaster="b-toaster-bottom-center"
          variant="danger"
          onHidden={() => this.$emit('changeState')}
        >
          <div class="d-flex align-items-center">
            <BIconExclamationCircle class="mr-3" />
            {this.messages.errorToastMessageKey === null ? null : this.translation(this.messages.errorToastMessageKey)}
          </div>
        </b-toast>
        <b-toast
          no-close-button
          visible={this.messages.warningToastMessageKey !== null}
          toaster="b-toaster-bottom-center"
          variant="warning"
          onHidden={() => this.$emit('changeState')}
        >
          <div class="d-flex align-items-center">
            <BIconExclamationCircle class="mr-3" />
            {this.messages.warningToastMessageKey === null ? null : this.translation(this.messages.warningToastMessageKey)}
          </div>
        </b-toast>
        <b-toast
          no-close-button
          visible={this.messages.successToastMessageKey !== null}
          toaster="b-toaster-bottom-center"
          onHidden={() => this.$emit('changeState')}
        >
          <div class="d-flex align-items-center">
            <BIconCheckCircle class="mr-3" />
            {this.messages.successToastMessageKey === null ? null : this.translation(this.messages.successToastMessageKey)}
          </div>
        </b-toast>
      </div>
    )
  }
}
