import {ErrorCodes} from "@/constants/APIconstants";
import {LogoutPayload, profileStore} from "@/_modules/profile/store/profile";
import router from "@/router";
import * as ProfileRoutes from "@/_modules/profile/router/routes";
import {webSocketHandler} from "@/ws/ws-handler";

interface Source {
  code: number;
  explanation: string;
  message: string;
}

export interface RSocketError {
  stack: string;
  name: string;
  message: string;
  source: Source;
}

export async function handleWebSocketError(error: RSocketError): Promise<void> {
  if (error.source) {
    switch (error.source.message) {
      case ErrorCodes.SessionNotFound:
        await profileStore.logout(new LogoutPayload(router, {name: ProfileRoutes.Login.name, params: {errorCode: 'SessionClosed'}}, true))
        await webSocketHandler.wsStreamDown()
        break
      case ErrorCodes.SessionClosed:
        await profileStore.logout(new LogoutPayload(router, {name: ProfileRoutes.Login.name, params: {errorCode: 'SessionClosed'}}, true))
        await webSocketHandler.wsStreamDown()
        break
      /*case ErrorCodes.PayloadParsingError:
        EventBus.$emit(CustomEvents.FlashMsg, {
          errorMessageKey: 'err_chat_payload_parsing_error',
          warningMessageKey: null,
          successMessageKey: null
        })
        break*/
      default:
        break
    }
  }
}