import {RouteItem, Home} from "@/router/routes"

export {Home}

export const DemandDetails: RouteItem = {
  path: "/map-demand/demand_details",
  name: "demand_details"
}

export const DemandNew: RouteItem = {
  path: "/new-demand",
  name: "new-demand"
}

export const DemandEdit: RouteItem = {
  path: "/demand_edit",
  name: "demand_edit"
}

export const DemandFind: RouteItem = {
  path: "/map-demand",
  name: "map-demand"
}

export const DemandEditFromActivity: RouteItem = {
  path: "/demands/demand_edit",
  name: "demand_edit_from_activity"
}

export const DemandNewFromActivity: RouteItem = {
  path: "/demands/new-demand",
  name: "demand_new_from_activity"
}
