import { ImportedComponent } from "vue/types/options"
import * as Routes from "./routes"

export default [
  {
    path: `${Routes.DemandDetails.path}/:demandId`,
    name: Routes.DemandDetails.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "demands" */ "../views/DemandDetails")
  },
  {
    path: `${Routes.DemandEdit.path}/:demandId`,
    name: Routes.DemandEdit.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "demands" */ "../views/Demand")
  },
  {
    path: `${Routes.DemandEditFromActivity.path}/:demandId`,
    name: Routes.DemandEditFromActivity.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "demands" */ "../views/Demand")
  },
  {
    path: Routes.DemandNew.path,
    name: Routes.DemandNew.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "demands" */ "../views/Demand")
  },
  {
    path: Routes.DemandNewFromActivity.path,
    name: Routes.DemandNewFromActivity.name,
    meta: {
      secured: true
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "demands" */ "../views/Demand")
  },
  {
    path: Routes.DemandFind.path,
    name: Routes.DemandFind.name,
    meta: {
      secured: false
    },
    component: (): Promise<ImportedComponent> => import(/* webpackChunkName: "demands" */ "../views/FindDemand")
  }
]
