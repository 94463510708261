import '@/utils/polyfills'
import Vue from "vue"
import App from "@/App"
import i18n from "@/i18n"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as VueGoogleMaps from 'gmap-vue'

import router from "@/router/index"

import VueAnalytics from 'vue-analytics'
import {isEmpty} from "@/constants/DataBoundaries"
import {googleAnalyticsKey, googleMapApiKey, googleReCaptchaPublicKey} from "@/constants/ApplicationConfiguration"

if (!isEmpty(googleAnalyticsKey)) {
  Vue.use(VueAnalytics, {
    id: googleAnalyticsKey,
    router
  })
}

import store from "@/store/index"

import {cleanUpStoreIfRequired} from "@/utils/store-utils"
cleanUpStoreIfRequired()

import Vuelidate from "vuelidate"

Vue.use(Vuelidate)
Vue.use(VueGoogleMaps, {
  load: {
    key: googleMapApiKey,
    libraries: 'places'
  }
})
Vue.component('gmap-cluster', VueGoogleMaps.Cluster)

import 'vue-multiselect/dist/vue-multiselect.min.css'

// Bootstrap initialization
import BootstrapVue from "bootstrap-vue"
Vue.use(BootstrapVue)

// Import global styles
import "@/assets/styles/main.scss"
import {VueReCaptcha} from "vue-recaptcha-v3"

Vue.use(VueReCaptcha, { siteKey: googleReCaptchaPublicKey })

export const EventBus = new Vue()

Vue.config.productionTip = false


import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
