import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";

@Component({name: 'NaabridRoadMap'})
export default class extends I18nComponent {
  public render(): VNode {
    return (
      <b-row>
        <b-col>
          <b-row class="mt-2 mb-n5">
            <b-col class="d-flex mb-5" cols="12" sm="6" md="4">
              <div>
                <b-avatar class="text-white font-weight-bold" size="sm" variant="primary" text="1"/>
              </div>
              <div class="ml-2">
                {this.translation('lp_roadmap_txt_1')}
              </div>
            </b-col>
            <b-col class="d-flex mb-5" cols="12" sm="6" md="4">
              <div>
                <b-avatar class="text-white font-weight-bold" size="sm" variant="primary" text="2"/>
              </div>
              <div class="ml-2">
                {this.translation('lp_roadmap_txt_2')}
              </div>
            </b-col>
            <b-col class="d-flex mb-5" cols="12" sm="6" md="4">
              <div>
                <b-avatar class="text-white font-weight-bold" size="sm" variant="primary" text="3"/>
              </div>
              <div class="ml-2">
                {this.translation('lp_roadmap_txt_3')}
              </div>
            </b-col>
            <b-col class="d-flex mb-5" cols="12" sm="6" md="4">
              <div>
                <b-avatar class="text-white font-weight-bold" size="sm" variant="primary" text="4"/>
              </div>
              <div class="ml-2">
                {this.translation('lp_roadmap_txt_4')}
              </div>
            </b-col>
            <b-col class="d-flex mb-5" cols="12" sm="6" md="4">
              <div>
                <b-avatar class="text-white font-weight-bold" size="sm" variant="primary" text="5"/>
              </div>
              <div class="ml-2">
                {this.translation('lp_roadmap_txt_5')}
              </div>
            </b-col>
            <b-col class="d-flex mb-5" cols="12" sm="6" md="4">
              <div>
                <b-avatar class="text-white font-weight-bold" size="sm" variant="primary" text="6"/>
              </div>
              <div class="ml-2">
                {this.translation('lp_roadmap_txt_6')}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    )
  }
}
