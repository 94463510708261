import {Component, Watch} from "vue-property-decorator"

import {I18nComponent} from "@/components/I18nComponent"

import {axiosErrorToApplicationError} from "@/api"
import {defaultErrorHandler, ErrorHandler} from "@/utils/errorHandler";
import {EventBus} from "@/main";
import {CustomEvents} from "@/constants/ComponentEvents";
import { LogoutPayload, profileStore } from "@/_modules/profile/store/profile";

import * as Error from "@/utils/errors";
import * as ProfileRoutes from "@/_modules/profile/router/routes";
import * as ServiceRoutes from "@/_modules/service/router/routes"
import * as DemandRoutes from "@/_modules/demand/router/routes"
import * as RequestServiceRoutes from "@/_modules/request/router/request-service-routes"
import * as ProposalDemandRoutes from "@/_modules/proposal/router/proposal-demand-routes"
import {Location} from "vue-router/types/router";
import router from "@/router";
import { isEmpty } from "@/constants/DataBoundaries";
import { MessagingCenter } from "@/_modules/message-center/router/routes";

export interface MessagesPayload {
  errorMessageKey: string | null;
  warningMessageKey: string | null;
  successMessageKey: string | null;
}

export interface ToastMessagesPayload {
  errorToastMessageKey: string | null;
  warningToastMessageKey: string | null;
  successToastMessageKey: string | null;
}

export const successPayload = (key: string): MessagesPayload => {
  return {
    errorMessageKey: null,
    warningMessageKey: null,
    successMessageKey: key
  }
}

// Define keep alive interval outside component to avoid creating multiple timers
let keepAliveInterval: number | null = null;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component
export default abstract class extends I18nComponent {

  public busy: boolean = false

  public errorMessageKey: string | null = null
  public warningMessageKey: string | null = null
  public successMessageKey: string | null = null
  public errorToastMessageKey: string | null = null
  public warningToastMessageKey: string | null = null
  public successToastMessageKey: string | null = null

  public get messagesPayload(): MessagesPayload {
    return {
      errorMessageKey: this.errorMessageKey,
      warningMessageKey: this.warningMessageKey,
      successMessageKey: this.successMessageKey
    }
  }

  public get toastMessagesPayload(): ToastMessagesPayload {
    return {
      errorToastMessageKey: this.errorToastMessageKey,
      warningToastMessageKey: this.warningToastMessageKey,
      successToastMessageKey: this.successToastMessageKey
    }
  }

  @Watch('errorMessageKey')
  @Watch('successMessageKey')
  @Watch('warningMessageKey')
  public onGlobalMessageChanged(): void {
    EventBus.$emit(CustomEvents.FlashMsg, this.messagesPayload)
    EventBus.$on(CustomEvents.DismissMsg, () => {
      this.clearMessages()
    })
  }

  @Watch('errorToastMessageKey')
  @Watch('successToastMessageKey')
  @Watch('warningToastMessageKey')
  public onGlobalToastMessageChanged(): void {
    EventBus.$emit(CustomEvents.ToastMsg, this.toastMessagesPayload)
    EventBus.$on(CustomEvents.DismissToast, () => {
      this.clearMessages()
    })
  }

  public clearMessages(): void {
    this.errorMessageKey = null
    this.warningMessageKey = null
    this.successMessageKey = null
    this.errorToastMessageKey = null
    this.warningToastMessageKey = null
    this.successToastMessageKey = null
  }

  public resetClientState(): void {
    this.clearMessages()
    this.busy = false
  }

  protected async touchSession(): Promise<void> {
    try {
      await profileStore.touchSession()
    } catch (err) {
      const e = axiosErrorToApplicationError(err)

      if (e instanceof Error.AuthenticationError) {
        const location: Location = {name: ProfileRoutes.Login.name}

        if (e.code !== undefined) {
          location.params = {errorCode: e.code}
        }

        await profileStore.logout(new LogoutPayload(router, location, true))
      }
    }
  }

  public keepSessionAlive(): void {
    const sessionTouchInterval = 280000;
    keepAliveInterval = window.setInterval(this.touchSession, sessionTouchInterval)
  }

  public get shouldKeepSessionAlive(): boolean {
    const keepAliveViews = [
      MessagingCenter.path,
      ProfileRoutes.Profile.path,
      ServiceRoutes.ServiceNew.path,
      ServiceRoutes.ServiceEdit.path,
      DemandRoutes.DemandNew.path,
      DemandRoutes.DemandEdit.path,
      RequestServiceRoutes.RequestServiceDraft.path,
      RequestServiceRoutes.RequestRequester.path,
      RequestServiceRoutes.RequestProvider.path,
      RequestServiceRoutes.FromOverviewRequestRequester.path,
      RequestServiceRoutes.FromOverviewRequestProvider.path,
      ProposalDemandRoutes.ProposalDraft.path,
      ProposalDemandRoutes.ProposalRequester.path,
      ProposalDemandRoutes.ProposalProvider.path,
      ProposalDemandRoutes.FromOverviewProposalRequester.path,
      ProposalDemandRoutes.FromOverviewProposalProvider.path
    ]
    return !isEmpty(this.$route.fullPath) && keepAliveViews.some(routePath => {
      return this.$route.path!.includes(routePath)
    })
  }

  public mounted(): void {
    if (this.shouldKeepSessionAlive && keepAliveInterval === null) {
      this.keepSessionAlive()
    }
  }

  public beforeDestroy(): void {
    if (!this.shouldKeepSessionAlive && keepAliveInterval !== null) {
      clearInterval(keepAliveInterval)
      keepAliveInterval = null
    }
  }

  public withRequest<T>(promise: Promise<T>, errorHandler?: ErrorHandler, silentUpdate?: boolean): Promise<T> {
    if (!silentUpdate) {
      this.resetClientState()
      this.busy = true
    }
    return promise.catch(async e => {
      const error = axiosErrorToApplicationError(e)
      const handler: ErrorHandler = errorHandler === undefined ? defaultErrorHandler : errorHandler
      await handler.handleError(error)
      if (handler.errorMessageKey !== undefined) {
        this.errorMessageKey = handler.errorMessageKey
      } else {
        console.error('Undefined error during request', error, e)
      }
      throw error
    }).finally(() => {
      this.busy = false
    })
  }
}
