import {Component, Prop} from "vue-property-decorator"
import {TsxComponent} from "@/components/TsxComponent"
import {VNode} from "vue"

import {MessagesPayload} from "@/mixins/BackendClientMixin"
import Button from "@/components/form/Button";
import { BIconX } from "bootstrap-vue";

export interface Props {
  messages: MessagesPayload;
  cssClass?: string;
  //onDismiss?: () => void;
  onChangeState?: () => void;
  errorMsgBtn?: boolean;
}

@Component
export default class extends TsxComponent<Props> implements Props{
  @Prop(Object) public messages!: MessagesPayload
  @Prop(String) public cssClass?: string
  @Prop(Boolean) public readonly errorMsgBtn?: boolean

  public get renderRefreshButton(): boolean {
    return (
      this.messages.errorMessageKey === 'err_service_request_status_incorrect' ||
      this.messages.errorMessageKey === 'err_proposal_status_incorrect'
    )
  }

  public render(): VNode {
    return (
      <div class={this.cssClass}>
        <b-alert
          show={this.messages.errorMessageKey !== null}
          dismissible
          class="text-center"
          variant="danger"
          onDismissed={() => this.$emit('changeState')}
        >
          <template slot="default">
            {this.messages.errorMessageKey === null ? null : this.translation(this.messages.errorMessageKey)}
            {this.renderRefreshButton &&
              <Button onClick={() => {
                window.location.reload()
              }} size="sm" variant="danger" class="ml-3" label={this.translation('btn_flash_msg_refresh')} />
            }
          </template>
          <template slot="dismiss">
            <BIconX/>
          </template>
        </b-alert>
        <b-alert
          dismissible
          class="text-center"
          show={this.messages.warningMessageKey !== null}
          variant="warning"
        >
          <template slot="default">
            {this.messages.warningMessageKey === null ? null : this.translation(this.messages.warningMessageKey)}
          </template>
          <template slot="dismiss">
            <BIconX/>
          </template>
        </b-alert>
        <b-alert
          dismissible
          class="text-center"
          show={this.messages.successMessageKey !== null}
          variant="success"
          onDismissed={() => this.$emit('changeState')}
        >
          <template slot="default">
            {this.messages.successMessageKey === null ? null : this.translation(this.messages.successMessageKey)}
          </template>
          <template slot="dismiss">
            <BIconX/>
          </template>
        </b-alert>
      </div>
    )
  }
}
