import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { VNode } from "vue/types/umd";

import { I18nComponent } from "@/components/I18nComponent";
import AppIconClose from "@/components/icons/AppIconClose";
import AppIconPlay from "@/components/icons/AppIconPlay";
import random from "@/utils/random";

interface Props {
  videoId: string;
}

@Component({name: "FullscreenYoutubeVideo", components: { }})
export default class FullscreenYoutubeVideo extends I18nComponent implements Props {
  @Prop({ type: String }) public readonly videoId!: string
  @Prop({ type: String, required: false, default: () => random.generateElementId() }) public readonly id!: string

  private modalVisible: boolean = false;
  private player?: YT.Player = undefined;
  private currentTime: number = 0;
  private youtubeApiReady: Promise<void> = new Promise<void>((resolve) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).onYouTubeIframeAPIReady = () => {
      resolve()
    }
  });

  private async isYoutubeApiReady(): Promise<boolean> {
    await this.youtubeApiReady;

    return true;
  }

  private loadYoutubeApi(): void {
    if (document.getElementById('youtube-iframe-api') === null) {
      const scriptTag = document.createElement('script');

      scriptTag.src = "https://www.youtube.com/player_api";
      scriptTag.id = "youtube-iframe-api";
      document.body.append(scriptTag);
    }
  }

  private initPlayer(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.player = new window!.YT.Player(`yt-player-${this.id}`, {
        videoId: this.videoId,
        playerVars: {
          'playsinline': 1
        },
        events: {
          onReady: () => {
            resolve();
          },
          onApiChange: (event: YT.PlayerEvent) => {
            console.log(event);
            
          },
          onError: (error: YT.OnErrorEvent) => {
            reject(error);
          }
        } 
      });
    });
  }

  private async onModalShown(): Promise<void> {
    await this.initPlayer();

    const timeout = 100;

    // setTimeout is needed here to start video playback consistently. Without it the video doesn't play sometimes.
    setTimeout(() => {
      if (typeof this.player !== undefined) {
        this.player!.seekTo(this.currentTime, true); 
  
        this.player!.playVideo()
      }
    }, timeout);
  }

  private onModalHide(): void {
    // Preserve current timestamp. Reset to 0 if playback has ended
    this.currentTime = this.player?.getPlayerState() !== YT.PlayerState.ENDED ? this!.player!.getCurrentTime() : 0;

    // Pausing the video here doesn't work. Destroy player when modal is closed.
    if (typeof this.player !== undefined) {
      this.player!.destroy();
    }

    this.player = undefined;
  }

  public mounted(): void {
    this.loadYoutubeApi();
  }

  public render(): VNode {
    return (
      <div>
        {this.isYoutubeApiReady() && 
          <div>
            <b-link class="btn-watch-video" onClick={() => (this.modalVisible = true)}>
              <AppIconPlay class="mb-1" />
              {this.translation('btn_watch_intro_video')}
            </b-link>
            <b-modal
              body-class="p-0"
              centered
              class="youtube-video-modal"
              hide-header={true}
              hide-footer={true}
              size="xl"
              static
              v-model={this.modalVisible}
              onHide={this.onModalHide}
              onShown={this.onModalShown}
              scopedSlots={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                default: ({ hide }: { hide: any }) => {
                  return (
                    <div>
                      <b-link class="youtube-modal-close" onClick={hide}>
                        <AppIconClose class="text-white" />
                      </b-link>
                      <div class="embed-responsive embed-responsive-16by9">
                        <div class="embed-responsive-item" id={`yt-player-${this.id}`}></div>
                      </div>
                    </div>
                  )
                }
              }}
            >
            </b-modal>
          </div>
        }
      </div>
    )
  }
}
