import {Component, Watch} from "vue-property-decorator"
import {VNode} from "vue"

import {BIconChat, BIconCircleFill, BIconPersonFill, BIconPersonCircle, BIconPencil, BIconBoxArrowRight, BIconCalendar4Week, BIconPlusCircle, BIconChatText} from "bootstrap-vue"

import {appStore, authStore} from "@/store"

import * as ProfileRoutes from "@/_modules/profile/router/routes"
import * as ServicesRoutes from "@/_modules/service/router/routes"
import * as DemandsRoutes from "@/_modules/demand/router/routes"
import * as ActivityRoutes from "@/_modules/activity/router/routes"
import {MessagingCenter} from "@/_modules/message-center/router/routes"
import {
  Home,
  AboutUs,
  PaymentAgreement,
  PrivacyPolicy,
  ProcedureForResolvingClaims,
  TermsAndConditions,
  ThankYou
} from "@/router/routes"

import {Language as Lang} from "@/types"
import {chatStore} from "@/_modules/message-center/store/chat-store";
import {BadgeEvent, badgesStore} from "@/store/badges-store";
import RouterLink from "@/components/RouterLink";
import SvgIcon from "@/components/SvgIcon";
import BackendClientMixin from '@/mixins/BackendClientMixin'
import AppIconMenu from "../icons/AppIconMenu"
import AppIconLanguage from "../icons/AppIconLanguage"
import AppIconClose from "../icons/AppIconClose"

import {initStickyNav} from '@/utils/sticky-nav'
import AppIconChatUnread from "../icons/AppIconChatUnread"
import { activityMenuItems } from "./ActivityNav"

interface LocaleMenu {
  code: Lang;
  title: string;
}

const languages: LocaleMenu[] = [
  {
    code: Lang.EST,
    title: 'Est'
  },
  {
    code: Lang.ENG,
    title: 'Eng'
  },
  {
    code: Lang.RUS,
    title: 'Rus'
  }
]


// eslint-disable-next-line no-shadow
export enum DocType {
  PROPOSAL = "PROPOSAL",
  REQUEST = "REQUEST"
}

// eslint-disable-next-line no-shadow
export enum UserRole {
  PROVIDER = "PROVIDER",
  REQUESTER = "REQUESTER"
}

@Component({name: 'MainMenu', components: {BIconChat, BIconPersonFill, BIconPersonCircle, BIconPencil, BIconCalendar4Week, BIconPlusCircle, BIconBoxArrowRight, BIconCircleFill, BIconChatText}})
export default class MainMenu extends BackendClientMixin {

  private buttonPulseAnimationNotifications: string[] = ['btn-anim-pulse-notifications']
  private buttonPulseAnimationBlue: string[] = ['btn-anim-pulse-blue']
  private iconPulseAnimation: string[] = ['anim-icon-blue']
  private mobileMenuOpen: boolean = false

  @Watch('$route')
  public userSign(): string | undefined {
    if (authStore.authInfo !== undefined) {
      try {
        return authStore.authInfo.displayName!.first[0].toUpperCase() + authStore.authInfo!.displayName!.last[0].toUpperCase()
      } catch (e) {
        return undefined
      }
    } else {
      return undefined
    }
  }

  private showMobileMenu(): void {
    this.mobileMenuOpen = true
    document.body.classList.add('overflow-hidden')
  }

  private hideMobileMenu(): void {
    this.mobileMenuOpen = false
    document.body.classList.remove('overflow-hidden')
  }

  public async mounted(): Promise<void> {
    await this.$nextTick()

    // Init sticky nav after main menu is rendered
    initStickyNav()

    // Close mobile menu if desktop menu becomes visible during resize
    window.addEventListener('resize', () => {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (this.mobileMenuOpen && window.innerWidth >= 992) {
        this.hideMobileMenu()
      }
    })
  }

  public onLangClick(e: Event, code: Lang): void {
    e.preventDefault()
    appStore.setLocale(code)
  }

  public get selectedLanguage(): LocaleMenu {
    const lang = appStore.locale
    const res: LocaleMenu | undefined = languages.find(x => x.code === lang)
    if (res === undefined) {
      throw Error(`Illegal language selected: ${res}`)
    }
    return res
  }

  public get authenticated(): boolean {
    return authStore.authenticated
  }

  public get badgesAmount(): number {
    return badgesStore.getBadgeEvents.length
  }

  private defineBadgeRelation(badge: BadgeEvent): string | undefined {
    if (badge.userRole === UserRole.PROVIDER && badge.docType === DocType.PROPOSAL) {
      return 'proposals'
    } else if (badge.userRole === UserRole.REQUESTER && badge.docType === DocType.PROPOSAL) {
      return 'demands'
    } else if (badge.userRole === UserRole.PROVIDER && badge.docType === DocType.REQUEST) {
      return 'services'
    } else if (badge.userRole === UserRole.REQUESTER && badge.docType === DocType.REQUEST) {
      return 'requests'
    }

    return undefined
  }

  public renderCabinetButton(): VNode {
    const profileIsFilled = authStore.authInfo!.profileCompleted
    return (
      <b-nav-item-dropdown
        toggle-class={['btn btn-primary cabinet-button rounded-pill', !profileIsFilled ? this.buttonPulseAnimationBlue : '']}
        class="d-none d-lg-block account-dropdown mr-0"
        right
        onShown={() => {
          this.buttonPulseAnimationBlue.shift()
        }}
      >
        <template slot="button-content">
          {profileIsFilled && this.badgesAmount === 0 &&
            <b-avatar class="user-sign" variant="white" text={this.userSign()?.toUpperCase()}/>
          }
          {profileIsFilled && badgesStore.getBadgeEvents.length > 0 &&
            <b-avatar class={['user-sign', this.buttonPulseAnimationNotifications]} variant="danger" text={this.badgesAmount.toString()}/>
          }
          {!profileIsFilled &&
            <b-icon-person-circle aria-hidden="true" />
          }
          <span class="cabinet-button-title">{this.translation("menu_title_cabinet")}</span>
        </template>
        <b-dropdown-item to={ActivityRoutes.Overview}>
          {this.badgesAmount === 0 &&
            <b-icon-calendar4-week aria-hidden="true" variant="primary"/>
          }
          {this.badgesAmount > 0 &&
            <b-avatar class="badge-amount" variant="danger" text={this.badgesAmount.toString()} />
          }
          <span class="pl-2">{this.translation("menu_item_activity_overview")}</span>
        </b-dropdown-item>
        <b-dropdown-item to={DemandsRoutes.DemandNew.path}>
          <b-icon-plus-circle aria-hidden="true" variant="primary"/>
          <span class="pl-2">{this.translation("menu_item_add_demand")}</span>
        </b-dropdown-item>
        <b-dropdown-item to={ServicesRoutes.ServiceNew.path}>
          <b-icon-plus-circle aria-hidden="true" variant="primary"/>
          <span class="pl-2">{this.translation("menu_item_add_service")}</span>
        </b-dropdown-item>
        <b-dropdown-item onClick={() => this.iconPulseAnimation.shift()} to={ProfileRoutes.Profile.path}>
          <b-icon-pencil aria-hidden="true" variant="primary"/>
          <span class={!profileIsFilled ? [this.iconPulseAnimation, 'pl-2'] : 'pl-2'}>{this.translation("menu_item_edit_profile")}</span>
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item to={ProfileRoutes.Logout.path}>
          <b-icon-box-arrow-right aria-hidden="true" variant="primary"/>
          <span class="pl-2">{this.translation("menu_item_logout")}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    )
  }

  public renderMobileCabinetButton(): VNode {
    const profileIsFilled = authStore.authInfo!.profileCompleted
    const badges = badgesStore.getBadgeEvents

    return (
      <b-nav-item-dropdown
        toggle-class={['btn btn-primary rounded-pill d-lg-none cabinet-button mr-4 p-1', !profileIsFilled ? this.buttonPulseAnimationBlue : '']}
        class="d-lg-none account-dropdown mr-0"
        right
        onShown={() => {
          this.buttonPulseAnimationBlue.shift()
        }}
      >
        <template slot="button-content">
          {profileIsFilled && this.badgesAmount === 0 &&
            <b-avatar class="user-sign mr-0" variant="white" text={this.userSign()?.toUpperCase()}/>
          }
          {profileIsFilled && badgesStore.getBadgeEvents.length > 0 &&
            <b-avatar class={['user-sign mr-0', this.buttonPulseAnimationNotifications]} variant="danger" text={this.badgesAmount.toString()}/>
          }
          {!profileIsFilled &&
            <b-icon-person-circle class="my-0" aria-hidden="true" />
          }
        </template>
        
        {activityMenuItems.map((menuItem) => {
          return(
            <b-dropdown-item to={{name: menuItem.name}} onClick={this.hideMobileMenu}>
              {this.translation(menuItem.titleTranslationKey)}
              {badges.find(badge => this.defineBadgeRelation(badge) === menuItem.name) ?
                <b-avatar
                  class="badge-amount badge-amount-sm mb-0 ml-1"
                  variant={'danger'}
                >
                  {String(badges.filter(it => this.defineBadgeRelation(it) === menuItem.name).length)}
                </b-avatar> : ''}
            </b-dropdown-item>
          )
        })}
        <b-dropdown-item to={ProfileRoutes.Profile.path} onClick={this.hideMobileMenu}>
          {this.translation("menu_item_edit_profile")}
        </b-dropdown-item>
        <b-dropdown-item to={ProfileRoutes.Logout.path} onClick={this.hideMobileMenu}>
          {this.translation("menu_item_logout")}
        </b-dropdown-item>
      </b-nav-item-dropdown>
    )
  }

  public renderLoginButton(): VNode {
    return (
      <b-button variant="primary" class="btn-login mr-4 mr-lg-0" pill={true} to={ProfileRoutes.Login.path} onClick={this.hideMobileMenu}>
        <b-icon-person-circle class="my-0" aria-hidden="true" />
        <span class="d-none d-lg-inline">{this.translation("menu_item_login")}</span>
      </b-button>
    )
  }

  public renderMessagesButton(): VNode {
    const unreadMsg = chatStore.getInitialAmountOfUnreadMessages
    return (
      <RouterLink class="nav-item-message mr-4 mr-lg-8" to={MessagingCenter.path} onClick={this.hideMobileMenu} aria-label={this.translation("route_messaging-center")}>
        <template slot="label">
          <SvgIcon
            icon={
              () => {
                return (
                  <div class="position-relative">
                    {unreadMsg === 0 &&
                      <b-icon-chat-text class="d-flex" aria-hidden="true" variant={'body'} />
                    }
                    {unreadMsg > 0 &&
                      <div class="badge-chat-events">
                        <AppIconChatUnread class="app-icon-lg text-danger mt-0" aria-hidden="true" />
                        <div class="chat-events-counter">
                          {unreadMsg}
                        </div>
                      </div>
                    }
                  </div>
                )
              }
            }
          />
        </template>
      </RouterLink>
    )
  }

  public render(): VNode {
    return (
      <div class="sticky-nav sticky-top w-100 border-bottom bg-white d-flex">
        <b-container fluid>
          <b-navbar class="main-menu px-lg-4" toggleable="lg">
            <b-navbar-brand to={ProfileRoutes.Home.path}>
              <b-img
                src={require('../../assets/images/logo.png')}
                srcset={[`
                      ${require('../../assets/images/logo.png')} 1x,
                      ${require('../../assets/images/logo@2x.png')} 2x,
                      ${require('../../assets/images/logo@3x.png')} 3x
                  `]} width="192" height="35" alt="naabrid.ee"
              />
            </b-navbar-brand>
            <b-navbar-nav class="flex-row ml-auto align-items-center">
              <RouterLink class="d-none d-lg-flex" linkClasses="hover-shadow" txt={this.translation("nav_item_providers")} to={ServicesRoutes.ServiceFind.path} />
              <RouterLink class="d-none d-lg-flex" linkClasses="hover-shadow" txt={this.translation("nav_item_requesters")} to={DemandsRoutes.DemandFind.path}/>
              {this.authenticated && this.renderMessagesButton()}
              <b-nav-item-dropdown class="language-dropdown d-none d-lg-flex" toggle-class="btn border-0 font-weight-normal pr-2" disabled={this.busy}>
                <template slot="button-content">
                  <AppIconLanguage class="mr-2" />
                  {this.translation(`text_${this.selectedLanguage.title.toUpperCase()}`)}
                </template>
                {languages.map(language => {
                  return (
                    <b-dropdown-item class="text-center" linkClass={appStore.locale === language.code ? 'font-weight-bold' : ''} onClick={(e: Event) => this.onLangClick(e, language.code)}>{this.translation(`enum_language_user_speak_short_${language.title.toLocaleLowerCase()}`)}</b-dropdown-item>
                  )
                })}
              </b-nav-item-dropdown>
              {this.authenticated && this.renderCabinetButton()}
              <li class="my-n3 my-lg-0">
                {this.authenticated && this.renderMobileCabinetButton()}
                {!this.authenticated && this.renderLoginButton()}
              </li>
            </b-navbar-nav>
            <b-link class="navbar-toggler border-0 p-0 text-primary" aria-label={this.translation('nav_mobile_toggle')} onClick={this.showMobileMenu}>
              <AppIconMenu class="app-icon-lg m-0" aria-hidden="true" />
            </b-link>
            <b-navbar-nav class={`mobile-menu${this.mobileMenuOpen ? ' show' : ''}`} id="nav-collapse">
              <li class="container-fluid d-flex align-items-center mobile-menu-header">
                <div class="small mr-auto">
                  {languages.map(language => {
                    return (
                      <b-link
                        class={`mr-4 text-body text-decoration-none${language === this.selectedLanguage ? ' font-weight-bold' : ''}`}
                        onClick={(e: Event) => {
                          this.onLangClick(e, language.code)
                          this.hideMobileMenu()
                        }}
                      >
                        {this.translation(`enum_language_user_speak_short_${language.title.toLocaleLowerCase()}`)}
                      </b-link>
                    )
                  })}
                </div>
                {this.authenticated && this.renderMessagesButton()}
                {this.authenticated && this.renderMobileCabinetButton()}
                {!this.authenticated && this.renderLoginButton()}
                <b-link onClick={this.hideMobileMenu}>
                  <AppIconClose class="align-bottom text-primary" />
                </b-link>
              </li>
              <li>
                <hr class="m-0" />
              </li>
              <li class="mobile-menu-content py-6 px-4">
                <ul class="list-unstyled py-4 pl-6 pr-4">
                  <li>
                    <b-link to={Home} onClick={this.hideMobileMenu}>
                      {this.translation("menu_item_home")}
                    </b-link>
                  </li>
                  <li>
                    <b-link to={AboutUs} onClick={this.hideMobileMenu}>
                      {this.translation("menu_item_about")}
                    </b-link>
                  </li>
                  <li>
                    <b-link to={TermsAndConditions} onClick={this.hideMobileMenu}>
                      {this.translation("menu_general_terms_and_conditions")}
                    </b-link>
                  </li>
                  <li>
                    <b-link to={PrivacyPolicy} onClick={this.hideMobileMenu}>
                      {this.translation("menu_item_privacy_policy")}
                    </b-link>
                  </li>
                  <li>
                    <b-link to={ProcedureForResolvingClaims} onClick={this.hideMobileMenu}>
                      {this.translation('menu_item_procedure_for_resolving_claims')}
                    </b-link>
                  </li>
                  <li>
                    <b-link to={PaymentAgreement} onClick={this.hideMobileMenu}>
                      {this.translation('menu_item_payment_terms')}
                    </b-link>
                  </li>
                  <li>
                    <b-link to={ThankYou} onClick={this.hideMobileMenu}>
                      {this.translation('menu_item_thank_you')}
                    </b-link>
                  </li>
                </ul>
              </li>
              <li>
                <hr class="m-0" />
              </li>
              <li class="d-flex flex-wrap justify-content-center py-2 px-4">
                <b-button class="mx-1" variant="outline-primary" to={ServicesRoutes.ServiceFind.path} onClick={this.hideMobileMenu}>{this.translation("nav_item_providers")}</b-button>
                <b-button class="mx-1" variant="outline-primary" to={DemandsRoutes.DemandFind.path} onClick={this.hideMobileMenu}>{this.translation("nav_item_requesters")}</b-button>
              </li>
            </b-navbar-nav>
          </b-navbar>
        </b-container>
      </div>
    )
  }
}
