//TODO: used as temp solution to cover undefined values until data comes from server
import {RequesterProtected} from "@/_modules/demand/types";
import {Currency, DealType, Qualification, RateType} from "@/types";
import {RequestDetails} from "@/_modules/request/types";
import {defaultMapCenter} from "@/constants/ApplicationConfiguration";
import {Dictionary} from "vue-router/types/router";
import {RateActionType} from "@/views/DealAnnouncer";

export function buildDefaultPrivateRequester(): RequesterProtected {
  return ({
    userId: '',
    name: {
      first: '',
      last: ''
    },
    verified: false,
    location: {
      address: {
        country: '',
        cityOrCounty: '',
        zipCode: '',
        address: ''
      },
      coordinates: {
        longitude: defaultMapCenter.longitude,
        latitude: defaultMapCenter.latitude
      }
    },
    sex: undefined,
    rating: {
      grade: '0.0',
      ratingsCount: 0
    },
    languages: []
  })
}

export function buildRequestData(): RequestDetails {
  return ({
    serviceId: '',
    title: '',
    requester: buildDefaultPrivateRequester(),
    location: {
      address: {
        country: 'EE',
        address: '',
        zipCode: '',
        cityOrCounty: ''
      },
      coordinates: {
        longitude: defaultMapCenter.longitude,
        latitude: defaultMapCenter.latitude
      }
    },
    service: {
      title: '',
      description_ru: undefined,
      description_et: undefined,
      description_en: undefined,
      scheduleInfo_ru: undefined,
      scheduleInfo_et: undefined,
      scheduleInfo_en: undefined,
      clientAge: [],
      clientDisease: [],
      clientMentalAbility: [],
      clientPhysicalActivity: [],
      clientSex: [],
      clientWeight: [],
      competencesWithParent: [],
      category: '',
      serviceId: '',
      rate: {
        type: RateType.HOURLY_PAYMENT,
        price: {
          amount: '',
          currency: Currency.EUR
        }
      },
      languages: [],
      location: {
        address: {
          country: '',
          cityOrCounty: '',
          zipCode: '',
          address: ''
        },
        coordinates: {
          longitude: defaultMapCenter.longitude,
          latitude: defaultMapCenter.latitude
        }
      },
      providedAtClientPlace: false,
      provider: {
        userId: '',
        name: {
          first: '',
          last: ''
        },
        verified: false,
        sex: undefined,
        rating: {
          grade: '0.0',
          ratingsCount: 0
        }
      },
      qualification: Qualification.UNKNOWN,
      distanceInMeters: ''
    },
    tasks: [],
    price: {
      servicePrice: {amount: '', currency: Currency.EUR},
      tax: {amount: '', currency: Currency.EUR}
    },
    lockState: {
      status: '',
      timeLeftInSeconds: 0,
      lockedBy: {
        first: '',
        last: ''
      }
    },
    status: '',
    servicePriceOverrided: false,
    isRatedBy: '',
    createdAt: new Date(),
    agreementFileId: '',
    agreementNumber: '',
    clientSex: undefined,
    clientWeight: undefined,
    languages: [],
    calculatePrice: true,
    clientRequirementsWithParent: [],
    clientDisease: [],
    clientMentalAbility: undefined,
    clientAge: undefined,
    clientPhysicalActivity: []
  })
}

export function onCreateRequestPayload(request: RequestDetails, requestId: string): Dictionary<string> {
  return {
    greetingTitle: 'request.title_msg_on_create',
    msg: 'request.msg_on_create',
    msgParams: `${request.service.title}&&${request.service.provider.name.first} ${request.service.provider.name.last}`,
    dealTitle: request.service.title,
    dealDate: request.createdAt.toString(),
    dealType: DealType.Request,
    fromDraft: 'true',
    dealId: requestId
  }
}

export function onRequestUpdateByRequester(request: RequestDetails): Dictionary<string> {
  return {
    greetingTitle: 'request.title_msg_on_update_by_requester',
    msg: 'request.msg_on_update_by_requester',
    msgParams: `${request.service.title}&&${request.service.provider.name.first} ${request.service.provider.name.last}`,
    dealDate: request.createdAt.toString(),
    dealTitle: request.service.title,
    dealType: DealType.Request,
    agreementFileId: request.agreementFileId,
    agreementNumber: request.agreementNumber
  }
}

export function onRequestUpdateByProvider(request: RequestDetails): Dictionary<string> {
  return {
    greetingTitle: 'request.title_msg_on_update_by_provider',
    msg: 'request.msg_on_update_by_provider',
    msgParams: `${request.service.title}&&${request.requester.name.first} ${request.requester.name.last}`,
    dealDate: request.createdAt.toString(),
    dealTitle: request.service.title,
    dealType: DealType.Request,
    agreementFileId: request.agreementFileId,
    agreementNumber: request.agreementNumber
  }
}

export function onRequestCurrentVersionAcceptByRequester(request: RequestDetails, isConcluded: boolean): Dictionary<string> {
  if (isConcluded) {
    return {
      greetingTitle: 'request.title_msg_on_deal_concluded_by_requester',
      msg: 'request.msg_on_deal_concluded_by_requester',
      msgParams: `${request.service.title}&&${request.service.provider.name.first} ${request.service.provider.name.last}`,
      dealDate: request.createdAt.toString(),
      dealTitle: request.service.title,
      dealType: DealType.Request,
      agreementFileId: request.agreementFileId,
      agreementNumber: request.agreementNumber,
      singleButtonBack: 'btn.pay'
    }
  } else {
    return {
      greetingTitle: 'request.title_msg_on_accepted_version_by_requester',
      msg: 'request.msg_on_accepted_version_by_requester',
      msgParams: `${request.service.title}&&${request.service.provider.name.first} ${request.service.provider.name.last}`,
      dealDate: request.createdAt.toString(),
      dealTitle: request.service.title,
      dealType: DealType.Request,
      agreementFileId: request.agreementFileId,
      agreementNumber: request.agreementNumber
    }
  }
}

export function onRequestCurrentVersionAcceptByProvider(request: RequestDetails, isConcluded: boolean): Dictionary<string> {
  return {
    greetingTitle: isConcluded ? 'request.title_msg_on_deal_concluded_by_provider' : 'request.title_msg_on_accepted_version_by_provider',
    msg: isConcluded ? 'request.msg_on_deal_concluded_by_provider' : 'request.msg_on_accepted_version_by_provider',
    msgParams: `${request.service.title}&&${request.requester.name.first} ${request.requester.name.last}`,
    dealDate: request.createdAt.toString(),
    dealTitle: request.service.title,
    dealType: DealType.Request,
    agreementFileId: request.agreementFileId,
    agreementNumber: request.agreementNumber
  }
}

export function onRequestCancelByRequester(request: RequestDetails): Dictionary<string> {
  return {
    greetingTitle: 'request.title_msg_on_cancel',
    msg: 'request.msg_on_cancel',
    msgParams: `${request.service.title}&&${request.service.provider.name.first} ${request.service.provider.name.last}`,
    dealType: DealType.Request,
    dealCancelled: 'true'
  }
}

export function onRequestCancelByProvider(request: RequestDetails): Dictionary<string> {
  return {
    greetingTitle: 'request.title_msg_on_declined',
    msg: 'request.msg_on_declined',
    msgParams: `${request.service.title}&&${request.requester.name.first} ${request.requester.name.last}`,
    dealType: DealType.Request,
    dealCancelled: 'true'
  }
}

export function onRequestRateByRequester(request: RequestDetails, requestId: string): Dictionary<string> {
  return {
    greetingTitle: 'request.title_msg_on_rating_send_requester',
    msg: 'request.msg_on_rating_send_requester',
    msgParams: `${request.requester.name.first}&&${request.service.provider.name.first} ${request.service.provider.name.last}`,
    rateActionType: RateActionType.Confirm,
    dealId: requestId,
    dealDate: request.createdAt.toString(),
    dealTitle: request.service.title,
    dealType: DealType.Request,
    agreementFileId: request.agreementFileId,
    agreementNumber: request.agreementNumber
  }
}

export function onRequestRateByProvider(request: RequestDetails, requestId: string): Dictionary<string> {
  return {
    greetingTitle: 'request.title_msg_on_rating_send_provider',
    msg: 'request.msg_on_rating_send_provider',
    msgParams: `${request.service.provider.name.first}&&${request.requester.name.first} ${request.requester.name.last}`,
    rateActionType: RateActionType.Rating,
    dealId: requestId,
    dealDate: request.createdAt.toString(),
    dealTitle: request.service.title,
    dealType: DealType.Request,
    agreementFileId: request.agreementFileId,
    agreementNumber: request.agreementNumber
  }
}

export function onRequestAcceptToDiscussion(request: RequestDetails): Dictionary<string> {
  return {
    greetingTitle: 'request.title_msg_on_accept_to_discussion',
    msg: 'request.msg_on_accept_to_discussion',
    msgParams: `${request.service.title}&&${request.requester.name.first} ${request.requester.name.last}`,
    singleButtonBack: 'btn.proceed_to_discussion',
    dealDate: request.createdAt.toString(),
    dealTitle: request.service.title,
    dealType: DealType.Request,
    agreementFileId: request.agreementFileId,
    agreementNumber: request.agreementNumber
  }
}
