export const toggleFondLogoVisibility = (): void => {
  const fondLogo = document.getElementById('fond-logo');
  const hideLogoPageOffset = 300;

  if (fondLogo === null) {
    return;
  }

  if (window.pageYOffset > hideLogoPageOffset) {
    fondLogo.classList.add('hide');
  } else {
    fondLogo.classList.remove('hide');
  }
}
