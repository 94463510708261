import {RecursivePartial} from "@/utils/typescript-library-extensions"
import {ValidationFieldSet, ValidationObject, ValidationRuleSet} from "@/utils/vuelidate-extension"
import {Dictionary} from "vue-router/types/router"
import {ServerOccurrence} from '@/_modules/request/types'
import {ServiceTypeForSearch} from "@/_modules/service/types";
import {ExistLocationCords} from "@/store/map";

export type UserId = string
export type DateNoTime = string
export type DateTime = string

export interface SearchModalFields {
  age: string[];
  weight: string[];
  disease: string[];
  physical: string[];
  mental: string[];
  speakLang: Language[];
  sex?: string[];
  serviceAtHome: ServiceTypeForSearch;
}

export interface ReusefulSearchFields extends SearchModalFields {
  sex: string[];
  serviceTypeId: string[];
  topLevelCategory: string;
  location: string;
  order: SortOrder<SortFields>;
  page: ArrayPage;
  center: MapCoordinates;
  zoom: number;
  distance: number;
  existLocationCords: ExistLocationCords;
}

export enum ReminderOptions {
  NONE = "NONE",
  B_5M = "B_5M",
  B_30M = "B_30M",
  B_1H = "B_1H",
  B_2H = "B_2H",
  B_1D = "B_1D",
  B_2D = "B_2D",
}

export enum RepeatOptions {
  ONCE = "ONCE",
  EVERY_DAY = "EVERY_DAY",
  EVERY_WORKING_DAY = "EVERY_WORKING_DAY",
  EVERY_WEEK = "EVERY_WEEK",
  EVERY_MONTH = "EVERY_MONTH"
}

export enum RepeatBy {
  COUNT = 'COUNT',
  UNTIL = 'UNTIL'
}

export enum ClientAge {
  A3_12 = "A3_12",
  A13_16 = "A13_16",
  A17_21 = "A17_21",
  A22_60 = "A22_60",
  A61_75 = "A61_75",
  A76_90 = "A76_90",
  A90_ = "A90_"
}
export enum ClientWeight {
  W10_30 = "W10_30",
  W30_50 = "W30_50",
  W50_80 = "W50_80",
  W80_90 = "W80_90",
  W90_ = "W90_"
}
export enum ClientMentalAbility {
  HEALTHY = "HEALTHY",
  MENTAL_DISORDER = "MENTAL_DISORDER",
  PSYCHOLOGICAL_TRAUMA = "PSYCHOLOGICAL_TRAUMA",
  LIMITED_INTELLIGENCE = "LIMITED_INTELLIGENCE"
}
export enum ClientPhysicalActivity {
  INDEPENDENT_IN_MOVEMENT = "INDEPENDENT_IN_MOVEMENT",
  PROBLEM_WITH_MOBILITY = "PROBLEM_WITH_MOBILITY",
  RESTRICTED_IN_MOVEMENT_DUE_TO_INJURY = "RESTRICTED_IN_MOVEMENT_DUE_TO_INJURY",
  RESTRICTED_IN_MOVEMENT_DUE_TO_EXCESS_WEIGHT = "RESTRICTED_IN_MOVEMENT_DUE_TO_EXCESS_WEIGHT",
  DISORDER_OF_THE_MUSCULOSKELETAL_SYSTEM = "DISORDER_OF_THE_MUSCULOSKELETAL_SYSTEM",
  MOVING_ON_A_MANUAL_CHAIR = "MOVING_ON_A_MANUAL_CHAIR",
  BEDRIDDEN = "BEDRIDDEN"
}

export enum ClientDisease {
  ALCOHOLISM = "ALCOHOLISM",
  ALZHEIMER = "ALZHEIMER",
  AUTISM = "AUTISM",
  APHASIA = "APHASIA",
  BIPOLARITY = "BIPOLARITY",
  PARKINSONS_DISEASE = "PARKINSONS_DISEASE",
  HIV_INFECTED = "HIV_INFECTED",
  HEMOPHILIA = "HEMOPHILIA",
  CELIAC_DISEASE = "CELIAC_DISEASE",
  DEMENTIA = "DEMENTIA",
  DEPRESSION = "DEPRESSION",
  DYSORPHOGRAPHY = "DYSORPHOGRAPHY",
  DISGRAPHIA = "DISGRAPHIA",
  DYSCALCULIA = "DYSCALCULIA",
  DYSLEXIA = "DYSLEXIA",
  DYSPRAXIA = "DYSPRAXIA",
  BOWEL_DISEASE = "BOWEL_DISEASE",
  STROKE = "STROKE",
  HEART_ATTACK = "HEART_ATTACK",
  LANES_DISEASE = "LANES_DISEASE",
  CYSTIC_FIBROSIS = "CYSTIC_FIBROSIS",
  MUSCULAR_DYSTROPHY = "MUSCULAR_DYSTROPHY",
  DRUG_ADDICTION = "DRUG_ADDICTION",
  VISUAL_IMPAIRMENT = "VISUAL_IMPAIRMENT",
  HEARING_IMPAIRMENT = "HEARING_IMPAIRMENT",
  DUMB = "DUMB",
  ONCOLOGY = "ONCOLOGY",
  INDIGESTION = "INDIGESTION",
  RHEUMATISM = "RHEUMATISM",
  DIABETES_MELLITUS = "DIABETES_MELLITUS",
  ATTENTION_DEFICIT_DISORDER = "ATTENTION_DEFICIT_DISORDER",
  BLINDNESS = "BLINDNESS",
  HEART_DISEASE = "HEART_DISEASE",
  PRADER_WILLI_SYNDROME = "PRADER_WILLI_SYNDROME",
  TOURETTES_SYNDROME = "TOURETTES_SYNDROME",
  DOWN_SYNDROME = "DOWN_SYNDROME",
  PHENYLKETONURIA = "PHENYLKETONURIA",
  CEREBRAL_PALSY = "CEREBRAL_PALSY",
  EPILEPSY = "EPILEPSY"
}

export enum Language {
  ENG = "ENG",
  EST = "EST",
  RUS = "RUS",
  ASE = "ASE"
}

export enum Currency {
  EUR = "EUR"
}

export enum Sex {
  Unset = "Unset",
  Male = "Male",
  Female = "Female"
}

export enum RateType {
  HOURLY_PAYMENT = "HOURLY_PAYMENT",
  FIXED_PRICE = "FIXED_PRICE"
}

export enum OrderDirection {
  ASC = "asc",
  DESC = "desc"
}

export enum LockState {
  NotLocked = "NOT_LOCKED",
  LockedByYou = "LOCKED_BY_YOU",
  AlreadyLockedByYou = "ALREADY_LOCKED_BY_YOU",
  AlreadyLockedByOther = "ALREADY_LOCKED_BY_OTHER",
}

export enum DealStatus {
  New = "NEW",
  UnderDiscussion = "UNDER_DISCUSSION",
  Declined = "DECLINED",
  Canceled = "CANCELED",
  DealConcluded = "DEAL_CONCLUDED",
  Paid = "PAID",
  Completed = "COMPLETED",
  Confirmed = "CONFIRMED",
  PaymentStarted = "PAYMENT_STARTED",
  PaymentFailed = "PAYMENT_FAILED",
  PaidToProvider = "PAID_TO_PROVIDER"
}

export enum ActorRole {
  Requester = "REQUESTER",
  Provider = "PROVIDER"
}

export enum DealType {
  Proposal = "proposal",
  Request = "request"
}

export enum Months {
  January = "0",
  February = "1",
  March = "2",
  April = "3",
  Mai = "4",
  June = "5",
  July = "6",
  August = "7",
  September = "8",
  October = "9",
  November = "10",
  December = "11"
}

export enum Qualification {
  STUDENT = "STUDENT",
  MASTER = "MASTER",
  EXPERT = "EXPERT",
  UNKNOWN = "UNKNOWN"
}


export enum WeekDays {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday"
}


export enum SortFields {
  PRICE = "price"
}

export enum Temperature {
  celsius = 'C',
  fahrenheit = 'F'
}

export enum Unit {
  mg = 'mg',
  ml = 'ml'
}

export interface PriceAndTaxes {
  servicePrice: Price;
  tax: Price;
  total?: Price;
  paymentFee?: Price;
  platformFee?: Price;
  calculatePrice?: boolean;
}

export interface Page {
  current: number;
  size: number;
}

export class ArrayPage implements Page {
  public current: number;
  public size: number;

  public constructor(current: number, size: number) {
    this.current = current
    this.size = size
  }

  public paginate<T>(list: T[]): T[] {
    return list.slice((this.current - 1) * this.size, this.current * this.size)
  }
}

export interface SortOrder<T> {
  columnName: T;
  orderDirection: OrderDirection;
}

export type FilterValue = string[] | Language[] | string | number | boolean | undefined
export type FiltersMap<FilterFieldType> = Map<FilterFieldType, FilterValue>

export function buildFilters<FilterFieldType>(entries?: readonly (readonly [FilterFieldType, FilterValue])[]): FiltersMap<FilterFieldType> {
  return new Map<FilterFieldType, FilterValue>(entries)
}

export interface FetchCriteria<SortFieldType, FilterFieldType> {
  order?: SortOrder<SortFieldType>;
  filters?: FiltersMap<FilterFieldType>;
  pagination?: Page;
}

export interface AuthInfo {
  userId: UserId;
  displayName?: Name;
  profileCompleted?: boolean;
}

export interface AuthChallenge {
  type: 'SmartId' | 'MobileId';
  verificationCode: string;
  pinNumber: 'PIN1' | 'PIN2';
}

export interface AuthChallengeStep {
  callback: string;
  challenge: AuthChallenge;
}

export interface ConfirmationStep {
  callback: string;
}

export type AuthResponse = ConfirmationStep | AuthChallengeStep | AuthInfo

export interface Name {
  first: string;
  last: string;
  middle?: string;
}

export type NameForm = Partial<Name>

export type NameValidation = ValidationFieldSet<Name>

export interface AddressFromOpenMaps {
  city: string;
  city_district: string;
  country: string;
  country_code: string;
  county: string;
  municipality: string;
  postcode: string;
  quarter: string;
  road: string;
}
export interface LocationFromResponse {
  latitude: number;
  longitude: number;
  address: AddressFromOpenMaps;
}

export interface MapCoordinates {
  latitude: number;
  longitude: number;
}

export function parseQueryParamCoordinates(queryParams: Dictionary<string | (string | null)[]>): MapCoordinates | undefined {
  if (queryParams.longitude !== undefined && typeof queryParams.longitude === 'string'
      && queryParams.latitude !== undefined && typeof queryParams.latitude === 'string') {
    try {
      const result = {
        latitude: Number(queryParams.latitude),
        longitude: Number(queryParams.longitude)
      }
      if (!isNaN(result.longitude) && !isNaN(result.latitude)) {
        return result
      }
    } catch(e) {
      console.error("Unable to parse user's location", e)
    }
  }
  return undefined
}

export interface Address {
  country: string;
  cityOrCounty: string;
  zipCode: string;
  address: string;
}

export interface AddressForm extends Partial<Address> {
  country: string;
}

export type AddressValidation = ValidationFieldSet<AddressForm>

export interface GeoLocation {
  address: Address;
  coordinates: MapCoordinates;
}

export interface GeoLocationForm extends RecursivePartial<GeoLocation> {
  address: AddressForm;
  coordinates?: MapCoordinates;
}

export interface GeoLocationValidation extends ValidationObject<GeoLocationForm> {
  address: AddressValidation;
  coordinates: ValidationRuleSet;
}

export interface Price {
  amount: string;
  currency: Currency;
}

export type PriceForm = Partial<Price>

export interface Rate {
  type: RateType;
  price: Price;
}

export type PriceValidation = ValidationFieldSet<Price>

export interface RateForm extends RecursivePartial<Rate> {
  price: PriceForm;
}

export interface RateValidation extends ValidationObject<Rate> {
  price: PriceValidation;
}

export interface FileData extends Blob {
  id: string;
  fileName: string;
  public: boolean;
}

export interface FilePublishStatePayload {
  fileId: string;
  visibility: string;
}

export interface RecurrenceRule {
  repeatType?: RepeatOptions;
  repeatUntil?: Date;
  repeatCount?: string;
}

export interface InitialTaskData {
  id?: string;
  start: string;
  end: string;
  dealStatus?: string;
  requesterUserId?: string;
  requesterUserName?: string;
  providerUserId?: string;
  providerUserName?: string;
  dealType?: string;
  dealId?: string;
  dealName?: string;
  instructions: string;
  recurrenceRule: RecurrenceRule;
  occurrences?: ServerOccurrence[];
  reminder: string;
  serviceCategories?: string[];
}

export interface PricesTaxes {
  servicePrice: Price;
  calculatePrice?: boolean;
  tax: Price;
  platformFee?: Price;
  paymentFee?: Price;
  total?: Price;
}

export interface PricesTaxesTasks {
  servicePrice: Price;
  calculatePrice?: boolean;
  tax: Price;
  platformFee?: Price;
  paymentFee?: Price;
  total?: Price;
  tasks: InitialTaskData[];
}

export interface Provider extends AuthorService {
  userId: string;
}
export interface Rating {
  grade: string;
  ratingsCount: number;
}
export interface GetRatingPayload {
  userId: string;
  role: string;
  category?: string;
}

export interface SendRequestRatingPayload {
  requestId: string;
  rating: string;
}

export interface SendProposalRatingPayload {
  proposalId: string;
  rating: string;
}

export interface AuthorService {
  name: Name;
  verified: boolean;
  email: string;
  phoneNumber: string;
}

export type ChangesToAccept = string[]

export interface BankLink {
  country: string;
  logo_url: string;
  name: string;
  url: string;
}

export interface PaymentMethods {
  bankLinks: BankLink[];
}

interface RouteName {
  name: string;
}

export interface NavPath {
  text: string;
  to: RouteName;
}

export interface UserTurnoverPayload {
  userId: UserId;
  year: string;
  month: string;
}

export interface Income {
  count: number;
  paid: Price;
  taxes: Price;
  netIncome: Price;
}

export interface Expenses {
  count: number;
  paid: Price;
  taxes: Price;
  netExpenses: Price;
}

export interface Turnover {
  income: Income;
  expenses: Expenses;
}

export interface UsersTasksPayload {
  userId: string;
  weekStartDate: DateNoTime;
}

export interface RequestPaymentPayload {
  requestId: string;
  paymentTermsAccepted: boolean;
}

export interface ProposalPaymentPayload {
  proposalId: string;
  paymentTermsAccepted: boolean;
}

export interface BusyTimePayload {
  providerId: string;
  startDate: string;
}

export interface ServiceProviderQualificationPayload {
  userId: string;
  category: string;
}

export interface Medications {
  name: string;
  amount: string;
  unit: Unit;
}

export interface DiaryData {
  time?: string;
  id: string;
  heartRate: number;
  bodyTemperature: string;
  bloodPressure: string;
  bloodSugarLevel: string;
  saturation: number;
  urineVolume: number;
  medications: string[] | Medications[];
  notes: string;
  collectedAt: string;
}

export type DiaryDataForm = Partial<DiaryData>

export interface NurseDiaryReportPayload {
  dealId: string;
  diary: DiaryDataForm;
}

export interface DatePickerInterface extends HTMLInputElement, Vue {
  isOpen: boolean;
  showCalendar(): void;
  selectedDate: Date | null;
}
