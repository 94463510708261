import {Component} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin";
import TitledSection from "@/components/layout/TitledSection"
import Button from "@/components/form/Button"
import Feedback from "@/components/layout/Feedback"

import * as DemandsRoutes from "@/_modules/demand/router/routes"
import * as ServiceRoutes from "@/_modules/service/router/routes"
import * as MainRoutes from "@/router/routes"

import {authStore} from "@/store";
import {profileStore} from "@/_modules/profile/store/profile";
import HomePageCard from "@/components/layout/HomePageCard";
import SelectServiceForm from "@/components/SelectServiceForm"
import FullscreenYoutubeVideo from "@/components/layout/FullscreenYoutubeVideo"
import SelectServiceCards from "@/components/SelectServiceCards";
import AppIconChevronDown from "@/components/icons/AppIconChevronDown";
import { toggleFondLogoVisibility } from "@/utils/fond-logo";
import {introVideoId} from "@/utils/video-links";


@Component({name: "Home"})
export default class Home extends BackendClientMixin {

  public email: string = ''
  public renderFeedBack: boolean = false
  private searchRoute: MainRoutes.RouteItem = ServiceRoutes.ServiceFind

  public async mounted(): Promise<void> {
    const userId = authStore.authInfo?.userId
    if (userId !== undefined) {
      await this.withRequest(profileStore.getSettings()).then(resp => {
        this.email = resp.profile.email
      })
    }

    await this.$nextTick()

    this.renderFeedBack = true

    const initialEntryPoint = this.$route.params.initialEntryPoint
    if (initialEntryPoint) {
      await this.$router.push(initialEntryPoint)
    }

    window.addEventListener('scroll', toggleFondLogoVisibility)

    document.getElementById('app')!.scrollIntoView(true);
  }

  public beforeDestroy(): void {
    window.removeEventListener('scroll', toggleFondLogoVisibility)
  }

  private scrollToContent(event: MouseEvent): void {
    event.preventDefault()
    document.getElementById('intro')!.scrollIntoView({behavior: "smooth", block: "start"})
  }

  public render(): VNode {
    return (
      <main class="bg-white pb-0">
        <header class="hero home bottom-arch">
          <b-container fluid="xl" class="pb-0">
            <b-row>
              <b-col cols="12" md="11" lg="10" xl="8" class="pt-8 pt-md-12">
                <h1 class="display-1">{this.translation('title_home')}</h1>
                <p class="lead mb-6">{this.translation('home_lead')}</p>
              </b-col>
              <b-col cols="12" sm="8" md="12" lg="10" xl="8">
                <SelectServiceForm v-model={this.searchRoute} onSetRoute={(route: MainRoutes.RouteItem) => {this.searchRoute = route} } />
              </b-col>
              <b-col cols="12" class="mt-6 pb-14">
                <a class="btn-scroll-content" href="#intro" onClick={(event: MouseEvent) => this.scrollToContent(event)}>
                  <div class="d-inline-flex scroll-btn mr-3">
                    <AppIconChevronDown aria-hidden="true" />
                  </div>
                  {this.translation('btn_scroll_to_content')}
                </a>
              </b-col>
            </b-row>
          </b-container>
        </header>
        <section id="intro" class="mt-6 mt-md-0">
          <b-link to={MainRoutes.InvestorInfo.path} class="fond-logo" id="fond-logo">
            <b-img src={require('../assets/images/Home/fond_logo.svg')}/>
          </b-link>
          <b-container fluid="xl">
            <TitledSection
              title={this.translation("title_home_body_first_block")}
              subtitle={this.translation("text_home_body_first_block_description")}
              content={() => {
                return (
                  <div class="position-relative pb-3 mb-3 mb-md-0 pb-md-6">
                    <div class="embed-responsive embed-responsive-4by3 rounded">
                      <b-img class="embed-responsive-item object-cover" src={require('../assets/images/Home/feature1.jpg')} alt=""/>
                    </div>
                    <FullscreenYoutubeVideo videoId={introVideoId()} />
                  </div>
                )
              }}
            />
          </b-container>
        </section>
        <section class="py-8 py-md-12">
          <b-container fluid="xl" class="mb-lg-10">
            <b-row>
              <b-col cols="12" class="text-center">
                <h2 class="display-2">
                  {this.translation('title_home_body_steps_block')}
                </h2>
                <p class="lead">
                  {this.translation('home_body_steps_block_description')}
                </p>
              </b-col>
              <b-col md="4" class="mt-4 mb-2 mt-md-12">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('@/assets/images/icons/home_step_01.svg')}/>
                  }}
                  title={'home_body_steps_block_step1'}
                  vertical
                />
              </b-col>
              <b-col md="4" class="mt-4 mb-2 mt-md-12">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('@/assets/images/icons/home_step_02.svg')}/>
                  }}
                  title={'home_body_steps_block_step2'}
                  vertical
                />
              </b-col>
              <b-col md="4" class="mt-4 mb-2 mt-md-12">
                <HomePageCard
                  icon={() => {
                    return <b-img alt="" aria-hidden="true" src={require('@/assets/images/icons/home_step_03.svg')}/>
                  }}
                  title={'home_body_steps_block_step3'}
                  vertical
                />
              </b-col>
            </b-row>
          </b-container>
        </section>
        <section>
          <b-container fluid="xl">
            <TitledSection
              reversed
              titleContent={() => {
                return (
                  <div>
                    <p class="h6 m-0 text-muted">{this.translation("subtitle_home_body_second_block")}</p>
                    <h2 class="mt-0">{this.translation("title_home_body_second_block")}</h2>
                    <p>{this.translation("text_home_body_second_block_description")}</p>
                    <div class="d-flex flex-column flex-sm-row mt-4">
                      <Button class="mr-sm-2" to={ServiceRoutes.ServiceFind.path} variant="primary"
                        label={this.translation("btn_become_requester")}/>
                      <Button class="mt-2 mt-sm-0" variant="outline-primary" to={MainRoutes.Customers.path}
                        label={this.translation('btn_know_more')}/>
                    </div>
                  </div>
                )
              }}
              content={() => {
                return (
                  <div class="embed-responsive embed-responsive-4by3 rounded mb-6 mb-md-0">
                    <b-img class="embed-responsive-item object-cover" src={require('@/assets/images/Home/feature2.jpg')} alt="" />
                  </div>
                )
              }}
            />
          </b-container>
        </section>
        <section>
          <b-container fluid="xl">
            <TitledSection
              titleContent={() => {
                return (
                  <div>
                    <p class="h6 m-0 text-muted">{this.translation("subtitle_home_body_third_block")}</p>
                    <h2 class="mt-0">{this.translation("title_home_body_third_block")}</h2>
                    <p>{this.translation("text_home_body_third_block_description")}</p>
                    <div class="d-flex flex-column flex-sm-row mt-4">
                      <Button class="mr-sm-2" variant="primary" to={DemandsRoutes.DemandFind.path}
                        label={this.translation("btn_become_provider")}/>
                      <Button class="mt-2 mt-sm-0" variant="outline-primary" to={MainRoutes.Assistants.path}
                        label={this.translation('btn_know_more')}/>
                    </div>
                  </div>
                )
              }}
              content={() => {
                return (
                  <div class="embed-responsive embed-responsive-4by3 rounded mb-6 mb-md-0">
                    <b-img class="embed-responsive-item object-cover" src={require('../assets/images/Home/feature3.jpg')} alt=""/>
                  </div>
                )
              }}
            />
          </b-container>
        </section>
        <section class="py-8 py-md-12">
          <b-container fluid="xl">
            <b-row>
              <b-col cols="12" class="text-center">
                <h2 class="display-2">
                  {this.translation('title_home_fourth_block_cards')}
                </h2>
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pr-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img width="50px" height="50px" src={require('../assets/images/icons/primary_advantage_01.svg')}/>
                  }}
                  title={'title_card_1'}
                  text={'text_card_1_description'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pl-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img width="50px" height="50px" src={require('../assets/images/icons/primary_advantage_02.svg')}/>
                  }}
                  title={'title_card_2'}
                  text={'text_card_2_description'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pr-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img width="50px" height="50px" src={require('../assets/images/icons/primary_advantage_03.svg')}/>
                  }}
                  title={'title_card_3'}
                  text={'text_card_3_description'}
                />
              </b-col>
              <b-col md="6" class="mt-4 mb-2 mt-md-10 pl-lg-10">
                <HomePageCard
                  icon={() => {
                    return <b-img width="50px" height="50px" src={require('../assets/images/icons/primary_advantage_04.svg')}/>
                  }}
                  title={'title_card_4'}
                  text={'text_card_4_description'}
                />
              </b-col>
            </b-row>
          </b-container>
        </section>
        <section class="py-8 py-md-12">
          <b-container fluid="xl" class="mt-lg-10">
            <b-row>
              <b-col cols="12" class="text-center">
                <h2 class="display-2">
                  {this.translation('title_home_search_category_block')}
                </h2>
              </b-col>
              <SelectServiceCards v-model={this.searchRoute} onSetRoute={(route: MainRoutes.RouteItem) => {this.searchRoute = route} } />
            </b-row>
          </b-container>
        </section>
        <section class="py-8 py-md-12">
          <b-container fluid="xl">
            <b-row class="justify-content-center">
              <b-col cols="12" class="text-center">
                <h2>
                  {this.translation('title_home_body_thankyou_block')}
                </h2>
              </b-col>
              <b-col cols="12" class="d-flex flex-wrap justify-content-center align-items-center mt-2 logo-section">
                <a href="https://inkubaator.tallinn.ee" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
                  <b-img width="170" src={require('@/assets/images/Footer/inkubaator.png')}/>
                </a>
                <a href="https://www.eoc.ee" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
                  <b-img width="58" src={require('@/assets/images/Footer/eoc.png')}/>
                </a>
                <a href="https://www.digital-magic.io" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
                  <b-img width="79" src={require('@/assets/images/Footer/digital-magic.png')}/>
                </a>
                <a href="https://walless.com" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
                  <b-img width="200" src={require('@/assets/images/Footer/walless.svg')}/>
                </a>
                <a href="https://startupkotiki.com" target="_blank" class="my-md-4 mx-2 mx-md-6 p-4">
                  <b-img width="78" src={require('@/assets/images/Footer/cats.png')}/>
                </a>
              </b-col>
            </b-row>
          </b-container>
        </section>
        {this.renderFeedBack &&
          <section class="pt-8 pt-md-12">
            <Feedback value={this.email}/>
          </section>
        }
      </main>
    )
  }
}
