import {ChatConnPayload, chatStore} from "@/_modules/message-center/store/chat-store"
import {badgesStore} from "@/store/badges-store";

export class WsHandler {

  public async wsStreamUp(userId: string): Promise<void> {
    await this.wsStreamDown() //ensure to close all streams

    await chatStore.establishMsgsCenterStream(userId)
    await badgesStore.establishBadgesEventsStream(userId)
  }

  public async wsStreamDown(): Promise<void> {

    await chatStore.disconnectChatStream()
    await chatStore.disconnectMsgsCenterStream()
    await badgesStore.disconnectBadgesEventsStream()


    await chatStore.clearChatMessages()
    await chatStore.clearContacts()
    await badgesStore.clearBadgeEvents()
  }

  public async wsStreamSingleChatUp(payload: ChatConnPayload): Promise<void> {
    await this.wsStreamSingleChatDown()
    await chatStore.establishChatConnection(payload)
  }
  public async wsStreamSingleChatDown(): Promise<void> {
    await chatStore.disconnectChatStream()
  }
}

export const webSocketHandler = new WsHandler()
