import {Component, Watch} from "vue-property-decorator"
import {VNode} from "vue"
import FlashMessages from "@/components/layout/FlashMessages"
import ToastMessages from "@/components/layout/ToastMessages"
import {I18nComponent} from "@/components/I18nComponent"

import MainMenu from "@/components/layout/MainMenu"
import Footer from "@/components/layout/Footer"
import ActivityNav from "@/components/layout/ActivityNav"

import {appStore, authStore} from "@/store"
import {LogoutPayload, profileStore} from "@/_modules/profile/store/profile"
import {EventBus} from "@/main"
import {Route} from "vue-router"
import {axiosErrorToApplicationError} from "@/api"
import * as Error from "@/utils/errors"
import {Location} from "vue-router/types/router"
import * as ProfileRoutes from "@/_modules/profile/router/routes"
import {Home} from "@/router/routes"
import router from "@/router"
import {mapStore} from "@/store/map"
import CookiesAttention from "@/components/CookiesAttention"
import {MessagesPayload, ToastMessagesPayload} from "@/mixins/BackendClientMixin"
import {servicesStore} from "@/_modules/service/store/services"
import {configStore} from "@/store/config"
import * as MainRoutes from "@/router/routes"
import {CustomEvents} from "@/constants/ComponentEvents"
import {webSocketHandler} from "@/ws/ws-handler"
import * as ActivityRoutes from "@/_modules/activity/router/routes"
import * as ServiceRoutes from "@/_modules/service/router/routes"
import * as DemandRoutes from "@/_modules/demand/router/routes"
import * as RequestServiceRoutes from "./_modules/request/router/request-service-routes"
import * as ProposalDemandRoutes from "./_modules/proposal/router/proposal-demand-routes"
import {BIconArrowUp, BIconHouseDoorFill} from "bootstrap-vue";
import { isEmpty } from "./constants/DataBoundaries"
import { addFlashMsgResizeHandler, handleFlashMsgResize, removeFlashMsgResizeHandler } from "./utils/flash-msg"

interface PathName {
  name: string;
}

interface Breadcrumb {
  html?: VNode;
  text: string;
  to: PathName;
}

@Component
export default class App extends I18nComponent {
  public messagesPayload: MessagesPayload = {
    errorMessageKey: null,
    warningMessageKey: null,
    successMessageKey: null
  }
  public toastMessagesPayload: ToastMessagesPayload = {
    errorToastMessageKey: null,
    warningToastMessageKey: null,
    successToastMessageKey: null
  }

  public navItems: Breadcrumb[] = []
  public disableBreadCrumbs: boolean = false
  public showActivityMenu: boolean = false
  public mobileOnlyBreadCrumbs: boolean = false
  public adminView: boolean = false
  public showScrollToTop: boolean = false

  @Watch("$route")
  public async onRouteChanges(v: Route): Promise<void> {
    const anyMsg = Object.values(this.messagesPayload).some((it) => {
      return it !== null
    })
    if (anyMsg) {
      this.messagesPayload.errorMessageKey = null
      this.messagesPayload.warningMessageKey= null
      this.messagesPayload.successMessageKey = null
    }

    const anyToastMsg = Object.values(this.toastMessagesPayload).some((it) => {
      return it !== null
    })
    if (anyToastMsg) {
      this.toastMessagesPayload.errorToastMessageKey = null
      this.toastMessagesPayload.warningToastMessageKey = null
      this.toastMessagesPayload.successToastMessageKey = null
    }

    window.scrollTo(0, 0);

    if (v.meta?.secured === true) {
      try {
        await profileStore.touchSession()
      } catch (err) {
        const e = axiosErrorToApplicationError(err)
        if (e instanceof Error.AuthenticationError) {

          const location: Location = {name: ProfileRoutes.Login.name}
          if (e.code !== undefined) {
            location.params = {errorCode: e.code}
          }
          await profileStore.logout(new LogoutPayload(router, location, true))
        }
      }
    }

    if (!(this.$route.name === ServiceRoutes.ServiceFind.name ||
        this.$route.name === ServiceRoutes.ServiceDetails.name ||
        this.$route.name === DemandRoutes.DemandFind.name ||
        this.$route.name === DemandRoutes.DemandDetails.name)) {
      mapStore.clearSearchState()
    }
  }

  public renderFlashMessages(): VNode {
    this.$nextTick(() => {
      if (Object.values(this.messagesPayload).find(it => {return it !== null})) {
        handleFlashMsgResize()
      }
    })

    return (
      <div>
        {Object.values(this.messagesPayload).find(it => {return it !== null}) &&
          <div class="flash-msg" ref="flashMsg">
            <FlashMessages
              cssClass="global-message-popup"
              messages={this.messagesPayload}
              onChangeState={() => {
                EventBus.$emit(CustomEvents.DismissMsg)
              }}
            />
          </div>
        }
        {Object.values(this.messagesPayload).find(it => {return it !== null}) &&
          <div id="flash-msg-offset-compensator" class="flash-msg-offset-compensator"/>
        }
      </div>
    )
  }

  public renderToastMessages(): VNode {
    return (
      <div>
        {Object.values(this.toastMessagesPayload).find(it => {return it !== null}) &&
          <ToastMessages
            messages={this.toastMessagesPayload}
            onChangeState={() => {
              EventBus.$emit(CustomEvents.DismissToast)
            }}
          />
        }
      </div>
    )
  }

  public handleScrollEvent(): void {
    // If user has scrolled down more than viewport height
    const isScrolledDown = window.scrollY > window.innerHeight;

    if ((isScrolledDown) && !this.showScrollToTop) {
      this.showScrollToTop = true;
    } else if (!isScrolledDown && this.showScrollToTop) {
      this.showScrollToTop = false;
    }
  }

  public scrollToTop(event: Event): void {
    event.preventDefault();
    document.getElementById('app')!.scrollIntoView({behavior: "smooth"});
  }

  public renderScrollToTop(): VNode {
    return (
      <div class="scroll-to-top">
        <a class="scroll-btn" href="#" onClick={(event: Event) => this.scrollToTop(event)}>
          <BIconArrowUp />
        </a>
      </div>
    )
  }

  public async created(): Promise<void> {
    EventBus.$on(CustomEvents.FlashMsg, (msgPayload: MessagesPayload) => {
      this.messagesPayload = msgPayload
    });

    EventBus.$on(CustomEvents.ToastMsg, (msgPayload: ToastMessagesPayload) => {
      this.toastMessagesPayload = msgPayload
    });

    EventBus.$on(CustomEvents.DismissMsg, () => {
      this.messagesPayload.errorMessageKey = null
      this.messagesPayload.warningMessageKey= null
      this.messagesPayload.successMessageKey = null
    });

    EventBus.$on(CustomEvents.DismissToast, () => {
      this.toastMessagesPayload.errorToastMessageKey = null
      this.toastMessagesPayload.warningToastMessageKey= null
      this.toastMessagesPayload.successToastMessageKey = null
    });

    window.addEventListener('scroll', this.handleScrollEvent);

    try {
      await appStore.setLocale(appStore.locale)

      if (authStore.authInfo === undefined || authStore.authInfo?.userId === null) {
        await webSocketHandler.wsStreamDown().catch(() => Promise.resolve())
        await profileStore.killSessionIfExist().catch(() => Promise.resolve())
      }
    } catch (e) {
      console.log('on created', e)
      await Promise.resolve()
    }
  }

  public async mounted(): Promise<void> {
    try {
      addFlashMsgResizeHandler();
      await servicesStore.loadServiceTypes().catch(() => [])
      await configStore.getCountries().catch(() => [])
      await webSocketHandler.wsStreamDown().catch(() => Promise.resolve())
      if (authStore.authInfo !== undefined) {
        const userId = authStore.authInfo.userId
        await webSocketHandler.wsStreamUp(userId!).catch(() => Promise.resolve())
      }
      await configStore.getServerConfig().then((resp) => {
        if (typeof resp === 'number') {
          this.$router.push({
            name: MainRoutes.Error.name,
            params: {
              reason: 'msg_internal_server_error_config_is_not_defined'
            }
          })
        }
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      }).catch(() => 500)
    } catch (e) {
      console.log('on mounted', e)
      await Promise.resolve()
    }
  }

  public async updated(): Promise<void> {
    if (!servicesStore.areServiceTypesLoaded) {
      await servicesStore.loadServiceTypes().catch(() => [])
    }
  }

  public async beforeDestroy(): Promise<void> {
    removeFlashMsgResizeHandler();
    window.removeEventListener('scroll', this.handleScrollEvent);

    await webSocketHandler.wsStreamDown()
  }

  private get navPath(): Breadcrumb[] {
    const path = this.$route.path.split('/')
    path.shift()

    const res = path.filter((it) => {
      if (Object.keys(this.$route.params).length > 0) {
        return Object.values(this.$route.params).some((iter) => {
          return it !== iter ? it : undefined
        })
      } else {
        return it
      }
    })

    this.adminView = path.some(it => {
      return Boolean(it === 'admin')
    })

    const enableActivityMenuForViews = [
      ActivityRoutes.Overview.name,
      ActivityRoutes.Services.name,
      ActivityRoutes.Requests.name,
      ActivityRoutes.Proposals.name,
      ActivityRoutes.Demands.name,
      RequestServiceRoutes.FromOverviewRequestProvider.name,
      RequestServiceRoutes.FromOverviewRequestRequester.name,
      RequestServiceRoutes.RequestRequester.name,
      RequestServiceRoutes.RequestProvider.name,
      ProposalDemandRoutes.FromOverviewProposalProvider.name,
      ProposalDemandRoutes.FromOverviewProposalRequester.name,
      ProposalDemandRoutes.ProposalRequester.name,
      ProposalDemandRoutes.ProposalProvider.name
    ];
    const enableBreadcrumbsAndActivityMenuForViews = [
      ServiceRoutes.ServiceNewFromActivity.name,
      ServiceRoutes.ServiceEditFromActivity.name,
      DemandRoutes.DemandNewFromActivity.name,
      DemandRoutes.DemandEditFromActivity.name
    ];
    const minimumPathLengthForBreadcrumb = 2;

    this.mobileOnlyBreadCrumbs = !isEmpty(this.$route.name) && enableBreadcrumbsAndActivityMenuForViews.includes(this.$route.name!);
    this.showActivityMenu = !isEmpty(this.$route.name) && (enableActivityMenuForViews.includes(this.$route.name!) || this.mobileOnlyBreadCrumbs);

    this.disableBreadCrumbs = this.adminView || path.length < minimumPathLengthForBreadcrumb || (this.showActivityMenu && !this.mobileOnlyBreadCrumbs);

    this.navItems = res.map((it) => {
      return ({
        text: this.translation(`route_${it}`),
        to: {name: it}
      })
    })

    this.navItems.unshift({
      html: <BIconHouseDoorFill aria-label={this.translation('route_home')} />,
      text: '',
      to: {name: Home.name}
    })

    return this.navItems
  }

  public renderBreadCrumbs(): VNode {
    const lastItemIndex = this.navPath.length - 1;
    return (
      <b-container fluid="xl" class={{'d-lg-none': this.mobileOnlyBreadCrumbs}}>
        <b-breadcrumb>
          {this.navPath.map((item, index) => {
            return <b-breadcrumb-item to={item.to} active={index === lastItemIndex}>{item.html}{item.text}</b-breadcrumb-item>
          })
          }
        </b-breadcrumb>
      </b-container>
    )
  }

  public render(): VNode {
    return (
      <div id="app">
        {this.renderFlashMessages()}
        {this.renderToastMessages()}
        {!this.adminView && <MainMenu/>}
        {this.showActivityMenu && <ActivityNav/>}
        {this.navPath.length > 1 && !this.disableBreadCrumbs && this.renderBreadCrumbs()}
        <div class="bottom-arch">
          <router-view key={this.$route.name} />
        </div>
        {!this.adminView &&
        <Footer />
        }
        {(!appStore.cookieConsent && !this.adminView) &&
          <CookiesAttention />
        }
        {this.showScrollToTop && this.renderScrollToTop()}
      </div>
    )
  }
}

