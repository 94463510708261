import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {TsxComponent} from "@/components/TsxComponent";

interface Props {
  //size: number;
  icon?: () => VNode;
}

@Component({name: 'SvgIcon'})
export default class SvgIcon extends TsxComponent<Props> implements Props{
  //@Prop({type: Number, required: false, default: 14}) public readonly size!: number
  @Prop({type: Function, required: false, default: undefined}) public readonly icon?: () => VNode

  public render(): VNode {
    return (
      <div>
        {this.icon !== undefined &&
          this.icon()
        }
      </div>
    )
  }
}
