import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";
import ContentPage from "@/components/ContentPage";

/* eslint-disable */
@Component({name: 'PrivacyPolicy'})
export default class extends I18nComponent {
  public render(): VNode {
    return (
      <ContentPage
        title={'Naabrid.ee privaatsuspoliitika'}
      >
        <p>
          Käesolev privaatsuspoliitika selgitab, kuidas MTÜ Istok (registrikood 80552951; aadress Magasini tn 1, Tartu 51005; edaspidi „Naabrid.ee“) töötleb isikuandmeid Vahendusteenuse osutamise käigus. Naabrid.ee on isikuandmete vastutavaks töötlejaks.
        </p>
        <p>
          Käesolevas privaatsuspoliitikas kasutatakse Naabrid.ee üldtingimustes kasutatud mõisteid. Naabrid.ee jaoks on Kasutajate ja teiste isikute, kelle andmetega Naabrid.ee kokku võib puutuda, privaatsus oluline ning Naabrid.ee austab Kasutajate ning teiste isikute privaatsust. Käesolevas privaatsuspoliitikas kirjeldatakse, kuidas Naabrid.ee töötleb isikuandmeid Vahendusteenuse osutamisel ning veebilehe www.naabrid.ee külastamisel.
        </p>
        <p>
          Palume tutvuda privaatsuspoliitikaga hoolikalt. Kuivõrd isikuandmete töötlemine on Vahendusteenuse pakkumise lahutamatuks osaks, siis privaatsuspoliitikas esitatud regulatsiooniga mittenõustumise korral ei ole võimalik teil Naabrid.ee pakutavat Vahendusteenust kasutada.
        </p>
        <h2>
          1. Mõisted
        </h2>
        <p>
          Isikuandmed – igasugune teave tuvastatud või tuvastatava füüsilise isiku kohta.
        </p>
        <p>
          Isikuandmete töötlemine – isikuandmetega tehtav igasugune toiming näiteks kogumine, salvestamine, korrastamine, säilitamine, muutmine ja avalikustamine, juurdepääsu võimaldamine isikuandmetele, päringute teostamine ja väljavõtete tegemine, isikuandmete kasutamine, edastamine, ristkasutamine, ühendamine, sulgemine, kustutamine või hävitamine.
        </p>
        <p>
          Andmesubjekt – füüsiline isik, kelle isikuandmeid Naabrid.ee. Andmesubjektid võivad olla näiteks Kasutajad, veebilehe külastajad või muud isikud, kes Naabrid.ee poole pöörduvad.
        </p>
        <p>
          Vastutav töötleja – isik, kes määrab kindlaks isikuandmete töötlemise eesmärgid ja vahendid ning vastutab eelkõige, et isikuandmete töötlemine toimuks õiguspäraselt ning andmesubjektide õigused oleksid tagatud. Vastutav töötleja annab volitatud töötlejale kohustuslikke juhiseid isikuandmete töötlemiseks ja vastutab selle eest, et volitatud töötleja täidab isikuandmete töötlemise nõudeid. Käesoleva privaatsuspoliitika mõttes on isikuandmete vastutav töötleja Naabrid.ee.
        </p>
        <h2>
          2. Milliseid isikuandmeid Naabrid.ee kogub ja töötleb
        </h2>
        <p>
          Naabrid.ee kogub ja töötleb järgnevaid isikuandmeid:
        </p>
        <ul class="list-styled">
          <li>Isiku kontaktandmed: e-posti aadress, telefoninumber, elukoht;</li>
          <li>Muu Vahendusteenuse osutamiseks vajalikud andmed (sh identifitseerimisandmed): nimi, sünniaeg, sugu, Teenuse osutamise või kasutamise asukoht;</li>
          <li>Vahendusteenuse osutamise käigus kogutud andmed: Platvormil avaldatud aktiivsed ja mitteaktiivsed kuulutused ning neis sisalduvad andmed (mh näiteks Kasutajatele antud hinnangud, isiku foto, info läbitud koolituste ja isiku kvalifikatsiooni kohta, informatsioon Teenuse osutamise tingimuste kohta jms), sõlmitud Lepingud, Teenuse maksumus ja maksetega seotud andmed, Teenuse osutamisega seotud vaidluste andmed;</li>
          <li>Muul viisil Platvormi kasutamise teel saadud andmed: Platvormi vahendusel peetav kirjavahetus, Platvormis tehtud päringud ja otsingud, andmed Vahendusteenuse kasutamise seadme kohta, veebilehe külastuse andmed (inglise keeles Cookie); klienditoe info.</li>
        </ul>
        <p>
          Naabrid.ee kogub isikuandmeid selliselt, et Kasutaja edastab Naabrid.ee-le ise oma isikuandmed ja kogub andmeid ise Kasutaja käitumise ja tegevuse kohta Platvormil.
        </p>
        <h2>
          3. Millistel eesmärkidel ja alustel Naabrid.ee isikuandmeid töötleb
        </h2>
        <p>
          Naabrid.ee töötleb isikuandmeid eelkõige Vahendusteenuse osutamise lepingu täitmiseks ja Vahendusteenuse efektiivseks osutamiseks ning teenuse kvaliteedi tõstmiseks. Kuivõrd isikuandmete töötlemine on Vahendusteenuse osutamise lahutamatuks osaks, on Vahendusteenuse osutamiseks lepingu sõlmimisel Kasutaja andnud isikuandmete töötlemiseks nõusoleku, mis on isikuandmete töötlemise aluseks. Täpsemalt töödeldakse isikuandmeid näiteks järgmistel eesmärkidel:
        </p>
        <ul class="list-styled">
          <li>
            Kasutajaga Vahendusteenuse osutamiseks lepingu sõlmimiseks (mh isiku tuvastamise ning tema teovõime kindlakstegemiseks);
          </li>
          <li>
            Vahedusteenuse osutamiseks sõlmitud lepingu täitmiseks (Kasutajale info edastamiseks võimaliku lepingupartneri ning lepingutingimuste kohta);
          </li>
          <li>
            Kasutajatele usaldusväärse Teenuse pakkumiseks (näiteks Kasutajale antud tagasiside andmed või Kasutaja foto Teenuse osutamisel lepingupoole identifitseerimiseks)
          </li>
          <li>
            Teenuse, Vahendusteenuse ning muude toimingute eest tasu saamiseks ja nõudmiseks;
          </li>
          <li>
            Vahendusteenuse osutamiseks sõlmitud lepingu täitmise tagamiseks, sealhulgas üldtingimuste või õigusaktide rikkumise tuvastamiseks Kasutaja poolt ning selle tõendamiseks;
          </li>
          <li>
            Kasutajate teavitamiseks Vahendusteenuse asjaoludest ning uuendustest;
          </li>
          <li>
            Kasutaja päringutele ja taotlustele vastamiseks;
          </li>
          <li>
            Kasutajatele otseturunduslike reklaampakkumiste tegemiseks ja uudiskirja saatmiseks, kui Kasutaja on selleks nõusoleku andnud;
          </li>
          <li>
            Statistiliste ülevaadete koostamiseks (kasutatakse andmeid anonümiseeritud kujul);
          </li>
          <li>
            Kasutajale huvipakkuva reklaami kuvamiseks;
          </li>
          <li>
            Seadusest tulenevate kohustuste täitmiseks (näiteks kohustus säilitada raamatupidamisdokumente, edastada pädevatele asutustele vastavate seaduslike taotluste alusel Kasutajate isikuandmeid)
          </li>
        </ul>
        <p>
          Naabrid.ee võib isikuandmeid töödelda ka muu Naabrid.ee või kolmanda isiku õigustatud huvi alusel, mida ei ole konkreetselt välja toodud eelnevas loetelus, kui Naabrid.ee või kolmanda isiku õigustatud huvi kaalub üles andmesubjekti õigused. Samuti töötleb Naabrid.ee isikuandmeid juhul, kui selleks on seadusest või muust õigusaktist tulenev õiguslik alus.
        </p>
        <h2>
          4. Isikuandmete edastamine
        </h2>
        <p>
          Naabrid.ee võib teatud juhtudel edastada isikuandmeid volitatud töötlejatele. Sellisteks isikuteks võivad olla eelkõige, kuid mitte ainult:
        </p>
        <ul class="list-styled">
          <li>
            Naabrid.ee koostööpartnerid, kellele andmete edastamine on vajalik Vahendusteenuse osutamiseks (näiteks makselahenduse pakkujad (Maksekeskus AS), serveri- ja pilveteenuse pakkujad, IT-teenuste osutajad, turunduspartnerid jne);
          </li>
          <li>
            Finantsasutused (näiteks arvete tasumiseks);
          </li>
          <li>
            Raamatupidamise, auditite ning õigusabiga seotud isikud;
          </li>
          <li>
            Muud Naabrid.ee volitatud töötlejad.
          </li>
        </ul>
        <p>
          Volitatud töötlejate kasutamisel tagab Naabrid.ee, et kasutatakse ainult selliseid töötlejaid, kes tagavad piisava andmekaitse taseme Euroopa Liidus kehtivate õigusaktide kohaselt. Naabrid.ee on veendunud selliste teenusepakkujate usaldusväärsuses, sõlminud nendega andmetöötluslepingud ning vastutab nende tegevuse eest.
        </p>
        <p>
          Naabrid.ee edastab isikuandmeid kolmandatele isikutele ainult juhul, kui Naabrid.ee on selleks seadusest tulenevalt kohustatud, kui see on vajalik Kasutajaga sõlmitud Vahendusteenuse osutamise lepingu täitmiseks, kui Naabrid.ee-l on selleks õigustatud huvi või kui Kasutaja on andnud selleks oma nõusoleku. Naabrid.ee võib edastada isikuandmeid järgmistele kolmandatele isikutele:
        </p>
        <ul class="list-styled">
          <li>
            Teised Kasutajad, kes kasutavad Vahendusteenust, et tagada Teenuse osutamine (näiteks Teenuse osutajale andmete edastamine Teenuse sisu ja Teenuse saaja kohta);
          </li>
          <li>
            järelevalve-, uurimis- ja korrakaitseasutustele õigusaktides sätestatud alustel;
          </li>
          <li>
            audiitoritele, õigus- ja muudele nõustajatele, kui see on vajalik nende kohustuste täitmiseks Naabrid.ee ees ja tingimusel, et nad hoiavad vastavaid andmeid konfidentsiaalsena;
          </li>
          <li>
            võlgade sissenõudmisega tegelevale isikule, kui Kasutajal on tekkinud Naabrid.ee ees võlgnevused.
          </li>
          <li>
            kolmandale isikule, kellele andmete edastamist andmesubjekt palub.
          </li>
        </ul>
        <p>
          Kasutajad ei tohi töödelda teiste Kasutajate isikuandmeid, välja arvatud ulatuses, mis on vajalik Teenuse osutamiseks. Kasutajad kohustuvad Platvormi vahendusel teatavaks saanud teiste kasutajate isikuandmeid töötlema üksnes kooskõlas käesoleva privaatsuspoliitikaga ning Naabrid.ee üldtingimustega.
        </p>
        <h2>
          5. Isikuandmete säilitamine
        </h2>
        <p>
          Naabrid.ee säilitab Kasutaja isikuandmeid kuni see on vajalik nende kogumise eesmärgi täitmiseks, Naabrid.ee õiguste kaitsmiseks või kuni see on õigusaktide kohaselt nõutud.
        </p>
        <p>
          Üldjuhul säilitatakse andmeid järgnevalt:
        </p>
        <ul class="list-styled">
          <li>
            Perioodil, mil Kasutajal on Platvormil konto ning peale konto kustutamist täiendavalt 3 aastat;
          </li>
          <li>
            Raamatupidamise jaoks vajalikud andmed säilitatakse 7 aastat;
          </li>
          <li>
            Kuriteo, pettuse või valeandmete esitamise kahtluse korral säilitatakse andmeid 10 aastat.
          </li>
          <li>
            Vaidluste korral säilitatakse andmed kuni nõude täitmiseni või aegumistähtaja lõpuni.
          </li>
          <li>
            Cookie’s andmed säilitatakse vastavalt Cookie poliitikale.
          </li>
        </ul>
        <h2>
          6. Õigused seoses isikuandmetega
        </h2>
        <p>
          Naabrid.ee tagab andmesubjektidele kõik kehtivatest õigusaktidest tulenevad õigused. Eelkõige on andmesubjektil järgnevad õigused:
        </p>
        <ul class="list-styled">
          <li>
            Juurdepääs enda isikuandmetele;
          </li>
          <li>
            Nõuda isikuandmete parandamist ja täpsustamist;
          </li>
          <li>
            Nõuda isikuandmete kustutamist
          </li>
          <li>
            Nõuda isikuandmete töötlemise piiramist;
          </li>
          <li>
            Nõuda isikuandmete ülekandmist;
          </li>
          <li>
            Esitada vastuväiteid isikuandmete töötlemise suhtes;
          </li>
          <li>
            Esitada kaebus järelevalveasutusele
          </li>
        </ul>
        <p>
          Naabrid.ee vastab andmesubjekti taotlusele hiljemalt 1 kuu jooksul, milles toob välja, kas ja milliseid meetmeid on taotluse osas rakendatud. Taotluse keerulisuse või mahukuse korral võib Naabrid.ee vastavat tähtaega pikendada kuni 2 kuu võrra.
        </p>
        <p>
          Nimetatud õigused ei ole piiramatud. Teiste andmesubjektide õigused, Naabrid.ee õigustatud huvid ning seadusest või muudest õigusaktidest tulenevad kohustused võivad piirata andmesubjekti õigusi.
        </p>
        <h2>
          7. Kontaktandmed
        </h2>
        <p>
          Kõigis küsimustes käesoleva privaatsuspoliitika kohta või taotluste osas võtta ühendust Naabrid.ee e-posti, telefoni või posti teel järgmistel kontaktidel:
        </p>
        <p>
          MTÜ Istok (registrikood 80552951)
        </p>
        <p>
          Aadress: Magasini tn 1, Tartu 51005
        </p>
        <p>
          Telefon: +37255593911
        </p>
        <p>
          E-post: info@naabrid.ee
        </p>
      </ContentPage>
    );
  }
}
/* eslint-enable */
