import {RouteItem, Home} from "@/router/routes"
import * as ServiceRoutes from "@/_modules/service/router/routes"
import * as ActivityRoutes from "@/_modules/activity/router/routes"
import {DealType} from "@/types";

export {Home}

export const RequestServiceDraft: RouteItem = {
  path: `${ServiceRoutes.ServiceDetails.path}/${DealType.Request}`,
  name: DealType.Request + '-draft'
}

export const RequestRequester: RouteItem = {
  path: `${ActivityRoutes.Requests.path}/${DealType.Request}`,
  name: DealType.Request + '-requester'
}

export const RequestProvider: RouteItem = {
  path: `${ActivityRoutes.Services.path}/${DealType.Request}`,
  name: DealType.Request + '-provider'
}

export const FromOverviewRequestRequester: RouteItem = {
  path: `${ActivityRoutes.Overview.path}/${DealType.Request}`,
  name: `from_overview_${DealType.Request}-requester`
}

export const FromOverviewRequestProvider: RouteItem = {
  path: `${ActivityRoutes.Overview.path}/${DealType.Request}`,
  name: `from_overview_${DealType.Request}-provider`
}
