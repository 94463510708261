import {VNode} from "vue"
import {Component, Prop} from "vue-property-decorator"

import {AbstractComponent, AbstractComponentPropsType} from "@/components/form/AbstractComponent"
import {ButtonType} from "@/constants/Elements"
import {BasicEvents} from "@/constants/ComponentEvents";
import random from "@/utils/random";

export enum Target {
  Blank = "_blank",
  Self = "_self",
  Parent = "_parent",
  Top = "_top"
}

interface PropsType extends AbstractComponentPropsType {
  id?: string;
  type?: ButtonType;
  variant?: string;
  to?: string;
  href?: string;
  size?: string;
  pill?: boolean;
  directives?: string;
  onClick?(e: Event): void;
  busy?: boolean;
  style?: string;
  onMousedown?: (e: Event) => void;
  onMouseup?: (e: Event) => void;
  target?: Target;
}

@Component({name: "Button"})
export default class extends AbstractComponent<PropsType> implements PropsType {
  @Prop({type: String, required: false, default: () => random.generateElementId()}) public readonly id!: string
  @Prop({type: String, required: false, default: ButtonType.Button}) public readonly type!: ButtonType
  @Prop(String) public readonly to?: string
  @Prop(String) public readonly href?: string
  @Prop(String) public readonly variant?: string
  @Prop(String) public readonly size?: string
  @Prop({type: Boolean, required: false, default: false}) public readonly pill?: boolean
  @Prop({type: String}) public readonly directives?: string
  @Prop({type: Boolean, required: false, default: false}) public readonly busy?: boolean
  @Prop({type: String, default: Target.Self, required: false}) public readonly target?: Target

  public onClick(e: Event): void {
    this.$emit(BasicEvents.Click, e)
  }

  public onMouseup(e: Event): void {
    this.$emit(BasicEvents.Mouseup, e)
  }

  public onMousedown(e: Event): void {
    this.$emit(BasicEvents.Mousedown, e)
  }
  
  protected get disabledState(): boolean {
    return this.busy || this.disabled
  }

  public render(): VNode {
    return (
      <b-button
        id={this.id}
        v-b-toggle={this.directives}
        variant={this.variant}
        name={this.name}
        href={this.href}
        target={this.target}
        to={this.to}
        disabled={this.disabledState}
        type={this.type}
        pill={this.pill}
        size={this.size}
        onClick={this.onClick}
        onMousedown={this.onMousedown}
        onMouseup={this.onMouseup}
      >
        {this.$slots.label === undefined ? this.label : this.$slots.label}
      </b-button>
    )
  }
}