import { RSocketClient, JsonSerializers } from "rsocket-core"
import RSocketWebSocketClient from "rsocket-websocket-client"
import {ISubscription} from "rsocket-types/ReactiveStreamTypes"
import {ReactiveSocket} from "rsocket-types"
import {configStore} from "@/store/config"
import {authStore} from "@/store"
import {ChatConnPayload, chatStore} from "@/_modules/message-center/store/chat-store";
import {Message} from "@/_modules/request/types";
import {handleWebSocketError, RSocketError} from "@/utils/web-socket-error-handler";

interface Metadata {
  metadata: undefined;
}

const maxRSocketRequestN = 2147483647
let subscription: ISubscription | null = null
let stream: ReactiveSocket<Message | {dealId: string; dealType: string}, Metadata> | null = null
let closedByUser = false

async function createTransport(): Promise<RSocketWebSocketClient> {
  const sc = await configStore.getServerConfig()
  if (typeof sc === 'number') {
    throw new Error('Config not loaded. Error code: ' + sc)
  } else {
    return new RSocketWebSocketClient({
      url: `${sc.badgesUri}/api/v1/ws/chat`
    })
  }
}

async function createClient(): Promise<RSocketClient<Message, Metadata>> {
  const t = await createTransport()
  return new RSocketClient({
    serializers: JsonSerializers,
    setup: {
      keepAlive: 60000,
      lifetime: 180000,
      dataMimeType: "application/json",
      metadataMimeType: "application/json"
    },
    transport: t as RSocketWebSocketClient
  })
}

async function retrieveConnection(payload: ChatConnPayload): Promise<void> {
  if (payload) {
    try {
      const client = await createClient()
      stream = await client.connect()

      await stream!.requestStream({
        data: {
          dealId: payload.dealId,
          dealType: payload.dealType.toUpperCase()
        },
        metadata: undefined
      })
        .subscribe({
          onComplete() {
            console.log('complete')
          },
          onError(error) {
            //console.log(`%cChat ${error.message}`, "color:purple;")

            handleWebSocketError(error as RSocketError)
            if (authStore.authenticated && !closedByUser) {
              retrieveConnection(payload)
            }
          },
          onNext(incomingPayload) {
            chatStore.addMessage(incomingPayload.data as Message)
          },
          onSubscribe(_subscription) {
            //console.log(`%cChat rSocket: connected`, "color:purple;")
            _subscription.request(maxRSocketRequestN)
            subscription = _subscription
          }
        })
    } catch (e) {
      setTimeout(() => {
        //console.log('%crSocket Chat connection lost. Attempt to restore...', "color:blue")
        retrieveConnection(payload)
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      }, 10000)
    }
  }
}

export default class WsChat {

  public streamStop(): void {
    closedByUser = true
    // eslint-disable-next-line no-unused-expressions
    stream?.close()
    // eslint-disable-next-line no-unused-expressions
    subscription?.cancel()
    subscription = null
  }

  public async streamStart(payload: ChatConnPayload): Promise<void> {
    closedByUser = false
    await retrieveConnection(payload)
  }
}
