import {Validation} from "vuelidate"
import {Boundary, BoundaryType, NumericBoundary, PredefinedPattern, StringBoundary, StringPredefinedBoundary, StringRegexBoundary} from "@/constants/DataBoundaries"
import {alpha, alphaNum, maxLength, maxValue, minLength, minValue, numeric, helpers} from "vuelidate/lib/validators"

export const validationDisabled: ValidationRuleSet = {}
// Validation interfaces

export type ValidationRule = (...params: string[]) => boolean

export type ValidationRuleFunction = () => ValidationRule

// Applies to exact field

export interface ValidationRuleSet {
  [key: string]: ValidationRule | ValidationRuleFunction;
}

// Validation field set is required to build validation object type from data model

export type ValidationFieldSet<T> = Record<keyof T, ValidationRuleSet>

export interface ValidationIterFieldSet<T> {
  $each: ValidationFieldSet<T>;
}

// Validation Object is used for custom structure where developer will specify each field
export type ValidationObject<T> = Record<keyof T, ValidationRuleSet | ValidationFieldSet<unknown>>

export interface ValidationIterObject<T> {
  $each: ValidationObject<T>;
}

export type ValidationContent<T> = ValidationRuleSet | ValidationFieldSet<T> | ValidationIterFieldSet<T> | ValidationObject<T> | ValidationIterObject<T>

// Validation instance is an interface that component's validator is returned
export type ValidationInstance<T = ValidationContent<unknown>> = T extends object ? { [K in keyof T]: ValidationInstance<T[K]> & Validation }: T

export type ValidationIter<T> = ValidationIterObject<T> | ValidationIterFieldSet<T>

export interface ValidationInstanceIter<T> {
  $each: ValidationInstance<T>[];
}

// TODO: This must be reworked in future - too hacky solution
export function castIter<T, V = ValidationInstance<ValidationIter<T>>>(obj: V): ValidationInstanceIter<T> {
  return obj as unknown as ValidationInstanceIter<T>
}

// TODO: This must be reworked in future - too hacky solution
export function inferValidationInstance<T = ValidationContent<unknown>>(obj: Validation): ValidationInstance<T> {
  return obj as unknown as ValidationInstance<T>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ValidationRuleParam = string | ((vm: any, parentVm?: Vue) => any)

export function validationMessage(v: Validation, errorMapper: Map<string, string>): string | undefined {
  if (!v.$anyError) {
    return undefined
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj = v as any
  // eslint-disable-next-line no-undef-init
  let resp: string | undefined = undefined
  for (const [key, value] of errorMapper) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === false) {
      resp = value
      break
    }
  }
  return resp
}

export function buildValidationRules(boundary: Boundary): ValidationRuleSet {
  if (boundary.type === BoundaryType.NUMERIC) {
    const numericBoundary = boundary as NumericBoundary

    const result: ValidationRuleSet = {
      minValue: minValue(numericBoundary.minValue),
      maxValue: maxValue(numericBoundary.maxValue)
    }
    if (numericBoundary.integer) {
      Object.assign(result, numeric)
    }
    return result
  } else {
    const stringBoundary = boundary as StringBoundary

    const result: ValidationRuleSet = {
      minLength: minLength(stringBoundary.minLength),
      maxLength: maxLength(stringBoundary.maxLength)
    }
    if (boundary.type === BoundaryType.STRING_PREDEFINED_PATTERN) {
      const patternBoundary = boundary as StringPredefinedBoundary
      switch (patternBoundary.pattern) {
        case PredefinedPattern.ALPHA:
          Object.assign(result, alpha)
          break
        case PredefinedPattern.ALPHANUMERIC:
          Object.assign(result, alphaNum)
          break
        case PredefinedPattern.NUMERIC:
          Object.assign(result, numeric)
          break
        default:
          break
      }
    } else if (boundary.type === BoundaryType.STRING_REGEX_PATTERN) {
      const regexBoundary = boundary as StringRegexBoundary
      Object.assign(result, {pattern: helpers.regex('pattern', regexBoundary.pattern)})
    }
    return result
  }
}
