import {VNode} from "vue"
import {Component} from "vue-property-decorator"
import {I18nComponent} from "@/components/I18nComponent";

@Component({name: "PageNotFound"})
export default class extends I18nComponent {

  public render(): VNode {
    return (
      <b-container fluid="xl" class="error-404-page mt-6 mt-md-10">
        <b-row class="justify-content-center">
          <b-col cols="12" md="10">
            <b-card body-class="text-center pt-12 pb-8 pt-md-16 pb-md-12 px-lg-16">
              <h1 class="mb-4">{this.translation('title_attention_page_not_found')}</h1>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    )
  }
}