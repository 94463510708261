import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import FormMixin from "@/mixins/FormMixin"

import TextInput from "@/components/form/TextInput"
import TextAreaInput from "@/components/form/TextAreaInput"


import {buildValidationRules, ValidationObject} from "@/utils/vuelidate-extension";
import {FeedbackForm, feedbackStore, FormWithRecaptcha} from "@/store/feedbackStore";
import {Validations} from "vuelidate-property-decorators";
import {required} from "vuelidate/lib/validators";
import DataBoundaries from "@/constants/DataBoundaries";
import Button from "@/components/form/Button";
import {ButtonType} from "@/constants/Elements";
import {BasicErrorHandler} from "@/utils/errorHandler";
import * as Error from "@/utils/errors";
import {ErrorCodes} from "@/constants/APIconstants";

interface FeedbackFormValidation {
  form: ValidationObject<FeedbackForm>;
}

export class FeedbackErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.RecaptchaValidationFailed:
        this.errorMessageKey = "err_recaptcha_validation_failed"
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}
const feedbackErrorHandler = new FeedbackErrorHandler()


@Component({name: 'Feedback'})
export default class extends FormMixin {
  @Prop(String) public readonly value!: string

  public feedbackMessages = 'feedback-messages'
  public form: FeedbackForm = {
    senderEmail: '',
    message: ''
  }

  @Validations()
  public validation(): FeedbackFormValidation {
    return {
      form: {
        senderEmail: {
          required,
          ...buildValidationRules(DataBoundaries.email)
        },
        message: {required}
      }
    }
  }


  public mounted(): void {
    this.form.senderEmail = this.value
  }

  public async onSubmit(e: Event): Promise<void> {
    e.preventDefault()
    if (this.checkValidation(this.$v.form)) {
      const recaptchaAction = 'support'
      await this.$recaptchaLoaded()
      const recaptchaToken = await this.$recaptcha(recaptchaAction)
      const payload: FormWithRecaptcha = {
        senderEmail: this.form.senderEmail,
        message: this.form.message,
        recaptchaAction: recaptchaAction,
        recaptchaToken: recaptchaToken
      }
      await this.withRequest(feedbackStore.sendMsgToSupport(payload), feedbackErrorHandler).then(() => {
        this.successMessageKey = "msg_message_send_to_customer_service"
        this.form.message = ''
        this.resetValidation(this.$v.form!.message)
      })
    }
  }

  public render(): VNode {
    return (
      <div class="feedback-section w-100 py-12 py-md-15 text-white">
        <b-container fluid="xl" class="mt-md-6">
          <b-row>
            <b-col cols="12" md="6" lg="5">
              <h2 class="display-1">{this.translation('title_feedback')}</h2>
              <p class="lead">{this.translation('description_feedback')}</p>
            </b-col>
            <b-col cols="12" md="6" lg="5" class="mt-2">
              <form onsubmit={this.onSubmit}>
                <TextInput
                  placeholder={this.translation("fld_feedback_email")}
                  v-model={this.form.senderEmail}
                  invalid-message={this.buildInvalidMessage(this.$v.form!.senderEmail!)}
                />
                <TextAreaInput
                  id="email"
                  maxLength={1000}
                  placeholder={this.translation("fld_message")}
                  v-model={this.form.message}
                  invalid-message={this.buildInvalidMessage(this.$v.form!.message!)}
                  rows={7}
                  textCounter
                />
                <i18n tag="p" class="small mb-6" path="msg_recaptcha_consent">
                  <a class="text-white" href="https://policies.google.com/privacy">{this.translation('msg_recaptcha_consent_privacy_policy')}</a>
                  <a class="text-white" href="https://policies.google.com/terms">{this.translation('msg_recaptcha_consent_terms_of_service')}</a>
                </i18n>
                <Button type={ButtonType.Submit} variant="light" label={this.translation('btn_send')}/>
              </form>
            </b-col>
          </b-row>
        </b-container>
      </div>
    )
  }
}
