export enum TextInputType {
  Text = "text",
  Tel = "tel",
  Email = "email",
  Password = "password",
  Date = 'date',
  Number = 'number',
}

export enum ButtonType {
  Button = "button",
  Submit = "submit",
  Reset = "reset"
}

export enum AutoCompleteValue {
  On = "on",
  Off = "off"
}


export enum InputComponentType {
  TextInput = "textinput",
  TextAreaInput = "textareainput"
}