export const handleFlashMsgResize = (): void => {
  const flashMsg = document.querySelector('.flash-msg');

  if (flashMsg !== null) {
    document.getElementById('flash-msg-offset-compensator')!.style.height = `${flashMsg.clientHeight}px`;
  }
}

export function addFlashMsgResizeHandler(): void {
  window.addEventListener('resize', handleFlashMsgResize)
}

export function removeFlashMsgResizeHandler(): void {
  window.removeEventListener('resize', handleFlashMsgResize)
}
