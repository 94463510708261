import {Component, Prop} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";

interface Props {
  icon: () => VNode;
  title: string;
  text: string;
  vertical?: boolean;
}

@Component({name: 'HomePageCard'})
export default class extends I18nComponent implements Props {
  @Prop(Function) public readonly icon!: () => VNode
  @Prop(String) public readonly title!: string
  @Prop(String) public readonly text!: string
  @Prop({type: Boolean, required: false, default: false}) public readonly vertical!: boolean

  public render(): VNode {
    return (
      <div class={`feature-card d-flex align-items-start pt-6 pt-lg-12 pl-6 pl-lg-12 ${!this.vertical ? 'flex-column flex-sm-row' : 'flex-md-column'}`}>
        {this.icon()}
        <div class={!this.vertical ? 'mt-4 mt-sm-0 ml-sm-6 ml-md-8' : ' mt-md-4 ml-4 ml-md-0'}>
          <h3 class="mb-2">{this.translation(this.title)}</h3>
          <p class="mb-0">{this.translation(this.text)}</p>
        </div>
      </div>
    )
  }
}
