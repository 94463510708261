import {VNode} from "vue"
import {Component, Prop} from "vue-property-decorator"

import {AbstractInputComponent, AbstractInputPropsType} from "@/components/form/AbstractInputComponent"

import {InputComponentType, TextInputType} from "@/constants/Elements"
import {ValidationInstance} from "@/utils/vuelidate-extension"
import {BasicEvents} from "@/constants/ComponentEvents"
import {Vue} from "vue/types/vue";
import {trimLeadingTrailingSpaces} from "@/constants/DataBoundaries"
import {autocompleteTimeValue} from "@/utils/string-utils";

type ValueType = string | number

interface PropsType extends AbstractInputPropsType<ValueType> {
  type?: TextInputType;
  placeholder?: string;
  autocomplete?: string;
  v?: ValidationInstance<ValueType>;
  customClass?: string;
  maxLength?: number;
  readonly?: boolean;
  plaintext?: boolean;
  trim?: boolean;
  onKeydown?: (e: Event) => void;
  prepend?: () => VNode | VNode[];
  append?: () => VNode | VNode[];
  timeMask?: boolean;
}

@Component({name: "TextInput"})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {

  @Prop([String, Number]) public readonly value?: ValueType
  @Prop({type: String, required: false, default: TextInputType.Text}) public readonly type!: TextInputType
  @Prop(String) public readonly placeholder?: string
  @Prop(Boolean) public readonly readonly?: boolean
  @Prop(Boolean) public readonly plainText?: boolean
  @Prop(String) public readonly autocomplete?: string
  @Prop(Object) public readonly v?: ValidationInstance<ValueType>
  @Prop({type: String, required: false, default: ''}) public readonly customClass!: string
  @Prop({type: Number, required: false, default: undefined}) public readonly maxLength!: number
  @Prop({type: Boolean, required: false, default: true}) public readonly trim?: boolean
  @Prop(Function) public readonly prepend?: () => VNode
  @Prop(Function) public readonly append?: () => VNode
  @Prop({type: Boolean, required: false, default: false}) public readonly timeMask!: boolean

  public isEmpty(): boolean {
    if (this.value === undefined) {
      return true
    } else if (typeof this.value === "string") {
      return this.value.length === 0
    } else {
      return false
    }
  }

  /* eslint-disable */
  public onBlur(e: Event): void {
    if (!this.plaintext) {
      this.focused = false
      const inputComponent = this.$refs.textinput as Vue
      const element = inputComponent.$el as HTMLInputElement
      if (this.trim) {
        if (this.timeMask) {
          switch (element.value.length) {
            case 0:
              element.value = ''
              break
            case 1:
              element.value = `0${element.value}:00`
              break
            case 2:
              element.value = element.value[1] === ":" ? `0${element.value}00` : `${element.value}:00`
              break
            case 3:
              element.value = element.value[2] === ":" ? `${element.value}00` : `0${element.value}0`
              break
            case 4:
              element.value = element.value[1] === ":" ? `0${element.value}` : `${element.value}0`
              break
            case 5:
              break
            default:
              element.value = ''
              break
          }
        }
        this.$emit(BasicEvents.Input, trimLeadingTrailingSpaces(String(element.value)))
      }
      this.$emit(BasicEvents.Blur, e)
    }
  }
  /* eslint-enable */

  public onKeydown(e: Event): void {
    this.$emit(BasicEvents.Keydown, e)
  }

  public onInput(e: Event | string | number | undefined): void {
    this.$emit(BasicEvents.Input, e)
  }

  public get bindClass(): string {
    if (this.prepend !== undefined && this.append !== undefined) {
      return 'input-with-append-prepend'
    } else if (this.prepend !== undefined) {
      return 'input-with-prepend'
    } else if (this.append !== undefined) {
      return 'input-with-append'
    } else {
      return ''
    }
  }

  public formatter(e: string | undefined): string | undefined {
    if (this.timeMask && e?.length) {
      return autocompleteTimeValue(e)
    }
    return e
  }

  public render(): VNode {
    const topDescriptionId = `${this.id}_top_description`;

    return (
      <b-form-group
        label-for={this.id}
        label={this.label}
        description={this.$slots.topDescription === undefined ? this.description : null} // Top description overrides description prop to avoid aria-describedby conflicts
        state={this.state}
        invalid-feedback={this.invalidMessage}
      >
        {this.$slots.label !== undefined &&
          <template slot="label">
            {this.$slots.label}
          </template>
        }
        {this.prepend !== undefined &&
          this.prepend()
        }
        {this.$slots.topDescription !== undefined && 
          <small tabindex="-1" class="form-text text-muted mt-n1 mb-1" id={topDescriptionId}>
            {this.$slots.topDescription}
          </small>
        }
        <b-input-group>
          <b-input
            ref={InputComponentType.TextInput}
            id={this.id}
            name={this.name}
            placeholder={this.placeholder}
            required={this.required}
            disabled={this.disabled}
            plaintext={this.plaintext}
            readonly={this.readonly}
            autocomplete={this.autocomplete}
            type={this.type}
            class={[this.bindClass, this.customClass]}
            value={this.value}
            formatter={this.formatter}
            maxlength={this.maxLength === undefined ? undefined : this.maxLength}
            state={this.state}
            onInput={this.onInput}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeydown={this.onKeydown}
            aria-describedby={this.$slots.topDescription !== undefined ? topDescriptionId : null}
          />
          {this.append !== undefined &&
            <b-input-group-append>
              {this.append()}
            </b-input-group-append>
          }
        </b-input-group>
      </b-form-group>
    )
  }
}
