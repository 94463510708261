import {Component, Prop} from "vue-property-decorator"
import {TsxComponent} from "@/components/TsxComponent"
import {BasicEvents} from "@/constants/ComponentEvents"
import random from "@/utils/random"

export interface AbstractComponentEventHandlers {
  onFocus(e: Event): void;
  onBlur(e: Event): void;
}

export interface AbstractComponentPropsType extends Partial<AbstractComponentEventHandlers> {
  id?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component
export abstract class AbstractComponent<T extends AbstractComponentPropsType> extends TsxComponent<T> implements AbstractComponentPropsType, AbstractComponentEventHandlers {

  @Prop({type: String, required: false, default: () => random.generateElementId()}) public readonly id!: string
  @Prop(String) public readonly name?: string
  @Prop(String) public readonly label?: string
  @Prop(Boolean) public readonly disabled!: boolean

  public onFocus(e: Event): void {
    this.$emit(BasicEvents.Focus, e)
  }

  public onBlur(e: Event): void {
    this.$emit(BasicEvents.Blur, e)
  }

}

