import axios, {AxiosError} from 'axios'

import {BackendUrls, ContentType, HttpStatuses} from "@/constants/APIconstants"
import * as Error from "@/utils/errors"
import HttpStatus from "http-status-codes"

axios.defaults.baseURL = BackendUrls.urlApi
axios.defaults.withCredentials = true
// TODO: Use Header enum values
axios.defaults.headers = {
  'Accept':ContentType.JSON,
  'Content-Type': ContentType.JSON
}

export default axios

export function axiosErrorToApplicationError(error: Error): Error.ApplicationError {
  if (axios.isCancel(error)) {
    return new Error.RequestCancelledError(error.message)
  }
  if (error instanceof Error.ApplicationError) {
    return error
  }
  const axiosError = error as AxiosError
  if (axiosError.response === undefined || axiosError.response === null) {
    if (axiosError.request !== undefined) {
      return new Error.ServerUnavailableError('Failed to get response from the server', error)
    } else {
      return new Error.UnexpectedInternalError('Unexpected application error', error)
    }
  }
  const response = axiosError.response
  switch (response.status) {
    case HttpStatus.BAD_REQUEST:
      return new Error.BadRequestError(response.data.code, 'Bad Request', error)
    case HttpStatus.UNAUTHORIZED:
      return new Error.AuthenticationError(response.statusText, response.data.code, error)
    case HttpStatus.NOT_FOUND:
      return new Error.ResourceNotFoundError(axiosError.request, 'Server resource not found', error)
    case HttpStatuses.BackendError:
      return new Error.BackendError(response.data.code, 'Backend error', error)
    case HttpStatus.INTERNAL_SERVER_ERROR:
      return new Error.UnexpectedServerError('Internal Server Error', error)
    default:
      return new Error.UnexpectedInternalError('Unexpected case. Response text: ' + axiosError.response.statusText)
  }
}