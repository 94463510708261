import { Component} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent"
import {PrivacyPolicy, ProcedureForResolvingClaims, TermsAndConditions} from "@/router/routes";
import ContentPage from "@/components/ContentPage";
import {BackendUrls} from "@/constants/APIconstants";

type TableRec = [string, string]
const generalInfoFields: TableRec[] = [
  ['lbl_about_country', 'msg_about_country'],
  ['lbl_about_city', 'msg_about_city'],
  ['lbl_about_business_name', 'msg_about_business_name'],
  ['lbl_about_established', 'msg_about_established'],
  ['lbl_about_legal_address', 'msg_about_legal_address'],
  ['lbl_about_head_office_address', 'msg_about_head_office_address'],
  ['lbl_about_incubator_office_address', 'msg_about_incubator_office_address'],
  ['lbl_about_register_code', 'msg_about_register_code'],
  ['lbl_about_vat_number', 'msg_about_vat_number'],
  ['lbl_about_project', 'msg_about_project'],
  ['lbl_about_founders_phones', 'msg_about_founders_phones'],
  ['lbl_about_email', 'msg_about_email']
]

@Component({name: "AboutUs"})
export default class extends I18nComponent {

  public render(): VNode {
    return (
      <ContentPage
        title={this.translation('title_about_us')}
      >
        <h2>{this.translation('title_about_us_support')}</h2>
        <a href="mailto:info@naabrid.ee">info@naabrid.ee</a>
        <h2>{this.translation('title_about_us_general')}</h2>
        {generalInfoFields.map(([key, value]) => (
          <b-row>
            <b-col cols="12" md="6">
              <strong>{this.translation(key)}:</strong>
            </b-col>
            <b-col cols="12" md="6">
              {this.translation(value)}
            </b-col>
          </b-row>
        ))}
        <b-row>
          <b-col cols="12" md="6">
            <b-link href="https://ariregister.rik.ee/est/company/80552951/MTÜ-Istok" target="_blank">
              {this.translation("menu_item_board_members")}
            </b-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-link className="text-primary" href={`${BackendUrls.urlApi}${BackendUrls.urlBaseWeb}/static/MTÜ Istok PÕHIKIRI.pdf`} target="_blank">
              {this.translation("menu_item_articles_of_association")}
            </b-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-link className="text-primary" href={`${BackendUrls.urlApi}${BackendUrls.urlBaseWeb}/static/${this.translation('file_link_ethics_code')}`} target="_blank">
              {this.translation("menu_item_ethics_code")}
            </b-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <strong>{this.translation("menu_item_annual_reports")}</strong>
          </b-col>
          <b-col cols="12" md="6">
            <b-link className="text-primary" href={`${BackendUrls.urlApi}${BackendUrls.urlBaseWeb}/static/Aruanne_2020.pdf`} target="_blank">
              {'2020'}
            </b-link>
            ,&nbsp;
            <b-link className="text-primary" href={`${BackendUrls.urlApi}${BackendUrls.urlBaseWeb}/static/Aruanne_2021.pdf`} target="_blank">
              {'2021'}
            </b-link>
          </b-col>
        </b-row>
        <h2>{this.translation('title_about_us_legal')}</h2>
        <b-link to={TermsAndConditions} target="_blank">
          {this.translation("menu_general_terms_and_conditions")}
        </b-link>
        <br/>
        <b-link to={PrivacyPolicy} target="_blank">
          {this.translation("menu_item_privacy_policy")}
        </b-link>
        <br/>
        <b-link to={ProcedureForResolvingClaims} target="_blank">
          {this.translation('menu_item_procedure_for_resolving_claims')}
        </b-link>
      </ContentPage>
    )
  }

}
