import {Module, VuexModule, getModule, Action, Mutation} from "vuex-module-decorators"
import rootStore from "@/store"

import {DealType} from "@/types";

import axios from "@/api"
import HttpStatus from "http-status-codes"
import {BackendUrls} from "@/constants/APIconstants"
import {UnexpectedServerResponseError} from "@/utils/errors"
import WsChat from "@/ws/ws-chat";
import {Message} from "@/_modules/request/types";
import WsMsgCenter from "@/ws/ws-msg-center";
import _ from "lodash";

export interface ChatConnPayload {
  dealId: string;
  dealType: DealType;
  userId: string;
}

export interface MsgsCenterEvent {
  id: string;
  senderName: string;
  senderUserId: string;
  senderVerified: boolean;
  dealTitle: string;
  dealId: string;
  dealType: DealType;
  message: string;
  myRoleInDeal: string;
  lastInBatch?: boolean;
  newEventsAmount?: number; // TEMPORARY
}

export interface Deal {
  dealId: string;
  dealType: DealType;
  dealTitle: string;
  dealLastMessage: string;
  myRoleInDeal: string;
  dealUnreadedMessageCount: number;
}

export interface Contact {
  userName: string;
  userId: string;
  userVerified: boolean;
  deals: Deal[];
  lastInBatch: boolean;
}

@Module({dynamic: true, namespaced: true, name: 'chat-store', store: rootStore})
export class ChatStoreModule extends VuexModule {

  public instanceWsMsgCenter = new WsMsgCenter() //Msgs center stream instance
  public instanceWsChat = new WsChat() //WsChat stream instance

  public messages: Message[] = []
  public bufferForMessages: Message[] = []

  public contacts: Contact[] = []
  public bufferForContacts: Contact[] = []

  public actualAmountOfUnreadedMessages: number = 0

  //Messages center stream handlers
  @Action({rawError: true})
  public async establishMsgsCenterStream(userId: string): Promise<void> {
    await this.instanceWsMsgCenter.streamStart(userId)
  }
  @Action({rawError: true})
  public async disconnectMsgsCenterStream(): Promise<void> {
    await this.instanceWsMsgCenter.streamStop()
  }
  public get getContacts(): Contact[] {
    return this.contacts
  }

  @Mutation
  public handleIncomingFrame(incomingContact: Contact): void {
    this.bufferForContacts.push(incomingContact)
    if (incomingContact.lastInBatch) {
      if (this.contacts.length === 0 || (this.contacts.length > 0 && this.contacts.find((it) => (it.userId === incomingContact.userId)) === undefined)) {
        this.contacts.push(...this.bufferForContacts.map(it => it))
      } else {
        this.contacts.forEach(contact => {
          if (contact.userId === incomingContact.userId) {
            contact.deals = _.uniqBy(incomingContact.deals.concat(contact.deals), 'dealId')
          }
        })
      }

      this.bufferForContacts = []
    }
  }

  @Mutation
  public clearContacts(): void {
    this.contacts = []
  }

  @Action({rawError: true})
  public async setEventAsReaded(payload: {msgId: string; dealType: string}): Promise<void> {
    const resp = await axios.delete(`${BackendUrls.chats}/${payload.msgId}?dealType=${payload.dealType}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatus.NO_CONTENT)
  }

  public get getInitialAmountOfUnreadMessages(): number {
    const totalNewChatEvents = this.contacts.flatMap(contact => {
      return contact.deals.filter(deal => deal.dealUnreadedMessageCount > 0)
    })
    return totalNewChatEvents.length
  }

  @Mutation
  public setEventAsReadedInternal(dealId: string): void {
    this.contacts.forEach(c => {
      c.deals.forEach(d => {
        if (d.dealId === dealId) {
          d.dealUnreadedMessageCount = 0
        }
      })
    })
  }

  //Single chat stream and handlers
  @Action({rawError: true})
  public async establishChatConnection(chatConnPayload: ChatConnPayload): Promise<void> {
    await this.instanceWsChat.streamStart(chatConnPayload)
  }
  @Action({rawError: true})
  public async disconnectChatStream(): Promise<void> {
    await this.instanceWsChat.streamStop()
  }
  public get getChatMessages(): Message[] {
    return this.messages
  }
  @Mutation
  public addMessage(message: Message): void {
    if (!this.messages.find(it => it.id === message.id)) {
      this.bufferForMessages.push(message)
    }

    if (message.lastInBatch) {
      this.messages.push(...this.bufferForMessages.map(it => (it)))
      this.bufferForMessages = []
    }
  }
  @Mutation
  public clearChatMessages(): void {
    this.messages = []
  }
}

export const chatStore = getModule(ChatStoreModule, rootStore)
