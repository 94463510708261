import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconChatUnread'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" class="app-icon" viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.277 23.698c-.355.071-.711.136-1.069.194-.3.048-.528-.264-.41-.543.134-.314.256-.633.366-.956l.005-.015c.372-1.08.675-2.322.786-3.478C1.115 17.055 0 14.64 0 12 0 6.201 5.373 1.5 12 1.5S24 6.201 24 12s-5.373 10.5-12 10.5a13.59 13.59 0 0 1-3.52-.459c-.78.395-2.459 1.113-5.203 1.657Z" fill="currentColor" />
      </svg>
    )
  }
}
