import {RouteItem, Home} from "@/router/routes"

export {Home}

export const AdminPanel: RouteItem = {
  path: "/admin",
  name: "admin"
}

export const Agreements: RouteItem = {
  path: "/admin/agreements",
  name: "agreements"
}

export const UserProfileManagement: RouteItem = {
  path: "/admin/user_profile_management",
  name: "user_profile_management"
}

export const AccountingAndReports: RouteItem = {
  path: "/admin/accounting_and_reports",
  name: "accounting_and_reports"
}

export const AdminMsgCenter: RouteItem = {
  path: "/admin/msg-center",
  name: "admin-msg-center"
}