import {RouteItem, Home, Success, SuccessLogged, Error} from "@/router/routes"

// Re-use global Home route in module
export {Home, Success, SuccessLogged, Error}

export const Auth: RouteItem = {
  path: "/auth",
  name: "Auth"
}

export const Login: RouteItem = {
  path: "/login",
  name: "login"
}

export const Signup: RouteItem = {
  path: "/signup",
  name: "signup"
}

export const Logout: RouteItem = {
  path: "/logout",
  name: "logout"
}

export const ForgotPassword = {
  path: "/forgot_password",
  name: "forgot_password"
}

export const CompleteRegistrationViaEmail = {
  path: "/complete_registration",
  name: "complete_registration"
}

export const EmailVerification = {
  path: "/email_verification",
  name: "email_verification"
}

export const Profile: RouteItem = {
  path: "/profile",
  name: "profile"
}

export const Activity: RouteItem = {
  path: "/activity",
  name: "activity"
}

export const ChangePassword: RouteItem = {
  path: "/change_password",
  name: "change_password"
}

export const SetupPassword: RouteItem = {
  path: "/setup_password",
  name: "setup_password"
}

export const ResetPassword = {
  path: "/reset_password",
  name: "reset_password"
}