import {storeVersion} from "@/constants/ApplicationConfiguration"

const storeVersionKey = 'naabrid_store_version'

function cleanStore(): void {
  localStorage.clear()
  sessionStorage.clear()
  localStorage.setItem(storeVersionKey, storeVersion)
}

export function cleanUpStoreIfRequired(): void {
  const storeContent = localStorage.getItem(storeVersionKey)
  if (storeContent) {
    const browserStoreVersion = JSON.parse(storeContent)
    if (browserStoreVersion !== storeVersion) {
      cleanStore()
    }
  } else {
    cleanStore()
  }
}
