import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";
import ContentPage from "@/components/ContentPage";
import Notice from "@/components/layout/Notice";

/* eslint-disable */
@Component({name: 'PaymentAgreement'})
export default class extends I18nComponent {
  public render(): VNode {
    return (
      <ContentPage
        title={'Veebiplatvormi Naabrid.ee müügitingimused'}
      >
        <template slot="lead">
          <p>
            Kasutajate ja platvormi suhted on üheselt reguleeritud meie müügitingimustega.
          </p>
        </template>
        <template slot="content">
          <p>
            Veebiplatvorm Naabrid (edaspidi Veebiplatvorm) omanik on MTÜ Istok (registrikood 80552951), asukohaga Magasini tn 1, Tartu 51005.
          </p>
          <h2>
            Makseviisid
          </h2>
          <p>
            Eesti pangalingid: Swedbank, SEB, Luminor, LHV, Coop Pank, Pocopay.
          </p>
          <Notice variant="warning">
            <p>
              Pangalingiga tasumisel vajutada kindlasti panga lehel nupule „Tagasi kaupmehe juurde“.
            </p>
          </Notice>
          <p>
            Makseid vahendab&nbsp;
            <b-link href={`https://maksekeskus.ee/`}>Maksekeskus AS</b-link>.
            Tasumine toimub väljaspool Veebiplatvormi turvalises keskkonnas – pangalingiga tasumisel vastava panga turvalises keskkonnas ja krediitkaardiga tasumisel Maksekeskus AS turvalises keskkonnas. Müüjal puudub ligipääs kliendi panga ja krediitkaardi andmetele. Leping jõustub alates tasumisele kuuluva summa laekumisest Veebiplatvormi arvelduskontole.
          </p>
          <p>
            Veebiplatvormi omanik on isikuandmete vastutav töötleja ning edastab maksete teostamiseks vajalikud isikuandmed volitatud töötleja Maksekeskus AS-le.
          </p>
          <h2>
            Pretensiooni esitamine
          </h2>
          <p>
            Pretensiooni saavad esitada Teenuse osutaja ning Teenuse saaja Teenuse osutamise täitmise kinnitamisel või 30 päeva jooksul alates Teenuse osutamise täitmise kinnitamisest. Pretensioon esitatakse Naabrid.ee-le e-posti teel või Platvormil oleva vormi kaudu.
          </p>
          <p>
            Kui pretensioon esitatakse pärast Teenuse osutamise täitmise kinnitamist, peab Kasutaja põhjendama, miks ei olnud pretensiooni võimalik esitada enne Teenuse osutamise täitmise kinnitamist.
          </p>
          <p>
            Pretensioonist peab nähtuma pretensiooni esitaja nimi, kontaktandmed, millise Lepingu suhtes pretensioon esitatakse, pretensiooni sisu (märkida, millega on Kasutaja rahulolematu), millised on Kasutaja argumendid pretensiooni esitamiseks ning millist tulemust pretensiooni esitaja taotleb. Pretensiooni esitaja lisab pretensioonile asjassepuutuvad dokumendid, fotod ning muud tõendid.
          </p>
          <p>
            Pretensiooni kättesaamisest teavitab Naabrid.ee pretensiooni esitajat viivitamata.
          </p>
          <h2>
            Pretensioonile vastamine
          </h2>
          <p>
            Pretensiooni kättesaamisel edastab Naabrid.ee pretensiooni Lepingu teisele poolele ning palub Lepingu teisel poolel pretensioonile vastata hiljemalt 7 päeva jooksul. Juhul, kui Lepingu teine pool tähtaegselt ei vasta, on Naabrid.ee õigustatud lahendama pretensiooni tema kahjuks.
          </p>
          <p>
            Kui pretensioonis esitatud asjaolud on selged ning pretensioonile vastuse nõudmine ei ole asjaolusid arvestades mõistlik, võib Naabrid.ee
          </p>
          <p>
            lahendada pretensiooni ka Lepingu teiselt poolelt pretensioonile vastust nõudmata.
            Pretensiooni vastuses tuleb Lepingu teisel poolel selgitada, kas võtab pretensioonis esitatu omaks või vaidleb sellele vastu.
          </p>
          <p>
            Pretensioonile vastamise korral tuleb vastusele lisada asjassepuutuvad dokumendid, fotod ning muud tõendid.
          </p>
          <h2>
            Pretensiooni lahendamine Naabrid.ee poolt
          </h2>
          <p>
            Naabrid.ee lahendab pretensiooni 7 päeva jooksul alates pretensioonile vastuse saamisest. Kui Naabrid.ee lahendab pretensiooni ilma Lepingu teiselt poolelt vastust nõudmata, lahendab Naabrid.ee pretensiooni 7 päeva jooksul alates pretensiooni saamisest.
          </p>
          <p>
            Pretensiooni lahendamisel tutvub Naabrid.ee pretensiooniga ning selle vastusega ning teeb otsuse pretensiooni kohta.
          </p>
          <p>
            Pretensiooni lahendamise otsusena võib Naabrid.ee jätta tasu Vahendusteenuse eest nõudmata, Teenuse osutajale tasu Teenuse osutamise eest välja maksmata ning tagastada Teenuse saajale, määrata Teenuse osutamise eest välja makstud summa tagastamine või määrata Kasutajale trahv hinnakirjas sätestatud alusel või määras, mis vastab Lepingu sõlmimise tasule Vahendusteenuse osutamise eest. Samuti võib Naabrid.ee pretensiooni lahendamise otsusega rakendada Naabrid.ee üldtingimuste punktis 4.1.1. sätestatud meetmeid.
          </p>
          <p>
            Isik, kelle kahjuks Naabrid.ee pretensiooni lahendas, kaotab õiguse jätta Teenuse osutamise kohta tagasisidet.
          </p>
          <p>
            Kui Kasutajad jõuavad pretensiooni lahendamise käigus kokkuleppele, tuleb Kasutajatel teavitada Naabrid.ee-d kokkuleppe sõlmimisest.
          </p>
          <p>
            Pretensiooni lahendamine Naabrid.ee poolt ei takista Kasutajat pöörduma oma õiguste kaitseks kohtusse või muu kohase organi poole. Kasutajad teadvustavad, et Naabrid.ee poolt pretensioonide lahendamine ei ole vahekohus või muu sarnane organ, kelle otsus oleks
          </p>
          <p>
            Pretensiooni lahendamise järgselt ei vastuta Naabrid.ee ühegi kahju eest, mis võis tekkida Kasutajale pretensiooni lahendamise tõttu. Eelkõige, kuid mitte ainult, ei vastuta Naabrid.ee kahju eest, mis võis tekkida isikule, kelle kahjuks pretensioon Naabrid.ee poolt lahendati, kuid kelle kasuks (või pretensiooni lahendamise otsusest teisiti) otsustas kohus või muu kohane organ, seoses pretensiooni lahendamisega.
          </p>
        </template>
      </ContentPage>
    )
  }
}
/* eslint-enable */
