import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconLanguage'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg class="app-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm9.348 7.355h-3.991c-.436-2.149-1.167-3.978-2.09-5.28a10.497 10.497 0 016.081 5.28zM16.258 12c0 1.108-.077 2.139-.208 3.097h-8.1A22.87 22.87 0 017.742 12c0-1.108.077-2.139.208-3.097h8.1c.13.958.208 1.989.208 3.097zM12 1.548c1.302 0 2.971 2.134 3.779 5.807H8.221C9.029 3.682 10.698 1.548 12 1.548zm-3.266.528c-.92 1.297-1.655 3.126-2.09 5.279H2.652a10.497 10.497 0 016.082-5.28zM1.548 12c0-1.079.165-2.12.47-3.097h4.379a23.67 23.67 0 000 6.194h-4.38A10.386 10.386 0 011.549 12zm1.104 4.645h3.992c.435 2.149 1.166 3.978 2.09 5.28a10.497 10.497 0 01-6.082-5.28zM12 22.452c-1.302 0-2.971-2.134-3.779-5.807h7.558c-.808 3.673-2.477 5.807-3.779 5.807zm3.266-.528c.92-1.297 1.655-3.126 2.09-5.279h3.992a10.498 10.498 0 01-6.082 5.28zm2.337-6.827a23.657 23.657 0 000-6.194h4.38c.304.978.469 2.018.469 3.097 0 1.079-.165 2.12-.47 3.097h-4.379z"/>
      </svg>
    )
  }
}
