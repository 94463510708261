import Vue from "vue"
import VueI18n from "vue-i18n";
import {Validation} from "vuelidate";
import {validationMessage} from "@/utils/vuelidate-extension";

export abstract class I18nComponent extends Vue {

  public translation(key: string, params?: VueI18n.Values): string {
    if (params === undefined) {
      return this.$i18n.t(key) as string
    } else {
      return this.$i18n.t(key, params) as string
    }
  }

  public get fieldErrorsMap(): Map<string, string> {
    return new Map<string, string>([
      ['numeric', this.translation('err_numeric_rule')],
      ['timeInFuture', this.translation('err_time_in_future_incorrect')],
      ['time', this.translation('err_time_incorrect')],
      ['email', this.translation('err_input_field_email')],
      ['decimal', this.translation('err_decimal_value')],
      ['nonNegativeCurrency', this.translation('err_non_negative_currency_value')],
      ['required', this.translation('err_input_field_required')],
      ['requiredIf', this.translation('err_input_field_required')],
      ['pattern', this.translation('err_invalid_value_format')],
      ['iban', this.translation('err_input_field_iban')],
      ['sameAs', this.translation('err_input_field_value_incorrect')],
      ['maxLength', this.translation('err_input_field_max_length')],
      ['minLength', this.translation('err_input_field_min_length')],
      ['minValue', this.translation('err_input_field_min_value')],
      ['maxValue', this.translation('err_input_field_max_value')],
      ['nonCyrillic', this.translation('err_cyrillic_is_forbidden')],
      ['adult', this.translation('err_less_then_eighteen_years_old')],
      ['startDateEarlyEndDate', this.translation('err_start_date_later_end')],
      ['earlyThenRule', this.translation('err_end_date_is_early_then_start')],
      ['integer', this.translation('err_integer_rule')],
      ['internationalPhoneNumber', this.translation('err_international_phone_number')],
      ['personalCode', this.translation('err_personal_code')],
      ['oneDigitAfterDot', this.translation('err_decimal_with_one_digit_after_dot')]
    ])
  }

  public get fieldPasswordErrorsMap(): Map<string, string> {
    const errors = new Map(this.fieldErrorsMap)
    errors.set('sameAs', this.translation('err_input_field_password_mismatch'))
    return errors
  }

  public get fieldCheckboxErrorMap(): Map<string, string> {
    const errors = new Map(this.fieldErrorsMap)
    errors.set('sameAs', this.translation('err_input_field_required'))
    return errors
  }

  public buildInvalidMessage(v: Validation, fieldErrorsMap = this.fieldErrorsMap): string | undefined {
    return validationMessage(v, fieldErrorsMap)
  }
}
