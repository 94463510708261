import {VNode} from "vue";
import {Component} from "vue-property-decorator"

import {BIconArrowRight} from "bootstrap-vue"
import {TsxComponent} from "@/components/TsxComponent";

export interface SuccessPageData {
  title: string | undefined;
  username?: string;
  message: string | undefined;
  buttonBackText?: string;
  buttonBackPath?: string;
}

@Component({name: 'SuccessPage', components: {BIconArrowRight}})
// eslint-disable-next-line @typescript-eslint/ban-types
export default class extends TsxComponent<{}> {

  public successPageData: SuccessPageData = {
    title: undefined,
    username: undefined,
    message: undefined,
    buttonBackText: undefined,
    buttonBackPath: undefined
  }

  public mounted(): void {
    if (this.$route.params !== undefined) {
      this.successPageData.title = this.$route.params.title
      this.successPageData.username = this.$route.params.username
      this.successPageData.message = this.$route.params.message
      this.successPageData.buttonBackText = this.$route.params.buttonText
      this.successPageData.buttonBackPath = this.$route.params.path
    }
  }

  public render(): VNode {
    return (
      <b-container fluid="xl" class="success-page mt-10">
        <b-row class="justify-content-center">
          <b-col cols="12" md="10">
            <b-card body-class="text-center pt-12 pb-8 pt-md-16 pb-md-12 px-lg-16">
              {this.successPageData.title !== undefined &&
              <h1>{this.translation(this.successPageData.title)}</h1>
              }
              {this.successPageData.message !== undefined &&
                <i18n tag="p" path={this.successPageData.message}>
                  <a class="text-primary" href={`mailto:${this.successPageData.username}`}>{this.successPageData.username}</a>
                </i18n>
              }
              {this.successPageData.buttonBackText !== undefined &&
                <div class="mt-8 mt-md-10 mb-4">
                  <b-button
                    to={this.successPageData.buttonBackPath}
                    variant="primary"
                  >
                    {this.translation(this.successPageData.buttonBackText)}
                  </b-button>
                </div>
              }
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    )
  }
}
