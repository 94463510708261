import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";
import ContentPage from "@/components/ContentPage";

/* eslint-disable */
@Component({name: 'TermsAndConditions'})
export default class extends I18nComponent {
  public render(): VNode {
    return (
      <ContentPage
        title={'Naabrid.ee üldtingimused'}
      >
        <p>
          Käesolevad üldtingimused kohalduvad MTÜ Istok (registrikood 80552951; aadress Magasini tn 1, Tartu 51005) (edaspidi „Naabrid.ee“) poolt osutatava teenuse („Vahendusteenus“) kohta. Naabrid.ee pakub infoühiskonna teenust ning ei täida ise Platvormi vahendusel sõlmitud Lepinguid ega osuta Teenuseid. Teenuse osutajad ei ole Naabrid.ee töötajad ning Naabrid.ee ei vastuta mingil viisil Lepingute täitmise eest.
        </p>
        <p>
          Platvormil Kasutajaks registreerudes kinnitab isik, et:
        </p>
        <ol class="list-styled">
          <li>
            on hoolega tutvunud käesolevate üldtingimustega ning nõustub nende järgimisega;
          </li>
          <li>
            füüsilisest isikust Vahendusteenuse kasutaja on vähemalt 18-aastane;
          </li>
          <li>
            äriühingust Vahendusteenuse kasutajana on ta äriühingu seaduslik esindaja või toimib teenuseid kasutades äriühingu esindajana ning tal on õigus teha äriühingu nimel teenuse kasutamiseks vajalikke tahteavaldusi;
          </li>
          <li>
            kogu teave, mis on esitatud Naabrid.ee-le Platvormile registreerudes, on täpne, õige ja täielik.
          </li>
        </ol>
        <p>
          Käesolevatest üldtingimustest kõrvale kalduvad tingimused kohalduvad ainult siis, kui Naabrid.ee ning Kasutaja on selles eraldi kirjalikult kokku leppinud.
        </p>
        <h2>
          1. Mõisted
        </h2>
        <p>
          „Vahendusteenus“ – Naabrid.ee poolt Kasutajatele pakutav teenus ehk Platvormi kasutamise võimaldamine Kasutajatele ning Platvormi haldamine, mille tulemusel saavad Kasutajad suhelda ning lihtsustub Lepingu sõlmimine.
        </p>
        <p>
          „Platvorm“ – koduleheküljel <b-link href={'https://www.naabrid.ee'} target="_blank">www.naabrid.ee</b-link> asuv keskkond, mille kaudu on Teenuse kasutajatel ning Teenuse osutajatel võimalik sõlmida Leping.
        </p>
        <p>
          „Leping“ – Platvormi kaudu Teenuse saaja ning Teenuse osutaja vahel sõlmitud leping Teenuse osutamise kohta.
        </p>
        <p>
          „Teenuse kasutaja“ – Platvormi kaudu teenuse osutamist otsiv isik.
        </p>
        <p>
          „Teenuse osutaja“ – Platvormi kaudu enda teenust pakkuv isik.
        </p>
        <p>
          „Kasutaja“ – Teenuse saaja või Teenuse osutaja, kes on Platvormil registreerunud.
        </p>
        <p>
          „Teenus“ – Teenuse saaja ning Teenuse osutaja poolt kokku lepitud ülesanne, mille täidab Teenuse osutaja.
        </p>
        <h2>
          2. Vahendusteenuse sisu
        </h2>
        <ol class="list-styled">
          <li>
            Leping Vahendusteenuse osutamiseks Naabrid.ee ning Kasutaja vahel sõlmitakse Kasutaja registreerumisega Platvormil.
          </li>
          <li>
            Vahendusteenuse sisuks on võimaldada Teenuse osutajatel leida enda pakutavale teenusele Teenuse saaja ning Teenuse saajatel leida sobiv Teenuse osutaja. Naabrid.ee üksnes lihtsustab Lepingute sõlmimist Teenuse osutaja ja Teenuse saaja vahel.
          </li>
          <li>
            Lepingu sõlmivad Teenuse saaja ning Teenuse osutaja omavahel ning vastutavad selle kohase täitmise eest. Naabrid.ee-l ei tulene Lepingust ühtegi kohustust. Naabrid.ee ei ole Kasutaja agent, maakler, komisjonär või muu Kasutaja huvides tegutsev isik.
          </li>
          <li>
            Naabrid.ee ja Teenuse osutaja vahel ei teki töövõtu-, töö- ega seltsigu õigussuhet ning Teenuse osutajale Naabrid.ee pakutavad võimalikud hüvitised või allahindlused ei ole käsitletavad töötasuna ega tulemustasuna.
          </li>
          <li>
            Naabrid.ee ei vastuta Lepingu täitmise eest ning Kasutajatel ei teki Lepingu täitmisega seoses Naabrid.ee vastu nõudeid. Muuhulgas, kuid mitte ainult, ei vastuta Naabrid.ee:
            <ol class="list-styled">
              <li>
                Platvormi kaudu osutatava Teenuse kvaliteedi eest;
              </li>
              <li>
                Kasutaja poolt Platvormil avaldatava teabe ja informatsiooni õigsuse ega seaduslikkuse eest;
              </li>
              <li>
                Kasutaja poolt teisele Kasutajale Teenuse osutamisega seoses tekitatud kahju eest.
              </li>
            </ol>
          </li>
          <li>
            Leping loetakse Teenuse osutaja ja Teenuse saaja vahel sõlmituks alates Lepingu tingimustes sätestatud tasu ning Naabrid.ee tasude laekumisest Naabrid.ee arvelduskontole.
          </li>
          <li>
            Pärast lepingu sõlmimist kohustuvad Kasutajad seda täitma täies ulatuses ning Lepingus ja selle lisades sätestatud tingimustel. Kui Lepingus ning selle lisas sätestatu on vastuolus, tuleb poolte kohustuste kindlakstegemisel lähtuda Lepingus sätestatust. Lepingu lisadeks on Lepingule lisatud märkused ning Kasutajate vahel Platvormi vahendusel toimuv kirjavahetus, mille on Kasutajad Lepingu kinnitamisel muutnud lepingu osaks, milles täpsustatakse Lepingu täitmise korda.
          </li>
          <li>
            Naabrid.ee ei pea kinni ega maksa Kasutajatele tehtavatelt väljamaksetelt ühtegi maksu. Kasutaja on teadlik, et tal võib tekkida Teenuse osutamisega ning selle eest tasu võtmisega seoses kohustus deklareerida ning tasuda makse. Kui maksuhaldur esitab Naabrid.ee-le maksupäringu Kasutajate tegevuse kohta, võib Naabrid.ee teha maksuhaldurile kättesaadavaks maksuhalduri poolt nõutavat teavet.
          </li>
          <li>
            Teenuse osutaja ei või Lepingu täitmist või Lepingust tulenevat õigust või kohustust loovutada kolmandatele isikutele.
          </li>
          <li>
            Vahendusteenusele ei kohaldu tarbija taganemisõigus. Naabrid.ee poolt osutatav vahendusteenus loetakse täielikult täidetuks hiljemalt Naabrid.ee tasude tasumisega ning selle hilisem tagasitäitmine ei ole võimalik. Lepingust on Kasutajatel võimalik taganeda võlaõigusseaduses sätestatud korras. Kui Naabrid.ee ei ole taganemisest teada saamise hetkeks maksnud välja Teenuse osutajale välja Lepingu tingimustes sätestatud tasu, tagastab Naabrid.ee Teenuse saajale makstud tasu vastavalt pretensioonide lahendamise otsusele.
          </li>
        </ol>
        <h2>
          3. Kasutajate õigused ja kohustused
        </h2>
        <ol class="list-styled">
          <li>
            Kasutajad on kohustatud:
            <ol class="list-styled">
              <li>
                Platvormil Kasutajaks registreerudes esitama Naabrid.ee-le üksnes tõesed isiku- ja kontaktandmed, muuhulgas, kuid mitte ainult, registreeruma oma täpse, õige ja täieliku nime all ja kasutama kehtivat, endale kuuluvat ning regulaarselt kontrollitavat e-posti aadressi;
              </li>
              <li>
                viivitamata teatama mis tahes muudatustest enda kontakt- ja isikuandmetes;
              </li>
              <li>
                mitte kasutama Platvormi pettusteks ja muudeks õigusvastasteks tegevusteks;
              </li>
              <li>
                esitama kuulutuste, fotode ja tekstide näol üksnes õigeid ja korrektseid andmeid;
              </li>
              <li>
                kuulutuste, fotode ja tekstide esitamisel peavad need olema kooskõlas õigusaktidega, heade tavadega ning kommetega;
              </li>
              <li>
                mitte pakkuma ega kutsuda üles pakkuma teenuseid, mille osutamine ei ole kooskõlas õigusaktidega;
              </li>
              <li>
                Täitma Lepingut kohaselt ning vastavalt kvaliteedile, milles Kasutajad on kokku leppinud;
              </li>
              <li>
                hoiduma Platvormil pakutavate või otsitavate Teenuste osas tehingute tegemisest Platvormi väliselt, samuti hoiduma Platvormil peetavas vestluses isiklike kontaktandmete edastamisest enne Lepingu sõlmimist;
              </li>
              <li>
                hoiduma raha tasumisest Teenuse eest Platvormi väliselt;
              </li>
              <li>
                mitte andma oma kontot kasutamiseks ühelegi teisele isikule;
              </li>
              <li>
                mitte rikkuma kolmandate isikute varalisi või mittevaralisi õigusi, sealhulgas, kuid mitte ainult, kolmandatele isikutele kuuluvaid intellektuaalse omandi õigusi;
              </li>
              <li>
                andmete ja asjaolude muutumisel neid koheselt Platvormil muutma või teavitama Naabrid.ee andmete ja asjaolude muutumisest, kui nende muutmine Platvormil ei ole võimalik;
              </li>
              <li>
                mitte avaldama ühe Teenuse kohta mitut erinevat kuulutust;
              </li>
              <li>
                teavitama Naabrid.ee-d salasõna või kasutajatunnuse kaotamisest või sattumisest kolmandate isikute kätte;
              </li>
              <li>
                kinni pidama kõikidest maksukohustustest, mis tal võivad tekkida seoses Teenuse osutamisega;
              </li>
              <li>
                käituma Platvormi kasutades heas usus ning kooskõlas heade kommetega.
              </li>
            </ol>
          </li>
          <li>
            Kasutajad on teadlikud, et:
            <ol class="list-styled">
              <li>
                Platvormil sõlmitud Leping on juriidiliselt siduv ning sellega kaasnevad siduvad kohustused, mille täitmata jätmisel vastutavad Kasutajad kohustuse rikkumise eest;
              </li>
              <li>
                Teenuseid osutatakse Kasutajate vahel sõlmitud lepingu alusel iseseisvalt ning Naabrid.ee ei vastuta Lepingu täitmise eest;
              </li>
              <li>
                Lepingu täitmise järgselt võib Kasutajatel tekkida kohustus deklareerida ja tasuda makse;
              </li>
              <li>
                Platvormi kasutamiseks loodud kasutajatunnuse ning salasõna kaotamise või sattumise korral kolmandate isikute kätte võib kolmas isik teha Kasutaja nimel kehtivaid tahteavaldusi, mille tulemusel võib Kasutajal tekkida õiguslikult siduvad kohustused ning mille täitmise eest Kasutaja võib kolmandate isikute või Naabrid.ee ees vastutada. Naabrid.ee-l ei ole võimalik ega kohustust kontrollida peale kasutajatunnuse ning salasõna sisestamist isikusamasust muudel viisidel;
              </li>
              <li>
                Naabrid.ee-l ei ole võimalik tagada, et Teenuse osutaja on see, kes ta väidab end Platvormil olevat.
              </li>
              <li>
                Platvormil teostatud toimingutele rakendub Naabrid.ee hinnakiri selle redaktsioonis, mis kehtib toimingu tegemise hetkel;
              </li>
              <li>
                Naabrid.ee-l ei ole võimalik ega kohustust kontrollida ega tagada Teenuse kvaliteeti ning selle täitmise kohasust.
              </li>
            </ol>
          </li>
        </ol>
        <h2>
          4. Naabrid.ee õigused ja kohustused
        </h2>
        <ol class="list-styled">
          <li>
            Naabrid.ee-l on õigus:
            <ol class="list-styled">
              <li>
                piirata Kasutaja õigust Platvormi kasutamiseks, Platvormi kasutusõigus lõpetada või leping Vahendusteenuse osutamiseks üles öelda või eemaldada või muuta Platvormil avaldatud kuulutusi või andmeid, kui Kasutaja rikub käesolevate üldtingimuste p-s 3 nimetatud kohutusi või kui Platvormi kasutusõiguse piiramine või lõpetamine on Naabrid.ee hinnangul vajalik muul põhjusel;
              </li>
              <li>
                jälgida Platvormil toimuvat tegevust, Platvormi kaudu Kasutajate toiminguid ja Platvormi kaudu toimuvat kirjavahetust, et tuvastada käesolevate üldtingimuste p-s 3 nimetatud kohustuste rikkumisi või lahendada Kasutajate vahel tekkinud vaidlusi;
              </li>
              <li>
                teostada test-tellimusi, et tuvastada käesolevate üldtingimuste p-s 3 nimetatud kohustuste rikkumisi. Test-tellimusest ei tulene Naabrid.ee-le ega Kasutajale õiguslikke kohustusi;
              </li>
              <li>
                teha Platvormil ja funktsionaalsuse osas muudatusi ja ümberpaigutusi, muutmata Kasutajate kuulutuste sisu (v.a käesolevate üldtingimuste p 4.1.1 sätestatud juhtudel);
              </li>
              <li>
                nõuda Kasutajalt Vahendusteenuse või muu toimingu eest tasu vastavalt kehtestatud hinnakirjale;
              </li>
            </ol>
          </li>
        </ol>
        <h2>
          5. Teenuse eest tasumine
        </h2>
        <ol class="list-styled">
          <li>
            Tasumine Teenuse osutamise eest toimub üksnes Naabrid.ee keskkonna vahendusel.
          </li>
          <li>
            Pärast Lepingu tingimuste kinnitamist Teenuse osutaja ja Teenuse saaja poolt kohustub Teenuse saaja tasuma Lepingu tingimustega koos esitatud arve, milles sisaldub tasu Teenuse osutamise eest ning Naabrid.ee tasud.
          </li>
          <li>
            Naabrid.ee maksab Teenuse osutajale välja Lepingu tingimustes sätestatud tasu Teenuse osutamise eest pärast seda, kui Teenuse osutamise täitmist on Platvormis kinnitanud nii Teenuse osutaja kui ka Teenuse saaja. Teenuse osutamise täitmist saavad kinnitada Teenuse osutaja ja Teenuse saaja 5 päeva jooksul alates Lepingus sätestatud Teenuse osutamise lõpetamisest. Kui Teenuse saaja ei kinnita Platvormis Teenuse osutamise täitmist 5 päeva jooksul, loetakse Teenuse saaja Teenuse osutamise täitmise kinnitanuks.
          </li>
          <li>
            Enne tasu Teenuse osutamise eest väljamaksmist Teenuse osutajale arvab Naabrid.ee maha tasud, mida Naabrid.ee on õigustatud nõudma.
          </li>
          <li>
            Kui Teenuse saaja ei kinnita Teenuse osutamise täitmist ning esitab Teenuse osutamise osas pretensiooni, maksab Naabrid.ee tasu Teenuse osutamise eest Teenuse osutajale välja lähtuvalt Naabrid.ee pretensiooni lahendamise otsusele.
          </li>
        </ol>
        <h2>
          6. Naabrid.ee tasud
        </h2>
        <ol class="list-styled">
          <li>
            Naabrid.ee on õigustatud nõudma Vahendusteenuse ning muude toimingute eest Kasutajalt tasu vastavalt kehtestatud hinnakirjale selle redaktsioonis, mis kehtib toimingu tegemise hetkel.
          </li>
          <li>
            Naabrid.ee on õigustatud nõudma hinnakirjas sätestatud tasu ka juhul, kui Leping jäeti kohaselt täitmata.
          </li>
          <li>
            Tasude maksmine toimub käesolevate üldtingimuste p 5.4 nimetatud mahaarvamise kaudu, pangalingiga või arve alusel.
          </li>
          <li>
            Konkreetse Lepingu puhul rakenduvad tasud nähtuvad Kasutajale Lepingu tingimuste kinnitamisel.
          </li>
        </ol>
        <h2>
          7. Maksetingimused
        </h2>
        <ol class="list-styled">
          <li>
            Teenuse osutaja ja Teenuse saaja poolt kinnitatud tasu ning Naabrid.ee tasud kuvatakse teenuse saajale Lepingu tingimuste kinnitamisel ning need saab tasuda turvaliselt läbi Eesti pangalinkide (Swedbank, SEB, Luminor, LHV, Coop Pank, Pocopay). Pangalingiga tasumisel vajutada pärast makse teostamist panga keskkonnas nupule „Tagasi kaupmehe juurde“.
          </li>
          <li>
            Makseid vahendab Maksekeskus AS. Tasumine toimub väljaspool Platvormi  pangalingile vastava panga turvalises keskkonnas ning Naabrid.ee-l puudub ligipääs kasutajate pangaandmetele.
          </li>
          <li>
            Makse teostamsel on makse saajaks Naabrid.ee, kes edastab saadud maksed pärast Teenuse osutamise täitmise kinnitamist Teenuse osutaja ja Teenuse saaja poolt Teenuse osutajale.
          </li>
          <li>
            Kõik portaalis kajastatud hinnad on eurodes ning makseid võetakse vastu üksnes eurodes.
          </li>
        </ol>
        <h2>
          8. Vastutus
        </h2>
        <ol class="list-styled">
          <li>
            Naabrid.ee vastutus saab tuleneda üksnes Vahendusteenuse pakkumisest. Naabrid.ee ei taga, et Kasutajatel oleks juurdepääs Platvormile katkestuste või vigadeta või et Platvorm ühildub mistahes kasutatava riist- ja tarkvaraga ning et Kasutaja saab Platvormi kaudu Teenust kasutada või osutada.
          </li>
          <li>
            Naabrid.ee ei vastuta Kasutajate ega kolmandate isikute ees Lepingu mittekohase täitmise ega Teenuse osutamise käigus või sellega seoses tekkinud mistahes kahju eest.
          </li>
          <li>
            Naabrid.ee ei vastuta:
            <ol class="list-styled">
              <li>
                Platvormi ega selle kaudu pakutava Vahendusteenuse või toimingute sooritamise võimaluse kättesaadavuse katkestuse või muu ajutise või püsiva kättesaamatuse eest;
              </li>
              <li>
                programmivigade, viiruste või muu pahavara eest, mida edastatakse Platvormi kaudu kolmandate isikute poolt;
              </li>
              <li>
                Platvormile talletatud sisu või muude andmete säilimise eest;
              </li>
            </ol>
          </li>
          <li>
            Naabrid.ee vastutab üksnes tahtluse või raske hooletuse tõttu Kasutajale tekkinud otsese varalise kahju eest. Naabrid.ee vastutus on piiratud 500 euroga.
          </li>
          <li>
            Kasutaja hüvitab Naabrid.ee-le kogu kahju ja kulutused, mis Naabrid.ee-l tekivad seoses Kasutaja poolt toime pandud rikkumisega.
          </li>
        </ol>
        <h2>
          9. Isikuandmete töötlemine
        </h2>
        <ol class="list-styled">
          <li>
            Platvormil Kasutajaks registreerides kinnitab Kasutaja, et on tutvunud ja nõus Naabrid.ee privaatsuspoliitikas sätestatuga. Kasutaja on teadlik, et privaatsuspoliitikas sätestatuga nõustumata jätmisel ei ole võimalik Kasutajale Vahendusteenust osutada ning privaatsuspoliitikas sätestatuga nõustumine on Vahendusteenuse osutamise vältimatuks eelduseks.
          </li>
          <li>
            Platvormil Kasutajaks registreerides annab Kasutaja nõusoleku töödelda Naabrid.ee-l registreerimisel ja Platvormi kasutamise käigus Naabrid.ee-le edastatud isikuandmeid. Naabrid.ee töötleb Kasutajate isikuandmeid, nagu näiteks, kuid mitte ainult, nimi, aadress, telefoninumber, e-posti aadress, fotod, teenuste kasutamise ajalugu, veebilehe külastamise andmed, maksetega seotud andmed, et võimaldada Platvormi toimimine, osutada Vahendusteenust, koostada osutatud teenuste ülevaateid ja statistikat, pakkuda efektiivset kliendituge, teha Kasutajatele suunatud pakkumisi,  täita juriidilisi kohustusi, nõuda sisse võlgnevusi ning tagada muude õigustatud huvide realiseerimine.
          </li>
          <li>
            Kasutaja annab nõusoleku edastada isikuandmeid kolmandatele isikutele, eelkõige, kuid mitte ainult, teistele Kasutajatele ning Maksekeskus AS-le, et tagada Teenuse osutamine ning maksete teostamine.
          </li>
          <li>
            Kasutaja ei tohi töödelda Platvormi kaudu kättesaadavaks saanud teise Kasutaja isikuandmeid, välja arvatud ulatuses, mis on vajalik Teenuse osutamiseks. Kui Teenuse osutaja rikub isikuandmete töötlemise põhimõtteid, on Naabrid.ee õigustatud sulgema Teenuse osutaja kasutajakonto ning nõudma kahju hüvitamist.
          </li>
          <li>
            Kui Kasutaja töötleb kolmandate isikute andmeid (näiteks Teenuse saamiseks kolmanda isiku kasuks), peab Kasutaja kolmanda isiku andmete töötlemiseks olema saanud eelneva nõusoleku kolmandalt isikult ning järgiks muid õigusaktides sätestatud isikuandmete töötlemise põhimõtteid. Naabrid.ee ei vastuta kolmandale isiku ees, kui Naabrid.ee töötleb kolmanda isiku isikuandmeid põhjusel, et Kasutaja on rikkunud enda kohustusi kolmanda isiku isikuandmete töötlemisel.
          </li>
          <li>
            Isikuandmeid võivad töödelda Naabrid.ee töötajad või muud Naabrid.ee poolt volitatud isikud nimetatud eesmärkide täitmiseks. Isikuandmeid säilitatakse seni, kuni see on vajalik eesmärgi täitmiseks, milleks isikuandmeid koguti või kuni see on vajalik Naabrid.ee õiguste kaitsmiseks või kuni see on õigusaktide kohaselt nõutud.
          </li>
          <li>
            Isikuandmeid võidakse avaldada kolmandatele isikutele, kui seda on andmesubjekti poolt palutud müüjal teha, kui seadus seda nõuab või lubab, kui on selleks antud eelnev nõusolek.
          </li>
          <li>
            Andmesubjektile edastatakse otseturustusteateid üksnes andmesubjekti poolt selleks eraldi antud nõusolekul. Otseturustusteadete saamisest on andmesubjektil võimalik igal hetkel loobuda. Teadetest loobumiseks tuleb järgida teates edastatud juhiseid teadetest loobumise kohta.
          </li>
        </ol>
        <h2>
          10. Üldtingimuste ning muude rakenduvate tingimuste muutmine
        </h2>
        <ol class="list-styled">
          <li>
            Naabrid.ee on õigustatud igal ajal käesolevaid üldtingimusi muutma. Samuti on Naabrid.ee õigustatud igal ajal muutma teisi poolte vahel rakenduvaid tingimusi, mille Naabrid.ee on kehtestanud (sealhulgas hinnakiri).
          </li>
          <li>
            Muudatused jõustuvad muudatuste avaldamise hetkest platvormi veebilehel. Kasutajal on võimalik pärast tingimuste muudatusi Vahendusteenuse kasutamisest loobuda. Jätkates tingimuste muutmisel Vahendusteenuse kasutamist, nõustub Kasutaja Naabrid.ee kehtestatud muudatustega.
          </li>
          <li>
            Naabrid.ee teavitab Kasutajat üldtingimuste või muude rakenduvate tingimuste muutmisest Platvormil avaldatavate teadete kaudu.
          </li>
        </ol>
        <h2>
          11. Vahendusteenuse osutamiseks sõlmitus lepingu kestus ja lõpetamine
        </h2>
        <ol class="list-styled">
          <li>
            Leping Vahendusteenuse osutamiseks sõlmitakse tähtajatult.
          </li>
          <li>
            Naabrid.ee on õigustatud leping Vahendusteenuse osutamiseks erakorraliselt üles öelda punktis 4.1.1 nimetatud asjaolu esinemisel. Lepingu vahendusteenuse osutamiseks ülesütlemiseks ei ole vaja Naabrid.ee-l anda rikkujale täiendavat tähtaja rikkumise lõpetamiseks.
          </li>
          <li>
            Kasutaja võib lepingu Vahendusteenuse osutamiseks igal ajal üles öelda ning nõuda kasutajakonto sulgemist, kui Kasutajal on täidetud kõik kohustused Naabrid.ee ning teiste Kasutajate ees ning Kasutaja viimasest Lepingu täitmisest on möödunud 30 päeva.
          </li>
        </ol>
        <h2>
          12. Lõppsätted
        </h2>
        <ol class="list-styled">
          <li>
            Lepingu Vahendusteenuse osutamiseks suhtes kohaldatakse Eesti Vabariigi õigust.
          </li>
          <li>
            Naabrid.ee ja Kasutajad teevad kõik endast oleneva, et lahendada kõik õigussuhetest tulenevad või sellega seotud vaidlused läbirääkimiste teel. Kui läbirääkimised ei anna tulemusi, lahendatakse õigussuhetest tulenevad või sellega seotud vaidlused, lahkarvamused või nõuded Eesti Vabariigi kohtutes. Esimese astme kohus on Tartu Maakohtu Tartu kohtumaja.
          </li>
          <li>
            Naabrid.ee ja Kasutaja vahel edastatavad teated ja taotlused tuleb edastada kirjalikult või kirjalikku taasesitamist võimaldavas vormis (eelistatult Platvormi vahendusel).
          </li>
          <li>
            Kui Kasutaja on tarbija, ei piira käesolevad üldtingimused kohaldatava õiguse nende sätete kohaldamist, millest ei ole võimalik poolte kokkuleppel kõrvale kalduda.
          </li>
          <li>
            Käesolevate üldtingimuste mõne sätte täielik või osaline kehtetus või jõustamise võimatus ei mõjuta ülejäänud sätete ega vastavate sätete ülejäänud osade kehtivust ega jõustatavust. Lepinguosalised asendavad kehtetu või jõustamatu sätte kehtiva sättega, mis vastab esialgse sätte majanduslikule eesmärgile kõige täpsemalt.
          </li>
          <li>
            Käesolev üldtingimuste redaktsioon kehtib alates 13.08.2020.
          </li>
        </ol>
      </ContentPage>
    )
  }
}
/* eslint-enable */
