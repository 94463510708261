import {VNode} from "vue"
import {Component, Prop} from "vue-property-decorator"
import {AbstractInputComponent, AbstractInputPropsType} from "@/components/form/AbstractInputComponent";
import {AutoCompleteValue, InputComponentType, TextInputType} from "@/constants/Elements";
import {ValidationInstance} from "@/utils/vuelidate-extension";
import {Vue} from "vue/types/vue";
import {BasicEvents} from "@/constants/ComponentEvents";
import {trimLeadingTrailingSpaces} from "@/constants/DataBoundaries";

type ValueType = string

interface PropsType extends AbstractInputPropsType<ValueType> {
  type?: TextInputType;
  rows?: number;
  maxRows?: number;
  maxLength?: number;
  placeholder?: string;
  autocomplete?: AutoCompleteValue;
  v?: ValidationInstance<ValueType>;
  textCounter?: boolean;
  trim?: boolean;
  onKeyup?: (e: Event) => void;
  onKeydown?: (e: Event) => void;
  onFocus?: (e: Event) => void;
}

@Component({name: 'TextAreaInput'})
export default class extends AbstractInputComponent<ValueType, PropsType> implements PropsType {
  @Prop(String) public readonly value?: ValueType
  @Prop({type: String, required: false, default: TextInputType.Text}) public readonly type!: TextInputType
  @Prop(String) public readonly placeholder?: string
  @Prop({type: Boolean, required: false, default: false}) public readonly textCounter?: boolean
  @Prop(Number) public readonly maxRows?: number
  @Prop(String) public readonly autocomplete?: AutoCompleteValue
  @Prop(Object) public readonly v?: ValidationInstance<ValueType>
  @Prop({type: Number, required: false, default: 4}) public readonly rows!: number
  @Prop({type: Number, required: false, default: undefined}) public readonly maxLength!: number
  @Prop({type: Boolean, required: false, default: true}) public readonly trim?: boolean

  public isEmpty(): boolean {
    if (this.value === undefined) {
      return true
    } else {
      return this.value.length === 0
    }
  }

  private resizeTextArea(rows: number): number {
    if (this.value !== undefined) {
      const currentLength = this.value.substr(0).split('\n')
      return currentLength.length > rows ? currentLength.length : rows
    } else {
      return rows
    }
  }

  public onBlur(e: Event): void {
    if (!this.plaintext) {
      this.focused = false
      if (this.trim) {
        const inputComponent = this.$refs.textareainput as Vue
        const element = inputComponent.$el as HTMLInputElement
        this.$emit(BasicEvents.Input, element.value !== undefined ? trimLeadingTrailingSpaces(String(element.value)) : element.value)
      }
      this.$emit(BasicEvents.Blur, e)
    }
  }


  public onKeyup(e: Event): void {
    this.$emit(BasicEvents.Keyup, e)
  }

  public onKeydown(e: Event): void {
    this.$emit(BasicEvents.Keydown, e)
  }

  public render(): VNode {
    return (
      <b-form-group
        label-for={this.id}
        description={this.description}
        state={this.state}
        invalid-feedback={this.invalidMessage}
      >
        {(this.$slots.label !== undefined || this.label) && 
          <label>{this.$slots.label === undefined ? this.label : this.$slots.label}</label>
        }
        <b-form-textarea
          ref={InputComponentType.TextAreaInput}
          id={this.id}
          name={this.name}
          required={this.required}
          disabled={this.disabled}
          plaintext={this.plaintext}
          value={this.value}
          rows={this.resizeTextArea(this.rows)}
          max-rows={this.maxRows}
          state={this.state}
          trim={true}
          maxlength={this.maxLength === undefined ? undefined : this.maxLength}
          placeholder={this.placeholder}
          onInput={this.onInput}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyup={this.onKeyup}
          onKeydown={this.onKeydown}
        />
        {this.textCounter &&
          <span class="text-countdown mt-1 float-right">{this.maxLength - (this.value !== undefined ? this.value.length : 0)}/{this.maxLength}</span>
        }
      </b-form-group>
    )
  }
}
