import { RSocketClient, JsonSerializers } from "rsocket-core"
import RSocketWebSocketClient from "rsocket-websocket-client"
import {ISubscription} from "rsocket-types/ReactiveStreamTypes"
import {ReactiveSocket} from "rsocket-types"
import {configStore} from "@/store/config"
import {authStore} from "@/store"
import {chatStore, Contact} from "@/_modules/message-center/store/chat-store";
import {handleWebSocketError, RSocketError} from "@/utils/web-socket-error-handler";

interface Metadata {
  metadata: undefined;
}

const maxRSocketRequestN = 2147483647
let subscription: ISubscription | null = null
let stream: ReactiveSocket<Contact, Metadata> | null = null

async function createTransport(): Promise<RSocketWebSocketClient> {
  const sc = await configStore.getServerConfig()
  if (typeof sc === 'number') {
    throw new Error('Config not loaded. Error code: ' + sc)
  } else {
    return new RSocketWebSocketClient({
      url: `${sc.badgesUri}/api/v1/ws/chats`
    })
  }
}

async function createClient(): Promise<RSocketClient<Contact, Metadata>> {
  const t = await createTransport()
  return new RSocketClient({
    serializers: JsonSerializers,
    setup: {
      keepAlive: 60000,
      lifetime: 180000,
      dataMimeType: "application/json",
      metadataMimeType: "application/json"
    },
    transport: t as RSocketWebSocketClient
  })
}

async function retrieveConnection(userId?: string): Promise<void> {
  if (userId) {
    try {
      const client = await createClient()
      stream = await client.connect()

      await stream!.requestStream({}).subscribe({
        onComplete() {
          console.log('/chats complete')
        },
        onError(error) {
          //console.log(`%cMsg center ${error.message}`, "color:blue;")
          handleWebSocketError(error as RSocketError)

          if (authStore.authenticated) {
            retrieveConnection(userId)
          }
        },
        onNext(payload) {
          if (payload.data !== undefined) {
            chatStore.handleIncomingFrame(payload.data)
          }
        },
        onSubscribe(_subscription) {
          //console.log(`%cMsg center rSocket: connected`, "color:purple;")
          _subscription.request(maxRSocketRequestN)
          subscription = _subscription
        }
      })
    } catch (e) {
      setTimeout(() => {
        //console.log('%crSocket MCenter events connection lost. Attempt to restore...', "color:blue")
        retrieveConnection(userId)
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      }, 10000)
    }
  }
}



export default class WsMsgCenter {

  public streamStop(): void {
    // eslint-disable-next-line no-unused-expressions
    stream?.close()
    // eslint-disable-next-line no-unused-expressions
    subscription?.cancel()
    subscription = null
  }

  public async streamStart(userId?: string): Promise<void> {
    await retrieveConnection(userId)
  }
}
