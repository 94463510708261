import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

export enum AppLang {
  EN = 'en',
  ET = 'et',
  RU = 'ru'
}

import en_global from "@/locales/en.json"
import et_global from "@/locales/et.json"
import ru_global from "@/locales/ru.json"

import en_adm from "@/_modules/admin/assets/locales/en.json"
import et_adm from "@/_modules/admin/assets/locales/et.json"
import ru_adm from "@/_modules/admin/assets/locales/ru.json"

import en_proposal from "@/_modules/proposal/assets/locales/en.json"
import et_proposal from "@/_modules/proposal/assets/locales/et.json"
import ru_proposal from "@/_modules/proposal/assets/locales/ru.json"

import en_request from "@/_modules/request/assets/locales/en.json"
import et_request from "@/_modules/request/assets/locales/et.json"
import ru_request from "@/_modules/request/assets/locales/ru.json"

import en_msg_center from "@/_modules/message-center/assets/locales/en.json"
import et_msg_center from "@/_modules/message-center/assets/locales/et.json"
import ru_msg_center from "@/_modules/message-center/assets/locales/ru.json"

import en_service from "@/_modules/service/locales/en.json"
import et_service from "@/_modules/service/locales/et.json"
import ru_service from "@/_modules/service/locales/ru.json"

import en_demand from "@/_modules/demand/locales/en.json"
import et_demand from "@/_modules/demand/locales/et.json"
import ru_demand from "@/_modules/demand/locales/ru.json"

const locale_en = {
  ...en_global,
  ...en_adm,
  ...en_proposal,
  ...en_request,
  ...en_msg_center,
  ...en_service,
  ...en_demand
}

const locale_et = {
  ...et_global,
  ...et_adm,
  ...et_proposal,
  ...et_request,
  ...et_msg_center,
  ...et_service,
  ...et_demand
}

const locale_ru = {
  ...ru_global,
  ...ru_adm,
  ...ru_proposal,
  ...ru_request,
  ...ru_msg_center,
  ...ru_service,
  ...ru_demand
}

const i18n = new VueI18n({
  // locale: store.getters.getLocale,
  locale: AppLang.ET,
  fallbackLocale: AppLang.EN,
  messages: {
    en: locale_en,
    et: locale_et,
    ru: locale_ru
  }
})

export default i18n

export function translation(key: string, params?: VueI18n.Values): string {
  if (params === undefined) {
    return i18n.t(key) as string
  } else {
    return i18n.t(key, params) as string
  }
}
