import {Component} from "vue-property-decorator"
import {VNode} from "vue"
import {I18nComponent} from "@/components/I18nComponent";
import ContentPage from "@/components/ContentPage";

@Component({name: 'InvestorInfo'})
export default class extends I18nComponent {
  public render(): VNode {
    return (
      <ContentPage
        title={this.translation('route_investorinfo')}
      >
        <p>
          {this.translation('text_investor_info_part1')}
        </p>
        <p>
          {this.translation('text_investor_info_part2')}
        </p>
        <p>
          {this.translation('text_investor_info_part3')}
        </p>
      </ContentPage>
    )
  }
}
