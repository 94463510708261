import {Module, Action, Mutation, getModule, VuexModule} from "vuex-module-decorators";
import rootStore from "@/store/index";
import WsBadgesEvents from "@/ws/ws-badges-events";
import {DocType, UserRole} from "@/components/layout/MainMenu";
import axios from "@/api";
import {BackendUrls} from "@/constants/APIconstants";
import {UnexpectedServerResponseError} from "@/utils/errors";
import HttpStatuses from "http-status-codes";

export interface BadgeEvent {
  id: string;
  docId: string;
  docType: DocType;
  createdAt: Date;
  userRole: UserRole;
}

@Module({dynamic: true, namespaced: true, name: 'badges-store', store: rootStore})
export class BadgesModule extends VuexModule {

  public badgesEvents = new WsBadgesEvents()

  public storeStateBadgeEvents: BadgeEvent[] = []

  @Action({rawError: true})
  public async establishBadgesEventsStream(userId: string): Promise<void> {
    await this.badgesEvents.streamStart(userId)
  }

  @Action({rawError: true})
  public async disconnectBadgesEventsStream(): Promise<void> {
    await this.badgesEvents.streamStop()
  }

  public get getBadgeEvents(): BadgeEvent[] {
    return this.storeStateBadgeEvents
  }
  @Mutation
  public handleIncomingEvent(event: BadgeEvent): void {
    this.storeStateBadgeEvents = this.storeStateBadgeEvents.filter(it => it.docId !== event.docId)
    this.storeStateBadgeEvents.push(event)
  }
  @Mutation
  public removeInternalBadgeEvent(event: BadgeEvent): void {
    this.storeStateBadgeEvents = this.storeStateBadgeEvents.filter(it => it.docId !== event.docId)
  }
  @Mutation
  public clearBadgeEvents(): void {
    this.storeStateBadgeEvents = []
  }

  @Action({rawError: true})
  public async deleteBadgeCall(badgeId: string): Promise<void> {
    const resp = await axios.delete(`${BackendUrls.badges}/${badgeId}`)
    UnexpectedServerResponseError.throwOnStatusMismatch(resp, HttpStatuses.NO_CONTENT)
  }
}

export const badgesStore = getModule(BadgesModule, rootStore)
