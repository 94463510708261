import moment, {Duration} from "moment";
import {DealStatus} from "@/types";
import { convertToI18nLang } from "@/store/app";
import { appStore } from "@/store";

const dots = "..."

export function ellepsis(value: string, len: number): string {
  if (len <= dots.length) {
    throw new Error("length value must be greater than " + dots.length)
  }
  if (value.length <= len) {
    return value
  }
  return value.slice(0, len - dots.length) + dots
}

export function sortDates(dates: string[]): string[] {
  return dates.sort((a, b) => {
    return moment(a).toDate().getTime() - moment(b).toDate().getTime()
  })
}

export function stringifyWithFormat(value: string[]): string {
  const formattedDates = value.map(it => {
    return moment(it).locale(convertToI18nLang(appStore.locale)).format('L')
  })
  return formattedDates.slice(0, value.length).join(', ')
}

export function commaSeparator(value: string[]): string {
  return String(value).split(',').join(', ')
}

export function timeBetween(end: moment.MomentInput, start: moment.MomentInput): Duration {
  return moment.duration((moment(end)).diff(moment(start), 'milliseconds'))
}

export function bindStatusClass(status: string): string {
  if (status === DealStatus.New) {
    return 'text-primary'
  } else if (status === DealStatus.UnderDiscussion || status === DealStatus.PaymentStarted) {
    return 'text-warning'
  } else if (status === DealStatus.DealConcluded || status === DealStatus.Completed || status === DealStatus.Paid) {
    return 'text-success'
  } else if (status === DealStatus.Canceled || status === DealStatus.Declined || status === DealStatus.Confirmed) {
    return 'text-secondary'
  } else if (status === DealStatus.PaidToProvider) {
    return 'text-primary'
  } else {
    return 'text-danger'
  }
}

export function formatCountDown(secs: number): string {
  let s = secs
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers,no-mixed-operators
  return (s-(s%=60))/60 + (s > 9 ? ':' : ':0') + s
}

/* eslint-disable */

function replaceUndefined(v: string): string {
  return v !== undefined ? v : ''
}

export function autocompleteTimeValue(value: string): string {
  const digitsWithColon = new RegExp('[^0-9\:]', 'g')
  value = value.split(digitsWithColon).join('')
  const alpha = new RegExp('[A-z]', 'g')
  let v = value.split(alpha).join('')
  if (Number(v[0]+v[1]) > 23) {
    v = '23' + replaceUndefined(v[2])+replaceUndefined(v[3])+replaceUndefined(v[4])
  }
  if (Number(v[3]+v[4]) > 59) {
    v = `${v[0]}${v[1]}${v[2]}59`
  }
  switch (v.length) {
    case 0:
      return ''
    case 1:
      if (Number(v) < 3) {
        return v
      } else {
        return '0' + v
      }
    case 2:
      if (Number(v[1]) < 4 && typeof v[1] === "number") {
        return v
      } else {
        return `${v[0]}${v[1]}`
      }
    case 3:
      if (v[2] !== ':') {
        if (v[0] === ':') {
          return `${v[1]}${v[2]}`
        }
        if (Number(v[2]) < 6) {
          if (v[1] !== ':') {
            return `${v[0]}${v[1]}:${v[2]}`
          } else {
            return `${v[0]}${v[1]}${v[2]}`
          }
        } else {
          return `${v[0]}${v[1]}:0${(v[2])}`
        }
      } else {
        return `${v}`
      }
    case 4:
      if (Number(v[3]) < 6) {
        return `${v[0]}${v[1]}${v[2]}${v[3]}`
      } else {
        return `${v[0]}${v[1]}${v[2]}0${v[3]}`
      }
    case 5:
      return v[2] !== ':' ? '00:00' : v
    default:
      return v.substr(0, 5)
  }
}

/* eslint-enable */
