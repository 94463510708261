import Vue from "vue"
import Router, {RawLocation, Route} from "vue-router"
import {PositionResult} from "vue-router/types/router"
import * as Routes from "./routes"

import Home from "@/views/Home"
import Customers from "@/views/Customers"
import Assistants from "@/views/Assistants"
import ErrorPage from "@/views/ErrorPage"
import PageNotFound from "@/views/PageNotFound"
import SuccessPage from "@/views/SuccessPage"
import DealAnnouncer from "@/views/DealAnnouncer"
import PaymentSuccess from "@/views/PaymentSuccess"
import PaymentFailure from "@/views/PaymentFailure"
import TermsAndConditions from "@/views/TermsAndConditions"
import PrivacyPolicy from "@/views/PrivacyPolicy"
import ProcedureForResolvingClaims from "@/views/ProcedureForResolvingClaims"
import PaymentAgreement from "@/views/PaymentAgreement"
import InvestorInfo from "@/views/InvestorInfo"
import ThankYou from "@/views/ThankYou"
import AboutUs from "@/views/AboutUs"

import profileRoutes from "@/_modules/profile/router/index"
import servicesRoutes from "@/_modules/service/router/index"
import demandsRoutes from "@/_modules/demand/router/index"
import activityRoutes from "@/_modules/activity/router/index"
import requestRoutes from "@/_modules/request/router/index"
import proposalRoutes from "@/_modules/proposal/router/index"
import adminRoutes from "@/_modules/admin/router/index"
import activeChatsRoutes from "@/_modules/message-center/router/index"

Vue.use(Router)

const homeRoute = {
  path: Routes.Home.path,
  name: Routes.Home.name,
  meta: {
    secured: false
  },
  component: Home
}

const assistantsRoute = {
  path: Routes.Assistants.path,
  name: Routes.Assistants.name,
  meta: {
    secured: false
  },
  component: Assistants
}

const customersRoute = {
  path: Routes.Customers.path,
  name: Routes.Customers.name,
  meta: {
    secured: false
  },
  component: Customers
}

const errorRoute = {
  path: Routes.Error.path,
  name: Routes.Error.name,
  meta: {
    secured: false
  },
  component: ErrorPage
}

const successRoute = {
  path: Routes.Success.path,
  name: Routes.Success.name,
  meta: {
    secured: false
  },
  component: SuccessPage
}

const successLoggedRoute = {
  path: Routes.SuccessLogged.path,
  name: Routes.SuccessLogged.name,
  meta: {
    secured: true
  },
  component: SuccessPage
}

const dealAnnouncer = {
  path: Routes.DealAnnouncer.path,
  name: Routes.DealAnnouncer.name,
  meta: {
    secured: true
  },
  component: DealAnnouncer
}

const paymentSuccess = {
  path: `${Routes.PaymentSuccess.path}/:dealId`,
  name: Routes.PaymentSuccess.name,
  meta: {
    secured: false
  },
  component: PaymentSuccess
}

const paymentFailure = {
  path: `${Routes.PaymentFailure.path}/:dealId`,
  name: Routes.PaymentFailure.name,
  meta: {
    secured: false
  },
  component: PaymentFailure
}

const termsAndConditions = {
  path: Routes.TermsAndConditions.path,
  name: Routes.TermsAndConditions.name,
  meta: {
    secured: false
  },
  component: TermsAndConditions
}

const privacyPolicy = {
  path: Routes.PrivacyPolicy.path,
  name: Routes.PrivacyPolicy.name,
  meta: {
    secured: false
  },
  component: PrivacyPolicy
}

const procedureForResolvingClaims = {
  path: Routes.ProcedureForResolvingClaims.path,
  name: Routes.ProcedureForResolvingClaims.name,
  meta: {
    secured: false
  },
  component: ProcedureForResolvingClaims
}

const paymentAgreement = {
  path: Routes.PaymentAgreement.path,
  name: Routes.PaymentAgreement.name,
  meta: {
    secured: false
  },
  component: PaymentAgreement
}

const investorInfo = {
  path: Routes.InvestorInfo.path,
  name: Routes.InvestorInfo.name,
  meta: {
    secured: false
  },
  component: InvestorInfo
}

const thankYou = {
  path: Routes.ThankYou.path,
  name: Routes.ThankYou.name,
  meta: {
    secured: false
  },
  component: ThankYou
}

const aboutUsRoute = {
  path: Routes.AboutUs.path,
  name: Routes.AboutUs.name,
  meta: {
    secured: false
  },
  component: AboutUs
}

const notFoundRoute = {
  path: "/404",
  name: "404",
  component: PageNotFound,
  meta: {
    secured: false
  }
}

// Redirect default route to 404. This fixes 404 page route title.
const defaultRoute = {
  path: "*",
  redirect: "/404"
}

const routes = [
  homeRoute,
  assistantsRoute,
  customersRoute,
  ...servicesRoutes,
  ...demandsRoutes,
  ...profileRoutes,
  ...activityRoutes,
  ...requestRoutes,
  ...proposalRoutes,
  ...adminRoutes,
  ...activeChatsRoutes,
  errorRoute,
  successLoggedRoute,
  successRoute,
  dealAnnouncer,
  paymentSuccess,
  paymentFailure,
  termsAndConditions,
  privacyPolicy,
  procedureForResolvingClaims,
  paymentAgreement,
  investorInfo,
  thankYou,
  aboutUsRoute,
  notFoundRoute,
  defaultRoute
]

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior (): PositionResult {
    return { x: 0, y: 0 }
  }
})

import {appStore} from "@/store"
import {Language} from "@/types"

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
router.beforeEach((to: Route, from: Route, next: (to?: RawLocation|void) => void) => {
  const langParam = to.query.lang
  if (langParam !== undefined && typeof langParam === 'string') {
    const locale = Object.values(Language).find(v => v === langParam)
    if (locale !== undefined) {
      appStore.setLocale(locale)
    }
  }
  return next()
})

export default router
