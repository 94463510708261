/* eslint-disable @typescript-eslint/no-magic-numbers */

import IBAN from "iban"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import libphonenumber from "google-libphonenumber"
import {InternationalPhoneNumber, InternationalPhoneNumberForm} from "@/_modules/profile/types";
import {
  validateAgeRestriction,
  validateEstonianOrLithuanianPersonalCode, validateLatvianPersonalCode,
  validatePersonalCode
} from "@/utils/personal-code-validation";

export enum PredefinedPattern {
  ALPHA = "alpha",
  NUMERIC = "numeric",
  ALPHANUMERIC = "alphanumeric"
}

export enum BoundaryType {
  NUMERIC,
  STRING,
  STRING_PREDEFINED_PATTERN,
  STRING_REGEX_PATTERN
}

export interface Boundary {
  type: BoundaryType;
}

export interface NumericBoundary extends Boundary {
  minValue: number;
  maxValue: number;
  integer: boolean;
  nonNegative: boolean;
  nonZero: boolean;
}

export interface StringBoundary extends Boundary {
  minLength: number;
  maxLength: number;
}

export interface StringPredefinedBoundary extends StringBoundary {
  pattern: PredefinedPattern;
}

export interface StringRegexBoundary extends StringBoundary {
  pattern: RegExp;
}

function numericBoundary(minValue: number, maxValue: number, integer?: boolean, nonNegative?: boolean, nonZero?: boolean): NumericBoundary {
  return {
    type: BoundaryType.NUMERIC,
    minValue,
    maxValue,
    integer: integer === undefined ? true : integer,
    nonNegative: nonNegative === undefined ? false : nonNegative,
    nonZero: nonZero === undefined ? false : nonZero
  }
}

function stringBoundary(minLength: number, maxLength: number): StringBoundary {
  return {
    type: BoundaryType.STRING,
    minLength,
    maxLength
  }
}

/*function stringPatternBoundary(minLength: number, maxLength: number, pattern: PredefinedPattern): StringPredefinedBoundary {
  return {
    type: BoundaryType.STRING_PREDEFINED_PATTERN,
    minLength,
    maxLength,
    pattern
  }
}*/

function stringRegexBoundary(minLength: number, maxLength: number, pattern: RegExp): StringRegexBoundary {
  return {
    type: BoundaryType.STRING_REGEX_PATTERN,
    minLength,
    maxLength,
    pattern
  }
}

const OneDigitAfterDotRegExp = /(?!^0*$)(?!^0*\.0*$)^\d{1,5}(\.\d{1})?$/
const PhoneNumberRegExp = /^[0-9 -]{5,16}$/
const nonNegativeCurrencyRegExp = /^[-+]?[0-9]*\d{0,2}(\.\d{1,2})?%?$/
const decimalValueRegExp = /^[0-9]+(\.[0-9]*)?$/
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const containsMinimumTwoNumbersRegExp = /^(?:\D*\d){2,}/
const timeRegExp = /(^([0-1][0-9]|[2][0-3]):([0-5][0-9])$)/
// eslint-disable-next-line no-misleading-character-class
const cyrillicCharsRegExp = /[\u0400-\u04FF\u0500-\u052F\u2DE0-\u2DFF\uA640-\uA69F\u1C80-\u1C8F\u1D2B-\u1D78\uFE2E-\uFE2F]/u

export function isEmpty(v: string | undefined | null): boolean {
  return v === undefined || v === null || v.length === 0
}

export function isNumericEmpty(v: number | undefined | null): boolean {
  return v === undefined || v === null || String(v).length === 0
}

export function validateNonCyrillic(): (v: string) => boolean {
  return (v) => isEmpty(v) || !cyrillicCharsRegExp.test(v as string)
}

export function validateEmail(): (v: string) => boolean {
  return (v) => isEmpty(v) || emailRegExp.test(v as string)
}

export function validateIban(): (v: string) => boolean {
  return (v) => isEmpty(v) || IBAN.isValid(v)
}

function hasTrailingZeroes(v: string): boolean {
  return v !== '0' && v.startsWith('0') && !v.startsWith('0.')
}

function checkNonNegativeCurrency(v: string): boolean {
  return !isEmpty(v) && !hasTrailingZeroes(v as string) && nonNegativeCurrencyRegExp.test(v as string)
}
function validateNonNegativeCurrency(): (v: string) => boolean {
  return (v) => isEmpty(v) || checkNonNegativeCurrency(v)
}

export function checkDecimal(v: string): boolean {
  return !isEmpty(v) && decimalValueRegExp.test(v as string)
}
function validateDecimal(): (v: string) => boolean {
  return (v) => isEmpty(v) || checkDecimal(v)
}

function checkTimeFormat(v: string): boolean {
  return !isEmpty(v) && timeRegExp.test(v as string)
}
function validateTime(): (v: string) => boolean {
  return (v) => isEmpty(v) || checkTimeFormat(v)
}

export function trimLeadingTrailingSpaces(v: string): string {
  return !isEmpty(v) ? v.replace(/^\s\s*/, '').replace(/\s\s*$/, '') : ''
}

export function validatePhoneNumberBasic(): (v: string) => boolean {
  return (v: string) => PhoneNumberRegExp.test(v as string)
}

export function validateOneDigitAfterDot(): (v: string) => boolean {
  return (v: string) => isEmpty(v) || OneDigitAfterDotRegExp.test(v as string)
}

const testNumbers: readonly InternationalPhoneNumber[] = [
  { countryCode: 'EE', prefix: '372', number: '00000766' },
  { countryCode: 'EE', prefix: '372', number: '00000566' },
  { countryCode: 'EE', prefix: '372', number: '00000266' },
  { countryCode: 'EE', prefix: '372', number: '07110066' },
  { countryCode: 'EE', prefix: '372', number: '01100266' },
  { countryCode: 'EE', prefix: '372', number: '00000666' },
  { countryCode: 'EE', prefix: '372', number: '01200266' },
  { countryCode: 'EE', prefix: '372', number: '13100266' },
  { countryCode: 'EE', prefix: '372', number: '00001566' },
  { countryCode: 'EE', prefix: '372', number: '00001466' },
  { countryCode: 'EE', prefix: '372', number: '00001366' },
  { countryCode: 'EE', prefix: '372', number: '69100366' },
  { countryCode: 'EE', prefix: '372', number: '69200366' },
  { countryCode: 'EE', prefix: '372', number: '69300366' },
  { countryCode: 'EE', prefix: '372', number: '69400366' },
  { countryCode: 'EE', prefix: '372', number: '69500366' },
  { countryCode: 'EE', prefix: '372', number: '69600366' },
  { countryCode: 'EE', prefix: '372', number: '69700366' },
  { countryCode: 'EE', prefix: '372', number: '69800366' },
  { countryCode: 'EE', prefix: '372', number: '69900366' },
  { countryCode: 'EE', prefix: '372', number: '69000366' },
  { countryCode: 'EE', prefix: '372', number: '68100366' },
  { countryCode: 'EE', prefix: '372', number: '68200366' },
  { countryCode: 'EE', prefix: '372', number: '68300366' },
  { countryCode: 'EE', prefix: '372', number: '68400366' },
  { countryCode: 'EE', prefix: '372', number: '68500366' }
]

const isTestNumber = (intPhNumber: InternationalPhoneNumberForm): boolean => {
  const intnumber = { countryCode: intPhNumber.countryCode!, prefix: intPhNumber.prefix!, number: intPhNumber.number! }
  return testNumbers.some(tn =>
    tn.countryCode === intnumber.countryCode && tn.prefix === intnumber.prefix && tn.number === intnumber.number
  )
}

function validateInternationalPhoneNumber(v: string, intPhNumber: InternationalPhoneNumberForm): boolean {
  if (!validatePhoneNumberBasic() || isEmpty(v) || isEmpty(intPhNumber.countryCode) || isEmpty(intPhNumber.number)) {
    return false
  } else {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
    try {
      const number = phoneUtil.parseAndKeepRawInput(String(`+${intPhNumber.prefix}${intPhNumber.number}`), intPhNumber.countryCode)
      return phoneUtil.isValidNumber(number) || isTestNumber(intPhNumber)
    } catch (e) {
      return false
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function validatePhoneNumber(): (v: string, vm?: any) => boolean {
  return (v, vm) => {
    return validateInternationalPhoneNumber(v, vm)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const personalCodeWithCountryValidator: (v: string, vm?: any) => boolean = (_, vm) => {
  function check(country: string, code: string): boolean {
    switch (country) {
      case 'EE':
      case 'LT':
        return validateEstonianOrLithuanianPersonalCode(code)
      case 'LV':
        return validateLatvianPersonalCode(code)
      default:
        return false
    }
  }

  return vm && vm.countryCode && vm.personalCode ? check(vm.countryCode, vm.personalCode) : false
}

export const personalCodeValidator = validatePersonalCode()
export const ageRestrictionValidator = validateAgeRestriction()
export const ibanValidator = validateIban()
export const nonNegativeCurrencyValidator = validateNonNegativeCurrency()
export const decimalValueValidator = validateDecimal()
export const emailValidator = validateEmail()
export const timeValidator = validateTime()
export const nonCyrillicValidator = validateNonCyrillic()
export const decimal = validateDecimal()
export const internationalPhoneNumber = validatePhoneNumber()
export const oneDigitAfterDot = validateOneDigitAfterDot()

export default {
  nonEmpty: stringBoundary(1, 300),
  name: stringBoundary(1, 100),
  newPassword: stringRegexBoundary(8, 50, containsMinimumTwoNumbersRegExp),
  email: stringRegexBoundary(4, 256, emailRegExp),
  emailCode: stringRegexBoundary(6, 6, /^\d{6}$/),
  city: stringBoundary(2, 50),
  address: stringBoundary(2, 200),
  zipCode: stringBoundary(4, 16),
  phone: stringBoundary(4, 20),
  currency: numericBoundary(0, 10000),
  currencyStringLenght: stringBoundary(0, 8),
  currencyNonNegative: stringRegexBoundary(0, 14, /^[-+]?[0-9]*\d{0,2}(\.\d{1,2})?%?$/),
  weight: numericBoundary(0.01, 300),
  weightLength: stringRegexBoundary(1, 7, /^[-+]?[0-9]*\d{0,2}(\.\d{1,3})?%?$/),
  defaultString: stringBoundary(1, 200),
  defaultNumeric: numericBoundary(0, 1000000),
  percentage: numericBoundary(1, 100),
  longDescription: stringBoundary(0, 2000),
  heartRate: numericBoundary(1, 299, true, true),
  bodyTemperature: numericBoundary(35.1, 43.9, false, true),
  bloodSugarLevel: numericBoundary(0.01, 100, false, true, true),
  urineVolume: numericBoundary(0.01, 10000, false, true, true),
  medicineAmount: numericBoundary(0.001, 100000, false, true, true),
  bloodPressure: stringBoundary(1, 7)
}
