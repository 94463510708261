import {VNode} from "vue"
import {Component} from "vue-property-decorator"
import {I18nComponent} from "@/components/I18nComponent"

@Component({name: "ErrorPage"})
export default class extends I18nComponent {

  private title: string | null = null
  private reason: string | null = null

  public mounted(): void {
    this.reason = this.$route.params.reason
    this.title = this.$route.params.title
  }

  public render(): VNode {
    const warn = 'Internal application error'
    return (
      <b-container>
        <b-row class="mt-3">
          <b-col class="text-center">
            <h1>{this.title}</h1>
            <h1>{warn}</h1>
          </b-col>
        </b-row>
        <b-row class="mb-10">
          <b-col class="text-center">
            {this.reason !== null &&
              this.translation(this.reason)
            }
          </b-col>
        </b-row>
      </b-container>
    )
  }
}
