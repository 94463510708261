import Vue from "vue"
import {Component} from "vue-property-decorator"
import {VNode} from "vue"

@Component({name: 'AppIconPlay'})
export default class extends Vue {
  public render(): VNode {
    return (
      <svg class="app-icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 49 48">
        <path fill="currentColor" stroke="currentColor" stroke-width="2" d="M12.5 36.554V11.446a1 1 0 011.496-.868l21.969 12.554a1 1 0 010 1.736L13.996 37.422a1 1 0 01-1.496-.868z"/>
      </svg>
    )
  }
}
