import * as Error from "@/utils/errors"
import {Location} from "vue-router/types/router"
import * as Routes from "@/_modules/profile/router/routes"
import {LogoutPayload, profileStore} from "@/_modules/profile/store/profile"
import router from "@/router"
import {ErrorCodes} from "@/constants/APIconstants";

export interface ErrorHandler {
  errorMessageKey?: string;
  handleError(e: Error.ApplicationError): Promise<void>;
}

export class BasicErrorHandler implements ErrorHandler {

  public errorMessageKey?: string

  protected async handleAuthenticationError(e: Error.AuthenticationError): Promise<void> {
    const location: Location = {name: Routes.Login.name}
    if (e.code !== undefined) {
      location.params = {errorCode: e.code}
    }
    await profileStore.logout(new LogoutPayload(router, location, true))
  }

  // eslint-disable-next-line require-await
  protected async handleRequestCancelledError(): Promise<void> {
    this.errorMessageKey = "err_request_cancelled_by_user"
  }

  // eslint-disable-next-line require-await
  protected async handleBadRequest(e: Error.BadRequestError): Promise<void> {
    console.error('400 BadRequest', e)
    this.errorMessageKey = "err_bad_request"
  }

  // eslint-disable-next-line require-await
  protected async handleResourceNotFound(e: Error.ResourceNotFoundError): Promise<void> {
    console.error('404 NotFound: ' + e.path, e)
    this.errorMessageKey = "err_resource_not_found"
  }

  // eslint-disable-next-line require-await
  protected async handleUnexpectedServerError(e: Error.UnexpectedServerError): Promise<void> {
    console.error("500 UnexpectedServerError", e)
    this.errorMessageKey = "err_unexpected_server_error"
  }

  // eslint-disable-next-line require-await
  protected async handleServerUnavailableError(e: Error.UnexpectedServerError): Promise<void> {
    console.error("Failed to get response from the server", e)
    this.errorMessageKey = "err_service_unavailable"
  }

  // eslint-disable-next-line require-await
  protected async handleUnexpectedServerResponse(e: Error.UnexpectedServerResponseError): Promise<void> {
    console.error(`Unexpected Server Response (expected status: ${e.expectedStatus}, actual: ${e.actualStatus})`, e)
    this.errorMessageKey = "err_unexpected_server_error"
  }

  // eslint-disable-next-line no-unused-vars,require-await
  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.DatabaseFailure:
        this.errorMessageKey = "err_unexpected_server_error"
        break;
      case ErrorCodes.MessagingSubsystemFailure:
        this.errorMessageKey = 'err_messaging_subsystem_failure'
        break;
      default:
        console.error("Unexpected Backend Error: " + e.code)
        this.errorMessageKey = "err_backend_error"
        break;
    }
  }

  public async handleError(e: Error.ApplicationError): Promise<void> {
    if (e instanceof Error.AuthenticationError) {
      await this.handleAuthenticationError(e)
    } else if (e instanceof Error.RequestCancelledError) {
      await this.handleRequestCancelledError()
    } else if (e instanceof Error.BadRequestError) {
      await this.handleBadRequest(e)
    } else if (e instanceof Error.ResourceNotFoundError) {
      await this.handleResourceNotFound(e)
    } else if (e instanceof Error.BackendError) {
      await this.handleBackendError(e)
    } else if (e instanceof Error.UnexpectedServerError) {
      await this.handleUnexpectedServerError(e)
    } else if (e instanceof Error.UnexpectedServerResponseError) {
      await this.handleUnexpectedServerResponse(e)
    } else if (e instanceof Error.ServerUnavailableError) {
      await this.handleServerUnavailableError(e)
    } else {
      console.error('Unexpected Application Error', e, e.rootCause)
      this.errorMessageKey = "err_unexpected_error"
    }
  }
}

export const defaultErrorHandler = new BasicErrorHandler()