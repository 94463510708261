import {Module, VuexModule, Action, getModule, Mutation} from "vuex-module-decorators"
import rootStore from "@/store"
import {AddressForm, ArrayPage, Language, LocationFromResponse, MapCoordinates, OrderDirection, SortFields, SortOrder} from "@/types"
import {defaultMapCenter, googleMapApiKey} from "@/constants/ApplicationConfiguration"
import {cloneDeep, head} from 'lodash'
import {RecursivePartial} from "@/utils/typescript-library-extensions"
import {ServiceTypeForSearch} from "@/_modules/service/types"

//import axios from "@/api"
//import HttpStatus from "http-status-codes"
//import {UnexpectedServerResponseError} from "@/utils/errors"

//const geoCodingUrl = "https://nominatim.openstreetmap.org/search"

const gmapGeoCodingUrl = "https://maps.googleapis.com/maps/api/geocode/json"

export const pageSize = 10

export interface SearchForm<T> {
  age: string[];
  weight: string[];
  sex: string[];
  disease: string[];
  physical: string[];
  mental: string[];
  category: string[];
  topLevelCategory: string;
  online?: boolean;
  location?: string;
  speakLang?: Language[];
  order?: SortOrder<T>;
  serviceAtHome?: ServiceTypeForSearch;
  center?: MapCoordinates;
  subCategory?: string;
  distance?: number;
  existLocationCords: ExistLocationCords;
  totalNumberServices?: number;
  page: ArrayPage;
  zoom?: number;
}

export interface GeoCodingResponseAddress {
  city: string;
  country: string;
  country_code: string;
  county: string;
  house_number: string;
  neighbourhood: string;
  postcode: string;
  road: string;
  suburb: string;
}

export interface GmapAddressComponents {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface ExistLocationCords {
  dLon?: number;
  dLat?: number;
}

export interface GmapCoordinates {
  lat: number;
  lng: number;
}

export interface GmapGeometry {
  location: GmapCoordinates;
}

export interface GmapRespResult { //TODO: interface not completely implemented
  address_components: GmapAddressComponents[];
  geometry: GmapGeometry; //TODO: interface not completely implemented
}

export interface GmapGeoCodingResponse {
  results: GmapRespResult[];
  status: string;
}

export interface GeoCodingResponse {
  address: GeoCodingResponseAddress;
  type: string;
  class: string;
  display_name: string;
  importance: number;
  place_id: string;
  lon: string;
  lat: string;
}

export interface PixelOffset {
  width: number;
  height: number;
}

export interface MapInfoPanelOptions {
  pixelOffset: PixelOffset;
}

export interface MapInfoPanelData {
  position: MapCoordinates;
  visible: boolean;
  options: MapInfoPanelOptions;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MapState {
  lastUserPosition: MapCoordinates;
}

function countryCodeToName(code: string): string {
  switch (code) {
    case 'EE':
      return 'Estonia'
    default:
      return 'Estonia'
  }
}

async function getAddressByCoords(coords: MapCoordinates): Promise<object> {
  const lat = coords.latitude
  const lng = coords.longitude
  const url = `${gmapGeoCodingUrl}?latlng=${lat},${lng}&key=${googleMapApiKey}`
  const resp = await fetch(url)
  return resp
}

async function geoCode(address: AddressForm): Promise<GmapRespResult | undefined> {
  if (address.country === undefined || address.cityOrCounty === undefined) {
    return undefined
  }
  const searchString = [countryCodeToName(address.country), address.cityOrCounty, address.address, address.zipCode].filter(s => s !== undefined && s.length > 0).join(",")
  //const url = `${geoCodingUrl}/?q=${searchString}&format=json&addressdetails=1&limit=1&polygon_svg=1`
  const url = `${gmapGeoCodingUrl}?address=${searchString}&key=${googleMapApiKey}`
  try {
    const resp = await fetch(url)
    const json = await resp.json()
    if (json.results === undefined || json.results === null || !Array.isArray(json.results) || json.results.length === 0) {
      return undefined
    }
    return head(json.results) as GmapRespResult
  } catch(e) {
    console.error('Unable to fetch address')
    return undefined
  }
}

@Module({dynamic: true, namespaced: true, name: "map", store: rootStore})
export class MapModule extends VuexModule {

  public searchForm: SearchForm<SortFields> = {
    age: [],
    weight: [],
    sex: [],
    disease: [],
    physical: [],
    mental: [],
    category: [],
    topLevelCategory: '',
    online: undefined,
    location: undefined,
    speakLang: [],
    order: {
      columnName: SortFields.PRICE,
      orderDirection: OrderDirection.ASC
    },
    subCategory: undefined,
    totalNumberServices: 0,
    page: new ArrayPage(1, pageSize),
    center: undefined,
    zoom: undefined,
    distance: 0,
    serviceAtHome: ServiceTypeForSearch.All,
    existLocationCords: {
      dLon: undefined,
      dLat: undefined
    }
  }

  public get searchData(): SearchForm<SortFields> {
    return this.searchForm
  }

  @Mutation
  public clearSearchState(): void {
    this.searchForm = {
      age: [],
      weight: [],
      sex: [],
      disease: [],
      physical: [],
      mental: [],
      category: [],
      topLevelCategory: '',
      online: undefined,
      location: undefined,
      speakLang: [],
      order: {
        columnName: SortFields.PRICE,
        orderDirection: OrderDirection.ASC
      },
      subCategory: undefined,
      totalNumberServices: 0,
      page: new ArrayPage(1, pageSize),
      center: this.lastPosition,
      zoom: undefined,
      distance: 0,
      serviceAtHome: ServiceTypeForSearch.All,
      existLocationCords: {
        dLon: undefined,
        dLat: undefined
      }
    }
  }

  @Mutation
  public setServiceSearchData(searchServiceForm: SearchForm<SortFields>): void {
    this.searchForm = searchServiceForm
  }

  @Mutation
  public setDemandSearchData(searchDemandForm: SearchForm<SortFields>): void {
    this.searchForm = searchDemandForm
  }

  public mapState: MapState = {
    lastUserPosition: defaultMapCenter // TODO: This information might be stored in localStore to survive refresh
  }

  public get lastPosition(): MapCoordinates {
    return this.mapState.lastUserPosition
  }

  @Mutation
  public setPosition(coordinates: MapCoordinates): void {
    this.mapState.lastUserPosition = coordinates
  }

  @Mutation
  public cleanupPosition(): void {
    this.mapState.lastUserPosition = defaultMapCenter
  }

  @Action({rawError: true})
  public async resolveCoordinates(address: AddressForm): Promise<RecursivePartial<LocationFromResponse> | undefined> {
    const addr = cloneDeep(address)
    addr.zipCode = undefined // TODO: Lena asked to exclude zip from address resolution
    const response = await geoCode(addr)
    if (response) {
      const zipCodeComponent = response!.address_components.filter(it => {
        return it.types.find(iter => {
          return iter === 'postal_code'
        })
      })
      const zipCode = head(zipCodeComponent) !== undefined ? head(zipCodeComponent)!.short_name : undefined
      return (zipCodeComponent === undefined) ? undefined : {
        latitude: Number(response.geometry.location.lat),
        longitude: Number(response.geometry.location.lng),
        address: {
          postcode: String(zipCode)
        }
      }
    } else {
      return undefined
    }
  }

  @Action({rawError: true})
  public async resolveAddress(coords: MapCoordinates): Promise<void> {
    const resp = await getAddressByCoords(coords)
    console.log(resp)
  }

  // @Action({rawError: true})
  // public async resolveZip(address: AddressForm): Promise<string | undefined> {
  //   const addr = cloneDeep(address)
  //   addr.zipCode = undefined // Current zip code may be the reason of failed search so we throw away it in this case
  //   const response = await geoCode(addr)
  //   return response === undefined ? undefined : response.address.postcode
  // }
}

export const mapStore = getModule(MapModule, rootStore)
