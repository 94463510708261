import {appStore} from "@/store";
import {Language} from "@/types";

export function introVideoId(): string {
  const locale = appStore.appState.locale
  switch (locale) {
    case Language.ENG:
      return '-R-rDoqZD_M' //TODO: ENG not found
    case Language.EST:
      return '-R-rDoqZD_M'
    case Language.RUS:
      return 'jQOPPYmZZ2Q'
    default:
      return '-R-rDoqZD_M'
  }
}
