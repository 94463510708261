import {Component} from 'vue-property-decorator'
import {VNode} from "vue"

import {I18nComponent} from "@/components/I18nComponent"
import * as ActivityRoutes from "@/_modules/activity/router/routes"
import {DocType, UserRole} from "@/components/layout/MainMenu";
import {BadgeEvent, badgesStore} from "@/store/badges-store";

interface Menu {
  name: string;
  titleTranslationKey: string;
}

export const activityMenuItems: Menu[] = [
  {
    name: ActivityRoutes.Overview.name,
    titleTranslationKey: "menu_overview"
  },
  {
    name: ActivityRoutes.Services.name,
    titleTranslationKey: "menu_offer"
  },
  {
    name: ActivityRoutes.Proposals.name,
    titleTranslationKey: "menu_proposals"
  },
  {
    name: ActivityRoutes.Demands.name,
    titleTranslationKey: "menu_demands"
  },
  {
    name: ActivityRoutes.Requests.name,
    titleTranslationKey: "menu_requests"
  }
  /*{
    name: ActivityRoutes.Accounts.name,
    titleTranslationKey: "menu_accounts"
  },
  {
    name: ActivityRoutes.History.name,
    titleTranslationKey: "menu_history"
  }*/
]

@Component({name: 'ActivityNav'})
export default class extends I18nComponent {

  private defineBadgeRelation(badge: BadgeEvent): string | undefined {
    if (badge.userRole === UserRole.PROVIDER && badge.docType === DocType.PROPOSAL) {
      return 'proposals'
    } else if (badge.userRole === UserRole.REQUESTER && badge.docType === DocType.PROPOSAL) {
      return 'demands'
    } else if (badge.userRole === UserRole.PROVIDER && badge.docType === DocType.REQUEST) {
      return 'services'
    } else if (badge.userRole === UserRole.REQUESTER && badge.docType === DocType.REQUEST) {
      return 'requests'
    }

    return undefined
  }

  public render(): VNode {
    const badges = badgesStore.getBadgeEvents
    
    const path = this.$route.path.split('/')
    path.shift()
    
    return (
      <b-container class="mb-4 d-none d-lg-block" fluid="xl">
        <b-nav tabs class="nav-overview">
          {activityMenuItems.map((menuItem) => {
            return(
              <b-nav-item active={path.includes(menuItem.name)} to={{ name: menuItem.name }}>
                <span>{this.translation(menuItem.titleTranslationKey)}</span>
                {badges.find(badge => this.defineBadgeRelation(badge) === menuItem.name) ?
                  <b-badge
                    pill
                    variant="danger"
                  >
                    {String(badges.filter(it => this.defineBadgeRelation(it) === menuItem.name).length)}
                  </b-badge> : ''}
              </b-nav-item>
            )
          })
          }
        </b-nav>
      </b-container>
    )
  }
}
